<template>
  <ConfirmModal persist wrapper-class="max-w-2xl" @close-dialog="closeDialog">
    <template #title>
      <h1 class="text-headline-m p-6 pb-5">{{ title }}</h1>
    </template>
    <template #description>
      <p class="text-body-m px-6 pb-5">
        {{ description }}
      </p>
    </template>
    <template #content>
      <div class="scrollbar-hide max-h-[550px] overflow-y-scroll px-6 pb-11" :class="{ 'overflow-hidden': formData.length <= 1 }">
        <div v-for="(formDataItem, index) of formData" :key="formDataItem.key" class="mb-4">
          <div class="flex items-center gap-4">
            <h3 v-if="!formDataItem.firstName && !formDataItem.lastName" :data-test-id="`headline-${index}`">
              {{ t('EmailInvitationQuota.manualContactUpload.contactTitle') }} {{ index + 1 }}
            </h3>
            <h3 v-else>{{ formDataItem.firstName }} {{ formDataItem.lastName }}</h3>
            <SvgIcon
              v-if="formData.length > 1"
              :data-test-id="`delete-user-${index}`"
              :path="mdiDelete"
              :size="23"
              class="cursor-pointer text-grey-800"
              @click="removeUser(index)"
            />
          </div>
          <div>
            <TextInput
              :id="`email-${index}`"
              v-model.trim="formDataItem.email"
              type="email"
              :label="t('EmailInvitationQuota.manualContactUpload.formFields.email') + '*'"
              :error="getValidationErrors(validators[index].value.email)"
              @update:model-value="validators[index].value.email.$validate"
            />
          </div>
          <div class="flex gap-3">
            <TextInput
              :id="`title-${index}`"
              v-model="formDataItem.title"
              :label="t('EmailInvitationQuota.manualContactUpload.formFields.title')"
            />
            <TextInput
              :id="`firstname-${index}`"
              v-model="formDataItem.firstName"
              :error="getValidationErrors(validators[index].value.firstName)"
              :label="t('EmailInvitationQuota.manualContactUpload.formFields.firstName') + '*'"
              @update:model-value="validators[index].value.firstName.$validate"
            />
            <TextInput
              :id="`lastname-${index}`"
              v-model="formDataItem.lastName"
              :error="getValidationErrors(validators[index].value.lastName)"
              :label="t('EmailInvitationQuota.manualContactUpload.formFields.lastName') + '*'"
              @update:model-value="validators[index].value.lastName.$validate"
            />
          </div>
          <div class="flex items-center gap-3">
            <TextInput
              :id="`company-${index}`"
              v-model="formDataItem.company"
              :label="t('EmailInvitationQuota.manualContactUpload.formFields.company')"
            />
            <SelectInput
              v-model="formDataItem.gender"
              class="w-1/4"
              :options="genderOptions"
              :label="t('EmailInvitationQuota.manualContactUpload.formFields.gender')"
            />
          </div>
        </div>
        <button class="btn-primary-mint-m mt-5" data-test-id="addGuestButton" @click="addUser()">
          <SvgIcon :path="mdiPlusCircleOutline" class="mr-2 text-white" />
          {{ t('EmailInvitationQuota.manualContactUpload.addGuestButton') }}
        </button>
      </div>
    </template>
    <template #actions>
      <div class="flex justify-end border-t border-t-grey-300 bg-grey-100 p-6">
        <button class="btn-ghost-purple-m mr-4 uppercase" @click="closeDialog()">
          {{ t('EmailInvitationQuota.manualContactUpload.cancelButton') }}
        </button>
        <button data-test-id="saveButton" class="btn-secondary-purple-m uppercase" @click="saveGuests()">
          {{ t('EmailInvitationQuota.manualContactUpload.saveButton') }}
        </button>
        <button class="btn-primary-purple-m ml-4" @click="nextStep()">
          {{ t('EmailInvitationQuota.manualContactUpload.templateButton') }}
        </button>
      </div>
    </template>
  </ConfirmModal>
</template>

<script lang="ts" setup>
import { mdiDelete, mdiPlusCircleOutline } from '@mdi/js'
import { SelectInput, TextInput } from '@ramp106/omrjs-core-ui'
import { useVuelidate } from '@vuelidate/core'
import { onMounted, ref, type Ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { GenderEnum, type InviteeInput } from '@/gql/myomr'
import { getValidationErrors } from '@/helpers/validationHelper'
import { required, email } from '@/services/validators'

const { t } = useI18n()

const props = defineProps<{
  title: string
  description: string
  singleInvites?: Array<InviteeInput & { key: number }>
}>()

const emit = defineEmits(['closeDialog', 'saveGuests', 'nextStep'])

const formDataInstance: InviteeInput & { key: number } = {
  email: '',
  gender: undefined,
  title: undefined,
  firstName: '',
  lastName: '',
  company: undefined,
  key: Date.now(),
}

const rules = {
  email: { required, email },
  firstName: { required },
  lastName: { required },
}

const formData: Ref<Array<InviteeInput & { key: number }>> = ref([{ ...formDataInstance, key: Date.now() }])

const validators = [useVuelidate(rules, formData.value[0])]

// const validators = [{ ...useVuelidate(rules, formData.value[0]) }]

const genderOptions = [
  { text: 'Male', value: GenderEnum.Male },
  { text: 'Female', value: GenderEnum.Female },
  { text: 'Other', value: GenderEnum.Other },
]

function closeDialog() {
  emit('closeDialog')
}

function removeUser(index: number) {
  validators.splice(index, 1)
  formData.value.splice(index, 1)
}

function addUser() {
  formData.value.push({ ...formDataInstance, key: Date.now() })
  validators.push(useVuelidate(rules, formData.value[formData.value.length - 1]))
}

function addExistingUser(user: InviteeInput & { key: number }) {
  formData.value.push({ ...user })
  validators.push(useVuelidate(rules, formData.value[formData.value.length - 1]))
}

async function validateForm() {
  let formIsValid = true
  for (const validator of validators) {
    const isValid = await validator.value.$validate()
    if (!isValid) formIsValid = false
  }
  return formIsValid
}

async function saveGuests() {
  const isValid = await validateForm()
  if (!isValid) return
  emit('saveGuests', formData.value)
}

async function nextStep() {
  const isValid = await validateForm()
  if (!isValid) return
  emit('nextStep', formData.value)
}

onMounted(() => {
  if (props.singleInvites && props.singleInvites.length) {
    removeUser(0)
    props.singleInvites.forEach((invitee) => {
      addExistingUser(invitee)
    })
  }
})
</script>
