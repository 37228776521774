<template>
  <div v-if="!loading && dueDateItems" class="mt-10">
    <h2>{{ t('ToDo.Title') }}</h2>

    <ContentCard class="mt-5">
      <template #header>
        <TabBar>
          <TabBarItem v-model="selectedTab" selected-value="open">{{ t('ToDo.open') }}</TabBarItem>
          <TabBarItem v-model="selectedTab" selected-value="done">{{ t('ToDo.done') }}</TabBarItem>
        </TabBar>
      </template>

      <template v-if="filteredDueDates.length">
        <DueDateItem
          v-for="dueDateItem in filteredDueDates"
          :key="dueDateItem.id"
          :due-date-item="dueDateItem"
          data-test-id="due-date-item"
          @completed="dueDateItemCompleted"
        />
      </template>
      <div v-else-if="!isDoneTab" class="flex flex-col items-center">
        <img class="mt-4 w-[285px]" src="/assets/images/duedates_done.svg" />
        <p class="mb-6 mt-8">{{ t('ToDo.allDone') }}</p>
      </div>
      <p v-else class="my-6 text-center">{{ t('ToDo.noneDone') }}</p>
    </ContentCard>
  </div>
</template>

<script lang="ts">
export default { name: 'DueDates' }
</script>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { useDueDateItemsQuery, useCreateDueDateCompletionMutation, useDeleteDueDateCompletionMutation } from '@/gql/myomr'
import { TabBar, TabBarItem } from '@/ui/Tabs'
const { t } = useI18n()

const selectedTab = ref<'open' | 'done'>('open')

const { result, loading } = useDueDateItemsQuery()
const { mutate: createDueDateCompletion } = useCreateDueDateCompletionMutation()
const { mutate: deleteDueDateCompletion } = useDeleteDueDateCompletionMutation()

const dueDateItems = computed(() => result.value?.dueDateItems)

const filteredDueDates = computed(() => {
  if (!dueDateItems.value) return []

  return (
    dueDateItems.value
      .filter((item) => item.completed === isDoneTab.value)
      // Sort by dueAt ASC, but if dueAt is missing then show it last
      .sort((a, b) => {
        if (a.dueAt === b.dueAt) return 0
        if (!a.dueAt) return -1
        if (!b.dueAt) return 1

        const aDate = Date.parse(a.dueAt)
        const bDate = Date.parse(b.dueAt)

        return aDate > bDate ? -1 : 1
      })
      .reverse()
  )
})

const isDoneTab = computed(() => selectedTab.value === 'done')

function dueDateItemCompleted(id: string, completed: boolean) {
  if (completed) {
    completeDueDateItem(id)
  } else {
    deleteDueDateItemCompletion(id)
  }
}

function completeDueDateItem(id: string) {
  createDueDateCompletion({ dueDateItemId: id }).then((mutationResult) => {
    if (!mutationResult?.data?.createDueDateCompletion) return

    result.value = { dueDateItems: mutationResult.data.createDueDateCompletion.dueDateItems }
  })
}

function deleteDueDateItemCompletion(id: string) {
  deleteDueDateCompletion({ dueDateItemId: id }).then((mutationResult) => {
    if (!mutationResult?.data?.deleteDueDateCompletion) return

    result.value = { dueDateItems: mutationResult.data.deleteDueDateCompletion.dueDateItems }
  })
}
</script>
