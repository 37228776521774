<template>
  <div class="my-6 flex flex-row items-end justify-between">
    <div>
      <h3 class="line-clamp-1 text-xl font-semibold" :title="props.title">{{ props.title }}</h3>
      <div class="flex flex-row">
        <div class="flex shrink-0 grow-0 items-center">
          <SvgIcon :path="mdiCalendar" :size="24" class="mr-2 grow-0" />
          <div>
            <span>{{ getFormattedDateRange(props.startsAt, props.endsAt) }}</span>
          </div>
        </div>
        <div class="flex shrink-0 grow-0 items-center p-2">
          <SvgIcon :path="mdiAccountMultipleOutline" :size="24" class="mr-2 grow-0" />
          {{ props.leadCount }}
        </div>
      </div>
    </div>
    <div>
      <DropDown wrapper-class="w-full">
        <template #activator="{ open }">
          <button class="btn-secondary-purple-m flex shrink-0 grow-0 items-center p-2 uppercase" @click.prevent="open">
            <SvgIcon :path="mdiTrayArrowDown" :size="16" class="mr-2 grow-0" />
            {{ t('EventHeader.downloadLeadsHeadline').toString().replace(' ', '&nbsp;') }}
          </button>
        </template>
        <template #default>
          <ul class="m-0 list-none divide-y divide-solid divide-grey-300 p-0">
            <li v-for="format in exportFormats" :key="`format-${format}`" class="cursor-pointer px-2 py-2 text-left hover:bg-grey-100">
              <a :href="downloadUrl(format)" class="flex text-black visited:text-black">
                {{ t(`exportFormats.${format}`) }}
              </a>
            </li>
          </ul>
        </template>
      </DropDown>
    </div>
  </div>
</template>

<script setup lang="ts">
import { mdiCalendar, mdiAccountMultipleOutline, mdiTrayArrowDown } from '@mdi/js'
import { DropDown } from '@ramp106/omrjs-core-ui'
import { useI18n } from 'vue-i18n'

import { formatDateSpan, formatDate } from '@/helpers/formatDateSpan'
import { exportFormats, type exportFormat } from '@/services/ApiService'

const props = defineProps<{
  title: string
  startsAt?: string | null | undefined
  endsAt?: string | null | undefined
  leadCount: number
  downloadUrl: (format: exportFormat) => string
}>()

const { t } = useI18n()

function getFormattedDateRange(beginningDate: string | null | undefined, endDate: string | null | undefined): string {
  if (beginningDate && endDate) {
    return formatDateSpan({ beginning: new Date(beginningDate), end: new Date(endDate), monthNames: false })
  } else if (beginningDate) {
    return formatDate({ date: new Date(beginningDate), monthNames: false })
  } else {
    return ''
  }
}
</script>
