<template>
  <div class="relative h-7 w-7">
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
        <circle cx="14.5" cy="14.7273" r="13.5" fill="#F3F4F6" stroke="#D4D6D9" />
      </svg>
    </div>
    <div class="absolute inset-0 flex items-center justify-center">
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="15" viewBox="0 0 17 15" fill="none">
        <g clip-path="url(#clip0_6826_2639)">
          <path
            d="M11.3202 1.51697H2.50694C2.13836 1.51697 1.82284 1.64751 1.56037 1.90858C1.29789 2.16966 1.16666 2.4835 1.16666 2.85012V14.8484L3.84723 12.1821H13.2292C13.5978 12.1821 13.9133 12.0516 14.1758 11.7905C14.4383 11.5295 14.5695 11.2156 14.5695 10.849V6.03443L13.2292 7.35232V10.849H3.27761L2.50694 11.5989V2.85012H9.97994L11.3202 1.51697Z"
            fill="#6E737D"
          />
          <path
            d="M8.07232 4.43738L6.99491 5.50905L9.64984 8.14984L16.114 1.72009L15.0366 0.648438L9.64986 6.00651L8.07232 4.43738Z"
            fill="#6E737D"
          />
        </g>
        <defs>
          <clipPath id="clip0_6826_2639">
            <rect width="16" height="16" fill="white" transform="translate(0.5 0.181885)" />
          </clipPath>
        </defs>
      </svg>
    </div>
  </div>
</template>
