import { gql } from '@apollo/client/core'
import * as VueApolloComposable from '@vue/apollo-composable'
import type * as VueCompositionApi from 'vue'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
export type ReactiveFunction<TParam> = () => TParam
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  ISO8601DateTime: { input: string; output: string }
  JSON: { input: any; output: any }
  Upload: { input: File | File[]; output: File | File[] }
}

export type AgenciesResult = {
  __typename?: 'AgenciesResult'
  agencies: Array<Agency>
  pagination: Pagination
}

export type Agency = Attachable &
  Contactable & {
    __typename?: 'Agency'
    alwaysShowLink: Scalars['Boolean']['output']
    assignedBadges: Array<AssignedBadge>
    categories: Array<Category>
    companySlug?: Maybe<Scalars['String']['output']>
    contactFormRecipients?: Maybe<Array<Scalars['String']['output']>>
    ctaPrimaryHide: Scalars['Boolean']['output']
    ctaSecondary?: Maybe<Link>
    description?: Maybe<Scalars['String']['output']>
    descriptionTranslations: Array<Translation>
    documents: Array<Asset>
    featureCategories: Array<AgencyFeatureCategory>
    features: Array<AgencyFeature>
    globalId: Scalars['ID']['output']
    hero?: Maybe<Asset>
    heroActive: Scalars['Boolean']['output']
    id: Scalars['ID']['output']
    industries: Array<Industry>
    linkText?: Maybe<Scalars['String']['output']>
    linkTextTranslations: Array<Translation>
    linkedin?: Maybe<Scalars['String']['output']>
    locations: Array<Location>
    logo?: Maybe<Asset>
    pageVisitReportEmails?: Maybe<Array<Scalars['String']['output']>>
    products: Array<Product>
    remote: Scalars['Boolean']['output']
    screenshots: Array<Asset>
    seoData?: Maybe<SeoData>
    shortDescription?: Maybe<Scalars['String']['output']>
    shortDescriptionTranslations: Array<Translation>
    slug: Scalars['ID']['output']
    title: Scalars['String']['output']
    traits: Array<AgencyTrait>
    twitter?: Maybe<Scalars['String']['output']>
    url?: Maybe<Scalars['String']['output']>
    vendorEmailAddresses?: Maybe<Array<Scalars['String']['output']>>
  }

export type AgencyAttributeFilterFacet = {
  __typename?: 'AgencyAttributeFilterFacet'
  docCount: Scalars['Int']['output']
  key: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type AgencyCitiesFacet = {
  __typename?: 'AgencyCitiesFacet'
  agenciesCount: Scalars['Int']['output']
  name: Scalars['String']['output']
  slug: Scalars['String']['output']
}

export type AgencyFeature = {
  __typename?: 'AgencyFeature'
  featureCategory?: Maybe<AgencyFeatureCategory>
  name: Scalars['String']['output']
  nameTranslations: Array<Translation>
}

export type AgencyFeatureCategory = {
  __typename?: 'AgencyFeatureCategory'
  features: Array<AgencyFeature>
  name: Scalars['String']['output']
  nameTranslations: Array<Translation>
}

export type AgencySearchFilters = {
  /** Array of industry slugs */
  industries?: InputMaybe<Array<Scalars['String']['input']>>
  /** Array of city slugs */
  locations?: InputMaybe<Array<Scalars['String']['input']>>
  /** Array of product slugs */
  products?: InputMaybe<Array<Scalars['ID']['input']>>
  remote?: InputMaybe<Scalars['Boolean']['input']>
  term?: InputMaybe<Scalars['String']['input']>
}

export enum AgencySortingEnum {
  BadgesAndTitle = 'badges_and_title',
  Id = 'id',
  Title = 'title',
}

export type AgencyTrait = {
  __typename?: 'AgencyTrait'
  description?: Maybe<Scalars['String']['output']>
  kind: AgencyTraitKindEnum
  position: Scalars['Int']['output']
  title?: Maybe<Scalars['String']['output']>
}

export enum AgencyTraitKindEnum {
  Certificate = 'certificate',
  Usp = 'usp',
}

export type AlternativeProduct = {
  __typename?: 'AlternativeProduct'
  metaTagIndex?: Maybe<Scalars['Boolean']['output']>
  product: Product
}

export type AlternativeProductsResult = {
  __typename?: 'AlternativeProductsResult'
  alternatives: Array<AlternativeProduct>
  pagination: Pagination
}

export type Answer = {
  __typename?: 'Answer'
  question: Question
  surveyResponse: SurveyResponse
  upload?: Maybe<Asset>
  /** @deprecated This value is going to be removed */
  uploadUrl?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** Attributes for creating or updating an answer */
export type AnswerAttributes = {
  questionId?: InputMaybe<Scalars['ID']['input']>
  upload?: InputMaybe<Scalars['Upload']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

export type AnswerError = {
  __typename?: 'AnswerError'
  errors: Array<Scalars['String']['output']>
  questionId: Scalars['ID']['output']
  value?: Maybe<Scalars['String']['output']>
}

export enum ArrayMatchEnum {
  All = 'all',
  Any = 'any',
}

export type Asset = {
  __typename?: 'Asset'
  /** @deprecated Use url instead */
  blobUrl: Scalars['String']['output']
  contentType: Scalars['String']['output']
  errors?: Maybe<Array<FieldError>>
  filename: Scalars['String']['output']
  id?: Maybe<Scalars['ID']['output']>
  name: Scalars['String']['output']
  nameTranslations: Array<Translation>
  position?: Maybe<Scalars['Int']['output']>
  previewFilename?: Maybe<Scalars['String']['output']>
  previewUrl?: Maybe<Scalars['String']['output']>
  size: Scalars['Float']['output']
  url: Scalars['String']['output']
}

export type AssetPreviewUrlArgs = {
  height?: InputMaybe<Scalars['Int']['input']>
  width?: InputMaybe<Scalars['Int']['input']>
}

export type AssetAttributes = {
  deleteCustomPreview?: InputMaybe<Scalars['Boolean']['input']>
  file?: InputMaybe<Scalars['Upload']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  nameTranslations?: InputMaybe<Array<TranslationAttributes>>
  position?: InputMaybe<Scalars['Int']['input']>
  preview?: InputMaybe<Scalars['Upload']['input']>
}

export type AssignedBadge = {
  __typename?: 'AssignedBadge'
  assignedRecordSlug: Scalars['String']['output']
  assignedRecordType: Scalars['String']['output']
  badgeName: Scalars['String']['output']
  badgeType: Scalars['String']['output']
  category?: Maybe<Category>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  timeframe?: Maybe<Scalars['String']['output']>
  url?: Maybe<Scalars['String']['output']>
}

export type Attachable = {
  documents: Array<Asset>
  globalId: Scalars['ID']['output']
  hero?: Maybe<Asset>
  logo?: Maybe<Asset>
  screenshots: Array<Asset>
}

export type Category = {
  __typename?: 'Category'
  agencies: Array<Agency>
  averageCustomerSupportScore?: Maybe<Scalars['Float']['output']>
  averageEaseOfSetup?: Maybe<Scalars['Float']['output']>
  averageEaseOfUse?: Maybe<Scalars['Float']['output']>
  averageRequirementsScore?: Maybe<Scalars['Float']['output']>
  descriptionAuthorCompany?: Maybe<Scalars['String']['output']>
  descriptionAuthorImage?: Maybe<Scalars['String']['output']>
  descriptionAuthorName?: Maybe<Scalars['String']['output']>
  descriptionAuthorPosition?: Maybe<Scalars['String']['output']>
  /** @deprecated Use seo_data.top_section_body instead */
  descriptionTopHtml?: Maybe<Scalars['String']['output']>
  displayOrTitle: Scalars['String']['output']
  displayTitle: Scalars['String']['output']
  hidden: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  kind: CategoryKindEnum
  products?: Maybe<Array<Product>>
  published: Scalars['Boolean']['output']
  relatedAgencies: Array<Agency>
  relatedCategories: Array<Category>
  relatedProducts: Array<Product>
  reviewCount?: Maybe<Scalars['Int']['output']>
  searchScore?: Maybe<Scalars['Float']['output']>
  seoData?: Maybe<SeoData>
  slug: Scalars['ID']['output']
  subcategories: Array<Category>
  subpages: Array<ProductListingPage>
  title: Scalars['String']['output']
  topCategory: Scalars['Boolean']['output']
}

export type CategoryProductsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  mainCategoryFilter?: InputMaybe<Scalars['Boolean']['input']>
  sortBy?: InputMaybe<ExploreProductsSortingEnum>
}

export enum CategoryKindEnum {
  ForAgencies = 'for_agencies',
  ForProducts = 'for_products',
}

/** Autogenerated input type of ChangePricingPlans */
export type ChangePricingPlansInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  pricingPlansAttributes: Array<PricingPlanAttributes>
  productId: Scalars['ID']['input']
}

export type ComparisonAlternativesResult = {
  __typename?: 'ComparisonAlternativesResult'
  alternativesPagination: ComparisonPagination
  products: Array<Product>
  versusProducts?: Maybe<Array<VersusProduct>>
}

export type ComparisonPagination = {
  __typename?: 'ComparisonPagination'
  comparisonProductIndex?: Maybe<Scalars['Int']['output']>
  currentPage: Scalars['Int']['output']
  hasNextPage: Scalars['Boolean']['output']
  hasPrevPage: Scalars['Boolean']['output']
  nextPage?: Maybe<Scalars['Int']['output']>
  perPage: Scalars['Int']['output']
  prevPage?: Maybe<Scalars['Int']['output']>
  totalCount: Scalars['Int']['output']
  totalPages?: Maybe<Scalars['Int']['output']>
}

export type ContactRequest = {
  __typename?: 'ContactRequest'
  contactRequestType?: Maybe<ContactRequestContactRequestTypeEnum>
  /** @deprecated Type has changed to ContactableType */
  contactable: ProductOrAgency
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  errors?: Maybe<Array<FieldError>>
  id?: Maybe<Scalars['ID']['output']>
  message: Scalars['String']['output']
  /** @deprecated Use contactable instead */
  product?: Maybe<Product>
  senderCompany?: Maybe<Scalars['String']['output']>
  senderCompanyPosition?: Maybe<Scalars['String']['output']>
  senderEmail: Scalars['String']['output']
  senderName: Scalars['String']['output']
  senderPhone?: Maybe<Scalars['String']['output']>
  sentAt?: Maybe<Scalars['ISO8601DateTime']['output']>
}

export enum ContactRequestContactRequestTypeEnum {
  ContactForm = 'contact_form',
  CustomQuote = 'custom_quote',
  FileDownload = 'file_download',
}

export type Contactable = {
  globalId: Scalars['ID']['output']
}

export enum ContentHubDownvoteReasonEnum {
  NotWhatExpected = 'not_what_expected',
  OtherReason = 'other_reason',
  TooSpecific = 'too_specific',
  TooUnspecific = 'too_unspecific',
  WrongContent = 'wrong_content',
}

export type ContentHubVote = {
  __typename?: 'ContentHubVote'
  createdAt: Scalars['ISO8601DateTime']['output']
  downvoteOtherReason?: Maybe<Scalars['String']['output']>
  downvoteReason?: Maybe<ContentHubDownvoteReasonEnum>
  id: Scalars['ID']['output']
  slug: Scalars['String']['output']
  vote: Scalars['Int']['output']
}

/** Autogenerated input type of CreateContactRequest */
export type CreateContactRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  contactRequestType: Scalars['String']['input']
  message?: InputMaybe<Scalars['String']['input']>
  /** @deprecated Use contactableId instead */
  productSlug?: InputMaybe<Scalars['ID']['input']>
  senderCompany?: InputMaybe<Scalars['String']['input']>
  senderCompanyPosition?: InputMaybe<Scalars['String']['input']>
  senderEmail: Scalars['String']['input']
  senderName: Scalars['String']['input']
  senderPhone?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated input type of CreateContentHubVote */
export type CreateContentHubVoteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  downvoteOtherReason?: InputMaybe<Scalars['String']['input']>
  downvoteReason?: InputMaybe<ContentHubDownvoteReasonEnum>
  slug: Scalars['String']['input']
  vote: Scalars['Int']['input']
}

/** Autogenerated return type of CreateContentHubVote. */
export type CreateContentHubVotePayload = {
  __typename?: 'CreateContentHubVotePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  vote?: Maybe<ContentHubVote>
}

/** Autogenerated input type of CreateDocumentDownloadRequest */
export type CreateDocumentDownloadRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  documentId: Scalars['ID']['input']
  senderCompany?: InputMaybe<Scalars['String']['input']>
  senderEmail: Scalars['String']['input']
  senderName: Scalars['String']['input']
}

/** Autogenerated return type of CreateDocumentDownloadRequest. */
export type CreateDocumentDownloadRequestPayload = {
  __typename?: 'CreateDocumentDownloadRequestPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  fileUrl?: Maybe<Scalars['String']['output']>
}

/** Autogenerated input type of Create */
export type CreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** @deprecated Use attachable instead */
  productId?: InputMaybe<Scalars['ID']['input']>
  screenshotAttributes: AssetAttributes
}

/** Autogenerated input type of CreatePageVisitSession */
export type CreatePageVisitSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  company: PageVisitCompany
  pageVisits: Array<PageVisit>
  sessionId: Scalars['ID']['input']
}

/** Autogenerated return type of CreatePageVisitSession. */
export type CreatePageVisitSessionPayload = {
  __typename?: 'CreatePageVisitSessionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors?: Maybe<Array<Error>>
  status?: Maybe<Scalars['String']['output']>
}

/** Autogenerated return type of Create. */
export type CreatePayload = {
  __typename?: 'CreatePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** All record screenshots after mutation */
  screenshots?: Maybe<Array<Asset>>
}

/** Autogenerated input type of CreateSurveyResponseReminder */
export type CreateSurveyResponseReminderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  message: Scalars['String']['input']
  sendCopyToVendor?: InputMaybe<Scalars['Boolean']['input']>
  surveyResponseId: Scalars['ID']['input']
}

/** Autogenerated input type of CreateUserProducts */
export type CreateUserProductsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  productSlugs: Array<Scalars['ID']['input']>
}

/** Autogenerated return type of CreateUserProducts. */
export type CreateUserProductsPayload = {
  __typename?: 'CreateUserProductsPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  userProducts: Array<UserProduct>
}

/** Autogenerated return type of CreateUserProducts. */
export type CreateUserProductsPayloadUserProductsArgs = {
  categories?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type Currency = {
  __typename?: 'Currency'
  code: Scalars['String']['output']
  name: Scalars['String']['output']
  symbol: Scalars['String']['output']
}

export enum DealPlanBoostInSearchEnum {
  No = 'no',
  Yes = 'yes',
}

export enum DealPlanCtaCustomQuoteEnum {
  No = 'no',
  Yes = 'yes',
}

export enum DealPlanCtaPrimaryEnum {
  No = 'no',
  Yes = 'yes',
}

export enum DealPlanCtaSecondaryEnum {
  No = 'no',
  Yes = 'yes',
}

export enum DealPlanFileDownloadPassedThroughEnum {
  No = 'no',
  Yes = 'yes',
}

export enum DealPlanHeroImageEnum {
  No = 'no',
  Yes = 'yes',
}

export enum DealPlanPageVisitReportsEnum {
  Basic = 'basic',
  Extended = 'extended',
  None = 'none',
}

export enum DealPlanProfileClaimedEnum {
  No = 'no',
  Yes = 'yes',
}

export enum DealPlanReviewRepliesAllowedEnum {
  No = 'no',
  Yes = 'yes',
}

export enum DealPlanTypeEnum {
  Plus = 'plus',
  Premium = 'premium',
  PremiumReach = 'premium_reach',
}

/** Autogenerated input type of Delete */
export type DeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  ids: Array<Scalars['ID']['input']>
  /** @deprecated Use attachable instead */
  productId?: InputMaybe<Scalars['ID']['input']>
}

/** Autogenerated return type of Delete. */
export type DeletePayload = {
  __typename?: 'DeletePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** All record screenshots after mutation */
  screenshots?: Maybe<Array<Asset>>
}

/** Autogenerated input type of DeleteUserProduct */
export type DeleteUserProductInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  productSlug: Scalars['ID']['input']
}

/** Autogenerated return type of DeleteUserProduct. */
export type DeleteUserProductPayload = {
  __typename?: 'DeleteUserProductPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  result: Scalars['Boolean']['output']
}

export type Error = {
  __typename?: 'Error'
  code: Scalars['Int']['output']
  message: Scalars['String']['output']
}

export type ExploreProductsResult = {
  __typename?: 'ExploreProductsResult'
  averageCustomerSupportScore?: Maybe<Scalars['Float']['output']>
  averageEaseOfSetup?: Maybe<Scalars['Float']['output']>
  averageEaseOfUse?: Maybe<Scalars['Float']['output']>
  averageRecommendationRating?: Maybe<Scalars['Float']['output']>
  averageRequirementsScore?: Maybe<Scalars['Float']['output']>
  averageRightDirection?: Maybe<Scalars['Float']['output']>
  companyFieldFacet: Array<KeywordFacet>
  marketSegmentFacet: Array<MarketSegmentFacet>
  pagination: Pagination
  products: Array<Product>
  recommendationRatingFacet: Array<RecommendationRatingFacet>
}

export enum ExploreProductsSortingEnum {
  Alphabetical = 'alphabetical',
  LowestPopularity = 'lowest_popularity',
  Popularity = 'popularity',
  Relevance = 'relevance',
  WeightedScore = 'weighted_score',
}

export type Feature = {
  __typename?: 'Feature'
  averageScore?: Maybe<Scalars['Int']['output']>
  featureCategoryId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type FieldError = {
  __typename?: 'FieldError'
  field: Scalars['String']['output']
  message: Scalars['String']['output']
  value?: Maybe<Scalars['String']['output']>
}

export enum FilterByIsSuiteEnum {
  IsProduct = 'is_product',
  IsSuite = 'is_suite',
  IsSuiteOrIsProduct = 'is_suite_or_is_product',
}

export enum FilterByPublicationStatusEnum {
  Draft = 'draft',
  Published = 'published',
  PublishedOrDraft = 'published_or_draft',
}

export type Incentive = {
  __typename?: 'Incentive'
  amount?: Maybe<Scalars['Float']['output']>
  description: Scalars['String']['output']
  /** @deprecated Use slug instead */
  id: Scalars['ID']['output']
  inactiveDueToResponseLimit: Scalars['Boolean']['output']
  slug: Scalars['ID']['output']
  title: Scalars['String']['output']
  valid: Scalars['Boolean']['output']
}

export type IncentiveValidityInfo = {
  __typename?: 'IncentiveValidityInfo'
  product: Product
  valid: Scalars['Boolean']['output']
}

export type IncentivisedProducts = {
  __typename?: 'IncentivisedProducts'
  incentive: Incentive
  products: Array<Product>
}

export type Industry = {
  __typename?: 'Industry'
  agencies: Array<Agency>
  name: Scalars['String']['output']
  slug: Scalars['String']['output']
}

export type KeywordFacet = {
  __typename?: 'KeywordFacet'
  docCount: Scalars['Int']['output']
  key: Scalars['String']['output']
}

export type Link = {
  __typename?: 'Link'
  label?: Maybe<Scalars['String']['output']>
  url: Scalars['String']['output']
}

export type Location = {
  __typename?: 'Location'
  city?: Maybe<Scalars['String']['output']>
  citySlug?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['String']['output']>
  fullAddress?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  latitude: Scalars['Float']['output']
  longitude: Scalars['Float']['output']
  state?: Maybe<Scalars['String']['output']>
  zip?: Maybe<Scalars['String']['output']>
}

/** Autogenerated input type of ManageDocumentDelete */
export type ManageDocumentDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  ids: Array<Scalars['ID']['input']>
  /** @deprecated Use attachable instead */
  productId?: InputMaybe<Scalars['ID']['input']>
}

/** Autogenerated input type of ManageDocumentUpdate */
export type ManageDocumentUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  documentAttributes: Array<AssetAttributes>
  /** @deprecated Use attachable instead */
  productId?: InputMaybe<Scalars['ID']['input']>
}

/** Autogenerated input type of ManageDocumentsCreate */
export type ManageDocumentsCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  documentAttributes: AssetAttributes
  /** @deprecated Use attachable instead */
  productId?: InputMaybe<Scalars['ID']['input']>
}

export type ManageExploreSurveyResponses = {
  __typename?: 'ManageExploreSurveyResponses'
  pagination: Pagination
  recommendationRatingFacet: Array<RecommendationRatingFacet>
  reminderStatusFacet: Array<SurveyResponsesReminderStatusFacet>
  replyStatusFacet: Array<SurveyResponsesReplyStatusFacet>
  results: Array<ManageSurveyResponse>
  stateFacet: Array<SurveyResponsesStateFacet>
}

export type ManageMutation = {
  __typename?: 'ManageMutation'
  changePricingPlans?: Maybe<Array<PricingPlan>>
  /** Mutation to create product document. Returns all product documents after changes. */
  createDocument: Array<Asset>
  createScreenshot?: Maybe<CreatePayload>
  createSurveyResponseReminder?: Maybe<ManageSurveyResponseReminder>
  /** Mutation to delete product documents. Returns all product documents after changes. */
  deleteDocuments: Array<Asset>
  deleteReviewReply?: Maybe<ReviewReply>
  deleteScreenshots?: Maybe<DeletePayload>
  /** Mutation to update product documents. Returns all product documents after changes. */
  updateDocuments: Array<Asset>
  updateProduct?: Maybe<UpdateProductPayload>
  updateScreenshots?: Maybe<UpdatePayload>
  upsertReviewReply?: Maybe<ReviewReply>
}

export type ManageMutationChangePricingPlansArgs = {
  input: ChangePricingPlansInput
}

export type ManageMutationCreateDocumentArgs = {
  input: ManageDocumentsCreateInput
}

export type ManageMutationCreateScreenshotArgs = {
  input: CreateInput
}

export type ManageMutationCreateSurveyResponseReminderArgs = {
  input: CreateSurveyResponseReminderInput
}

export type ManageMutationDeleteDocumentsArgs = {
  input: ManageDocumentDeleteInput
}

export type ManageMutationDeleteReviewReplyArgs = {
  input: ManageReviewRepliesDeleteInput
}

export type ManageMutationDeleteScreenshotsArgs = {
  input: DeleteInput
}

export type ManageMutationUpdateDocumentsArgs = {
  input: ManageDocumentUpdateInput
}

export type ManageMutationUpdateProductArgs = {
  input: UpdateProductInput
}

export type ManageMutationUpdateScreenshotsArgs = {
  input: UpdateInput
}

export type ManageMutationUpsertReviewReplyArgs = {
  input: ManageReviewRepliesUpsertInput
}

export type ManageOptions = {
  __typename?: 'ManageOptions'
  currencies: Array<Currency>
  pricingSubscriptionModes: Array<PricingSubscriptionMode>
  pricingTextOptions: Array<PricingTextOption>
}

export type ManageQuery = {
  __typename?: 'ManageQuery'
  agencies?: Maybe<Array<Agency>>
  agency: Agency
  contactRequests: Array<ContactRequest>
  options: ManageOptions
  product: Product
  products?: Maybe<Array<Product>>
  surveyResponse: ManageSurveyResponse
  surveyResponses?: Maybe<ManageExploreSurveyResponses>
}

export type ManageQueryAgencyArgs = {
  id: Scalars['ID']['input']
}

export type ManageQueryContactRequestsArgs = {
  contactRequestType?: InputMaybe<ContactRequestContactRequestTypeEnum>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  productSlug?: InputMaybe<Scalars['ID']['input']>
}

export type ManageQueryProductArgs = {
  id: Scalars['ID']['input']
}

export type ManageQuerySurveyResponseArgs = {
  id: Scalars['ID']['input']
}

export type ManageQuerySurveyResponsesArgs = {
  filters?: InputMaybe<ManageSurveyResponsesSearchFilters>
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  productSlug?: InputMaybe<Scalars['ID']['input']>
  sortBy?: InputMaybe<SurveyResponsesSortByEnum>
  sortDirection?: InputMaybe<SortDirectionEnum>
}

/** Autogenerated input type of ManageReviewRepliesDelete */
export type ManageReviewRepliesDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** ID or slug of the review reply */
  id: Scalars['ID']['input']
}

/** Autogenerated input type of ManageReviewRepliesUpsert */
export type ManageReviewRepliesUpsertInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  notifyReviewer?: InputMaybe<Scalars['Boolean']['input']>
  personalized?: InputMaybe<Scalars['Boolean']['input']>
  /** Reply message */
  reply: Scalars['String']['input']
  /** ID or slug of the review */
  reviewId: Scalars['ID']['input']
}

export type ManageSurveyResponse = {
  __typename?: 'ManageSurveyResponse'
  aasmState: Scalars['String']['output']
  anonymous: Scalars['Boolean']['output']
  createdAt: Scalars['ISO8601DateTime']['output']
  firstPublishedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  id: Scalars['ID']['output']
  incentive?: Maybe<Incentive>
  kind: SurveyResponseKinds
  manualReminderDisabled: Scalars['Boolean']['output']
  publishedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  rejectedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  rejectedReason?: Maybe<SurveyResponseRejectedReasons>
  /** @deprecated Use manualReminderDisabled instead */
  reminderDisabled: Scalars['Boolean']['output']
  reminders: Array<ManageSurveyResponseReminder>
  review?: Maybe<Review>
  reviewerCompany?: Maybe<Scalars['String']['output']>
  reviewerCompanySize?: Maybe<Scalars['String']['output']>
  reviewerFirstName?: Maybe<Scalars['String']['output']>
  reviewerIndustry?: Maybe<Scalars['String']['output']>
  reviewerPosition?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['ISO8601DateTime']['output']
  video?: Maybe<Asset>
}

export type ManageSurveyResponseReminder = {
  __typename?: 'ManageSurveyResponseReminder'
  automatic: Scalars['Boolean']['output']
  createdAt: Scalars['ISO8601DateTime']['output']
  id: Scalars['ID']['output']
  message?: Maybe<Scalars['String']['output']>
  reminderType: SurveyResponseReminderReminderTypes
  sentAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  status: SurveyResponseReminderStatuses
  surveyResponse: ManageSurveyResponse
  userId?: Maybe<Scalars['Int']['output']>
}

export type ManageSurveyResponsesSearchFilters = {
  rating?: InputMaybe<Array<RecommendationRatingKeyEnum>>
  reminderStatus?: InputMaybe<Array<SurveyResponsesReminderStatusEnum>>
  replyStatus?: InputMaybe<Array<SurveyResponsesReplyStatusEnum>>
  state?: InputMaybe<Array<SurveyResponsesStateEnum>>
  term?: InputMaybe<Scalars['String']['input']>
  updatedSince?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  updatedTo?: InputMaybe<Scalars['ISO8601DateTime']['input']>
}

export type MarketSegmentFacet = {
  __typename?: 'MarketSegmentFacet'
  docCount: Scalars['Int']['output']
  key: MarketSegmentKeyEnum
}

export enum MarketSegmentKeyEnum {
  Enterprise = 'enterprise',
  Medium = 'medium',
  Small = 'small',
}

export type Mutation = {
  __typename?: 'Mutation'
  createContactRequest?: Maybe<ContactRequest>
  createContentHubVote?: Maybe<CreateContentHubVotePayload>
  createDocumentDownloadRequest?: Maybe<CreateDocumentDownloadRequestPayload>
  createPageVisitSession?: Maybe<CreatePageVisitSessionPayload>
  createUserProducts?: Maybe<CreateUserProductsPayload>
  deleteUserProduct?: Maybe<DeleteUserProductPayload>
  manage: ManageMutation
  operations: OperationsMutation
  processSurveyResponse?: Maybe<ProcessSurveyResponsePayload>
  updateCompanySlug?: Maybe<UpdateCompanySlugPayload>
}

export type MutationCreateContactRequestArgs = {
  input: CreateContactRequestInput
}

export type MutationCreateContentHubVoteArgs = {
  input: CreateContentHubVoteInput
}

export type MutationCreateDocumentDownloadRequestArgs = {
  input: CreateDocumentDownloadRequestInput
}

export type MutationCreatePageVisitSessionArgs = {
  input: CreatePageVisitSessionInput
}

export type MutationCreateUserProductsArgs = {
  input: CreateUserProductsInput
}

export type MutationDeleteUserProductArgs = {
  input: DeleteUserProductInput
}

export type MutationProcessSurveyResponseArgs = {
  input: ProcessSurveyResponseInput
}

export type MutationUpdateCompanySlugArgs = {
  input: UpdateCompanySlugInput
}

export type OperationsMutation = {
  __typename?: 'OperationsMutation'
  updateProductDescription?: Maybe<UpdateProductDescriptionPayload>
}

export type OperationsMutationUpdateProductDescriptionArgs = {
  input: UpdateProductDescriptionInput
}

export type PageVisit = {
  agencyCategorySlugs?: InputMaybe<Array<Scalars['String']['input']>>
  agencySlugs?: InputMaybe<Array<Scalars['String']['input']>>
  categorySlugs?: InputMaybe<Array<Scalars['String']['input']>>
  createdAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  productSlugs?: InputMaybe<Array<Scalars['String']['input']>>
  subjectId?: InputMaybe<Scalars['String']['input']>
  subjectType?: InputMaybe<Scalars['String']['input']>
  url?: InputMaybe<Scalars['String']['input']>
}

export type PageVisitCompany = {
  address?: InputMaybe<Scalars['String']['input']>
  alexaRank?: InputMaybe<Scalars['Int']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  companyName?: InputMaybe<Scalars['String']['input']>
  companyWebsite?: InputMaybe<Scalars['String']['input']>
  country?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  employees?: InputMaybe<Scalars['String']['input']>
  financialReport?: InputMaybe<Scalars['String']['input']>
  financialReportCurrency?: InputMaybe<Scalars['String']['input']>
  foundedYear?: InputMaybe<Scalars['Int']['input']>
  linkedinIndustryCategory?: InputMaybe<Scalars['String']['input']>
  linkedinIndustryCode?: InputMaybe<Scalars['String']['input']>
  linkedinUrl?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  sector?: InputMaybe<Scalars['String']['input']>
  zipcode?: InputMaybe<Scalars['String']['input']>
}

export type PaginatedReviews = {
  __typename?: 'PaginatedReviews'
  frequentWords: Array<Scalars['String']['output']>
  pagination: Pagination
  purposes: Array<ReviewsPurposeType>
  reviews: Array<Review>
  statistics: ReviewStatistics
}

export type Pagination = {
  __typename?: 'Pagination'
  currentPage: Scalars['Int']['output']
  hasNextPage: Scalars['Boolean']['output']
  hasPrevPage: Scalars['Boolean']['output']
  perPage: Scalars['Int']['output']
  totalCount: Scalars['Int']['output']
  totalPages: Scalars['Int']['output']
}

export type PopularProducts = {
  __typename?: 'PopularProducts'
  category: Category
  products: Array<Product>
}

export type PricingPlan = {
  __typename?: 'PricingPlan'
  billingPeriod?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  descriptionTranslations: Array<Translation>
  errors?: Maybe<Array<FieldError>>
  id?: Maybe<Scalars['ID']['output']>
  name?: Maybe<Scalars['String']['output']>
  nameTranslations: Array<Translation>
  perkList?: Maybe<Array<Scalars['String']['output']>>
  perksTranslations: Array<Translation>
  position?: Maybe<Scalars['Int']['output']>
  priceCents?: Maybe<Scalars['Int']['output']>
  priceCurrency?: Maybe<Scalars['String']['output']>
  priceCurrencySymbol?: Maybe<Scalars['String']['output']>
  priceTextValue?: Maybe<Scalars['String']['output']>
  pricingSubscriptionModeId?: Maybe<Scalars['ID']['output']>
  pricingTextOptionId?: Maybe<Scalars['ID']['output']>
  productId: Scalars['ID']['output']
  startingPrice: Scalars['Boolean']['output']
}

export type PricingPlanAttributes = {
  _destroy?: InputMaybe<Scalars['Boolean']['input']>
  /** @deprecated Use descriptionTranslations instead */
  description?: InputMaybe<Scalars['String']['input']>
  descriptionTranslations?: InputMaybe<Array<TranslationAttributes>>
  id?: InputMaybe<Scalars['ID']['input']>
  /** @deprecated Use nameTranslations instead */
  name?: InputMaybe<Scalars['String']['input']>
  nameTranslations?: InputMaybe<Array<TranslationAttributes>>
  /** @deprecated Use perksTranslations instead */
  perks?: InputMaybe<Array<Scalars['String']['input']>>
  perksTranslations?: InputMaybe<Array<TranslationAttributes>>
  position?: InputMaybe<Scalars['Int']['input']>
  priceCents?: InputMaybe<Scalars['Int']['input']>
  priceCurrency?: InputMaybe<Scalars['String']['input']>
  pricingSubscriptionModeId?: InputMaybe<Scalars['String']['input']>
  pricingTextOptionId?: InputMaybe<Scalars['String']['input']>
  startingPrice?: InputMaybe<Scalars['Boolean']['input']>
}

export type PricingSubscriptionMode = {
  __typename?: 'PricingSubscriptionMode'
  id: Scalars['ID']['output']
  inDays?: Maybe<Scalars['Int']['output']>
  name: Scalars['String']['output']
  nameTranslations: Array<Translation>
}

export type PricingTextOption = {
  __typename?: 'PricingTextOption'
  free: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  nameTranslations: Array<Translation>
}

/** Autogenerated input type of ProcessSurveyResponse */
export type ProcessSurveyResponseInput = {
  answers: Array<AnswerAttributes>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  incentive?: InputMaybe<Scalars['String']['input']>
  incentiveGroup?: InputMaybe<Scalars['String']['input']>
  locale?: InputMaybe<Scalars['String']['input']>
  marketingConsentAccepted?: InputMaybe<Scalars['Boolean']['input']>
  productSlug: Scalars['String']['input']
  source?: InputMaybe<Scalars['String']['input']>
  submitted?: InputMaybe<Scalars['Boolean']['input']>
  surveyId: Scalars['ID']['input']
  trigger?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of ProcessSurveyResponse. */
export type ProcessSurveyResponsePayload = {
  __typename?: 'ProcessSurveyResponsePayload'
  answerErrors?: Maybe<Array<AnswerError>>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors?: Maybe<Array<Scalars['String']['output']>>
  surveyResponse?: Maybe<SurveyResponse>
}

export type Product = Attachable &
  Contactable & {
    __typename?: 'Product'
    activeTrialPhase?: Maybe<TrialPhase>
    agencies: Array<Agency>
    alternativesFooterDescription?: Maybe<Scalars['String']['output']>
    alwaysShowLink: Scalars['Boolean']['output']
    anytimeSupport?: Maybe<ProductAnytimeSupportEnum>
    applicableIncentive?: Maybe<Incentive>
    assignedBadges: Array<AssignedBadge>
    atpSeoData?: Maybe<SeoData>
    averageCustomerSupportScore?: Maybe<Scalars['Float']['output']>
    averageEaseOfSetup?: Maybe<Scalars['Float']['output']>
    averageEaseOfUse?: Maybe<Scalars['Float']['output']>
    averageRecommendationRating?: Maybe<Scalars['Float']['output']>
    averageRequirementsScore?: Maybe<Scalars['Float']['output']>
    averageRightDirection?: Maybe<Scalars['Float']['output']>
    categories: Array<Category>
    cloud?: Maybe<ProductCloudEnum>
    companySlug?: Maybe<Scalars['String']['output']>
    config: ProductConfig
    contactFormRecipients?: Maybe<Array<Scalars['String']['output']>>
    /** @deprecated Please use ctaSecondary.label */
    ctaLabel?: Maybe<Scalars['String']['output']>
    ctaPrimaryHide: Scalars['Boolean']['output']
    ctaSecondary?: Maybe<Link>
    ctaSecondaryLabelTranslations: Array<Translation>
    ctaSecondaryUrlTranslations: Array<Translation>
    /** @deprecated Please use ctaSecondary.url */
    ctaUrl?: Maybe<Scalars['String']['output']>
    /** @deprecated Please use config instead */
    dealPlanType?: Maybe<DealPlanTypeEnum>
    /** @deprecated Please use mainCategory.slug */
    defaultCategoryId: Scalars['ID']['output']
    description?: Maybe<Scalars['String']['output']>
    descriptionTranslations: Array<Translation>
    disclaimerText?: Maybe<Scalars['String']['output']>
    disclaimerTextTranslations: Array<Translation>
    disclaimerUrl?: Maybe<Scalars['String']['output']>
    disclaimerUrlTranslations: Array<Translation>
    documents: Array<Asset>
    errors?: Maybe<Array<FieldError>>
    euCompanyAffiliated?: Maybe<ProductEuCompanyAffiliatedEnum>
    euLocation?: Maybe<ProductEuLocationEnum>
    euStandardContract?: Maybe<ProductEuStandardContractEnum>
    featureCategories: Array<ProductFeatureCategory>
    features: Array<Feature>
    freeDemo: Scalars['Boolean']['output']
    freeMode?: Maybe<Scalars['String']['output']>
    freePlanAvailable?: Maybe<Scalars['Boolean']['output']>
    freeTrial: Scalars['Boolean']['output']
    germanLocation?: Maybe<ProductGermanLocationEnum>
    germanSupport?: Maybe<ProductGermanSupportEnum>
    globalId: Scalars['ID']['output']
    hero?: Maybe<Asset>
    heroActive: Scalars['Boolean']['output']
    id: Scalars['ID']['output']
    isSuite: Scalars['Boolean']['output']
    linkText?: Maybe<Scalars['String']['output']>
    linkTextTranslations: Array<Translation>
    linkedin?: Maybe<Scalars['String']['output']>
    logo?: Maybe<Asset>
    mainCategory: Category
    negativeReview?: Maybe<Review>
    noPricingPlans?: Maybe<Scalars['Boolean']['output']>
    noPricingPlansHint?: Maybe<Scalars['String']['output']>
    noPricingPlansHintTranslations: Array<Translation>
    onPremise?: Maybe<ProductOnPremiseEnum>
    pageVisitReportEmails?: Maybe<Array<Scalars['String']['output']>>
    positiveReview?: Maybe<Review>
    pricingPlans: Array<PricingPlan>
    pricingSeoData?: Maybe<SeoData>
    pricingUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
    /** @deprecated Use assigned_badges instead */
    productBadges: Array<ProductBadge>
    productSuites: Array<Product>
    published?: Maybe<Scalars['Boolean']['output']>
    publishedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
    purposes: Array<Category>
    qualityState?: Maybe<Scalars['String']['output']>
    /** Total number of reviews */
    reviewCount: Scalars['Int']['output']
    /** Number of reviews in given category */
    reviewCountInCategory?: Maybe<Scalars['Int']['output']>
    reviewSummary?: Maybe<ReviewSummary>
    schemaMarkupType: ProductSchemaMarkupTypeEnum
    screenshots: Array<Asset>
    searchScore?: Maybe<Scalars['Float']['output']>
    seoData?: Maybe<SeoData>
    shortDescription?: Maybe<Scalars['String']['output']>
    shortDescriptionTranslations: Array<Translation>
    showDisclaimer?: Maybe<Scalars['Boolean']['output']>
    slug: Scalars['ID']['output']
    suiteProducts: Array<Product>
    title: Scalars['String']['output']
    topProduct?: Maybe<Scalars['Boolean']['output']>
    trialPeriod?: Maybe<Scalars['Float']['output']>
    trialUnit?: Maybe<ProductTrialUnitEnum>
    trustedPartners: Array<Agency>
    twitter?: Maybe<Scalars['String']['output']>
    url?: Maybe<Scalars['String']['output']>
    urlTranslations: Array<Translation>
    usesNewSurvey?: Maybe<Scalars['Boolean']['output']>
    vendorEmailAddresses?: Maybe<Array<Scalars['String']['output']>>
    weightedScore?: Maybe<Scalars['Float']['output']>
    /** Weighted score in given category */
    weightedScoreInCategory?: Maybe<Scalars['Float']['output']>
  }

export type ProductApplicableIncentiveArgs = {
  incentiveGroup?: InputMaybe<Scalars['String']['input']>
  source?: InputMaybe<Scalars['String']['input']>
}

export type ProductReviewCountInCategoryArgs = {
  categoryId: Scalars['ID']['input']
}

export type ProductWeightedScoreInCategoryArgs = {
  categoryId: Scalars['ID']['input']
}

export enum ProductAnytimeSupportEnum {
  No = 'no',
  NoInfo = 'no_info',
  Yes = 'yes',
  YesConditional = 'yes_conditional',
}

/** Attributes for updating a product */
export type ProductAttributes = {
  anytimeSupport?: InputMaybe<ProductAnytimeSupportEnum>
  cloud?: InputMaybe<ProductCloudEnum>
  configAttributes?: InputMaybe<ProductConfigAttributes>
  contactFormRecipients?: InputMaybe<Array<Scalars['String']['input']>>
  ctaPrimaryHide?: InputMaybe<Scalars['Boolean']['input']>
  /** @deprecated Use ctaSecondaryLabelTranslations instead */
  ctaSecondaryLabel?: InputMaybe<Scalars['String']['input']>
  ctaSecondaryLabelTranslations?: InputMaybe<Array<TranslationAttributes>>
  /** @deprecated Use ctaSecondaryUrlTranslations instead */
  ctaSecondaryUrl?: InputMaybe<Scalars['String']['input']>
  ctaSecondaryUrlTranslations?: InputMaybe<Array<TranslationAttributes>>
  /** @deprecated Use descriptionTranslations instead */
  description?: InputMaybe<Scalars['String']['input']>
  descriptionTranslations?: InputMaybe<Array<TranslationAttributes>>
  /** @deprecated Use disclaimerTextTranslations instead */
  disclaimerText?: InputMaybe<Scalars['String']['input']>
  disclaimerTextTranslations?: InputMaybe<Array<TranslationAttributes>>
  /** @deprecated Use disclaimerUrlTranslations instead */
  disclaimerUrl?: InputMaybe<Scalars['String']['input']>
  disclaimerUrlTranslations?: InputMaybe<Array<TranslationAttributes>>
  euCompanyAffiliated?: InputMaybe<ProductEuCompanyAffiliatedEnum>
  euLocation?: InputMaybe<ProductEuLocationEnum>
  euStandardContract?: InputMaybe<ProductEuStandardContractEnum>
  freeDemo?: InputMaybe<Scalars['Boolean']['input']>
  freeTrial?: InputMaybe<Scalars['Boolean']['input']>
  germanLocation?: InputMaybe<ProductGermanLocationEnum>
  germanSupport?: InputMaybe<ProductGermanSupportEnum>
  heroImage?: InputMaybe<Scalars['Upload']['input']>
  heroVideo?: InputMaybe<Scalars['Upload']['input']>
  /** @deprecated Use linkTextTranslations instead */
  linkText?: InputMaybe<Scalars['String']['input']>
  linkTextTranslations?: InputMaybe<Array<TranslationAttributes>>
  linkedin?: InputMaybe<Scalars['String']['input']>
  logo?: InputMaybe<Scalars['Upload']['input']>
  noPricingPlans?: InputMaybe<Scalars['Boolean']['input']>
  /** @deprecated Use noPricingPlansHintTranslations instead */
  noPricingPlansHint?: InputMaybe<Scalars['String']['input']>
  noPricingPlansHintTranslations?: InputMaybe<Array<TranslationAttributes>>
  onPremise?: InputMaybe<ProductOnPremiseEnum>
  pageVisitReportEmails?: InputMaybe<Array<Scalars['String']['input']>>
  /** @deprecated Use shortDescriptionTranslations instead */
  shortDescription?: InputMaybe<Scalars['String']['input']>
  shortDescriptionTranslations?: InputMaybe<Array<TranslationAttributes>>
  showDisclaimer?: InputMaybe<Scalars['Boolean']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  trialPeriod?: InputMaybe<Scalars['Float']['input']>
  trialUnit?: InputMaybe<ProductTrialUnitEnum>
  twitter?: InputMaybe<Scalars['String']['input']>
  /** @deprecated Use urlTranslations instead */
  url?: InputMaybe<Scalars['String']['input']>
  urlTranslations?: InputMaybe<Array<TranslationAttributes>>
  vendorEmailAddresses?: InputMaybe<Array<Scalars['String']['input']>>
}

export type ProductBadge = {
  __typename?: 'ProductBadge'
  assignedRecordSlug: Scalars['String']['output']
  assignedRecordType: Scalars['String']['output']
  badgeName: Scalars['String']['output']
  badgeType: Scalars['String']['output']
  category?: Maybe<Category>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  product: Product
  timeframe?: Maybe<Scalars['String']['output']>
  url?: Maybe<Scalars['String']['output']>
}

export enum ProductCloudEnum {
  No = 'no',
  NoInfo = 'no_info',
  Yes = 'yes',
  YesConditional = 'yes_conditional',
}

export type ProductConfig = {
  __typename?: 'ProductConfig'
  boostInSearchAdminCalculated: DealPlanBoostInSearchEnum
  boostInSearchCached: ProductConfigBoostInSearchCachedEnum
  boostInSearchVendor: ProductConfigBoostInSearchVendorEnum
  boostInSearchVendorPossibleOptions: Array<Scalars['String']['output']>
  ctaCustomQuoteAdminCalculated: DealPlanCtaCustomQuoteEnum
  ctaCustomQuoteCached: ProductConfigCtaCustomQuoteCachedEnum
  ctaCustomQuoteVendor: ProductConfigCtaCustomQuoteVendorEnum
  ctaCustomQuoteVendorPossibleOptions: Array<Scalars['String']['output']>
  ctaPrimaryAdminCalculated: DealPlanCtaPrimaryEnum
  ctaPrimaryCached: ProductConfigCtaPrimaryCachedEnum
  ctaPrimaryVendor: ProductConfigCtaPrimaryVendorEnum
  ctaPrimaryVendorPossibleOptions: Array<Scalars['String']['output']>
  ctaSecondaryAdminCalculated: DealPlanCtaSecondaryEnum
  ctaSecondaryCached: ProductConfigCtaSecondaryCachedEnum
  ctaSecondaryVendor: ProductConfigCtaSecondaryVendorEnum
  ctaSecondaryVendorPossibleOptions: Array<Scalars['String']['output']>
  errors?: Maybe<Array<FieldError>>
  fileDownloadPassedThroughAdminCalculated: DealPlanFileDownloadPassedThroughEnum
  fileDownloadPassedThroughCached: ProductConfigFileDownloadPassedThroughCachedEnum
  fileDownloadPassedThroughVendor: ProductConfigFileDownloadPassedThroughVendorEnum
  fileDownloadPassedThroughVendorPossibleOptions: Array<Scalars['String']['output']>
  heroImageAdminCalculated: DealPlanHeroImageEnum
  heroImageCached: ProductConfigHeroImageCachedEnum
  heroImageVendor: ProductConfigHeroImageVendorEnum
  heroImageVendorPossibleOptions: Array<Scalars['String']['output']>
  pageVisitReportsAdminCalculated: DealPlanPageVisitReportsEnum
  pageVisitReportsCached: ProductConfigPageVisitReportsCachedEnum
  pageVisitReportsVendor: ProductConfigPageVisitReportsVendorEnum
  pageVisitReportsVendorPossibleOptions: Array<Scalars['String']['output']>
  profileClaimedAdminCalculated: DealPlanProfileClaimedEnum
  profileClaimedCached: ProductConfigProfileClaimedCachedEnum
  profileClaimedVendor: ProductConfigProfileClaimedVendorEnum
  profileClaimedVendorPossibleOptions: Array<Scalars['String']['output']>
  reviewRepliesAllowedAdminCalculated: DealPlanReviewRepliesAllowedEnum
  reviewRepliesAllowedCached: ProductConfigReviewRepliesAllowedCachedEnum
  reviewRepliesAllowedVendor: ProductConfigReviewRepliesAllowedVendorEnum
  reviewRepliesAllowedVendorPossibleOptions: Array<Scalars['String']['output']>
}

/** Attributes for updating a product config */
export type ProductConfigAttributes = {
  boostInSearchVendor?: InputMaybe<ProductConfigBoostInSearchVendorEnum>
  ctaCustomQuoteVendor?: InputMaybe<ProductConfigCtaCustomQuoteVendorEnum>
  ctaPrimaryVendor?: InputMaybe<ProductConfigCtaPrimaryVendorEnum>
  ctaSecondaryVendor?: InputMaybe<ProductConfigCtaSecondaryVendorEnum>
  fileDownloadPassedThroughVendor?: InputMaybe<ProductConfigFileDownloadPassedThroughVendorEnum>
  heroImageVendor?: InputMaybe<ProductConfigHeroImageVendorEnum>
  pageVisitReportsVendor?: InputMaybe<ProductConfigPageVisitReportsVendorEnum>
  profileClaimedVendor?: InputMaybe<ProductConfigProfileClaimedVendorEnum>
  reviewRepliesAllowedVendor?: InputMaybe<ProductConfigReviewRepliesAllowedVendorEnum>
}

export enum ProductConfigBoostInSearchCachedEnum {
  No = 'no',
  Yes = 'yes',
}

export enum ProductConfigBoostInSearchVendorEnum {
  Automatic = 'automatic',
  No = 'no',
  Yes = 'yes',
}

export enum ProductConfigCtaCustomQuoteCachedEnum {
  No = 'no',
  Yes = 'yes',
}

export enum ProductConfigCtaCustomQuoteVendorEnum {
  Automatic = 'automatic',
  No = 'no',
  Yes = 'yes',
}

export enum ProductConfigCtaPrimaryCachedEnum {
  No = 'no',
  Yes = 'yes',
}

export enum ProductConfigCtaPrimaryVendorEnum {
  Automatic = 'automatic',
  No = 'no',
  Yes = 'yes',
}

export enum ProductConfigCtaSecondaryCachedEnum {
  No = 'no',
  Yes = 'yes',
}

export enum ProductConfigCtaSecondaryVendorEnum {
  Automatic = 'automatic',
  No = 'no',
  Yes = 'yes',
}

export enum ProductConfigFileDownloadPassedThroughCachedEnum {
  No = 'no',
  Yes = 'yes',
}

export enum ProductConfigFileDownloadPassedThroughVendorEnum {
  Automatic = 'automatic',
  No = 'no',
  Yes = 'yes',
}

export enum ProductConfigHeroImageCachedEnum {
  No = 'no',
  Yes = 'yes',
}

export enum ProductConfigHeroImageVendorEnum {
  Automatic = 'automatic',
  No = 'no',
  Yes = 'yes',
}

export enum ProductConfigPageVisitReportsCachedEnum {
  Basic = 'basic',
  Extended = 'extended',
  None = 'none',
}

export enum ProductConfigPageVisitReportsVendorEnum {
  Automatic = 'automatic',
  Basic = 'basic',
  Extended = 'extended',
  None = 'none',
}

export enum ProductConfigProfileClaimedCachedEnum {
  No = 'no',
  Yes = 'yes',
}

export enum ProductConfigProfileClaimedVendorEnum {
  Automatic = 'automatic',
  No = 'no',
  Yes = 'yes',
}

export enum ProductConfigReviewRepliesAllowedCachedEnum {
  No = 'no',
  Yes = 'yes',
}

export enum ProductConfigReviewRepliesAllowedVendorEnum {
  Automatic = 'automatic',
  No = 'no',
  Yes = 'yes',
}

export enum ProductEuCompanyAffiliatedEnum {
  No = 'no',
  NoInfo = 'no_info',
  Yes = 'yes',
}

export enum ProductEuLocationEnum {
  No = 'no',
  NoInfo = 'no_info',
  Yes = 'yes',
  YesConditional = 'yes_conditional',
}

export enum ProductEuStandardContractEnum {
  No = 'no',
  NoInfo = 'no_info',
  Yes = 'yes',
}

export type ProductFeatureCategory = {
  __typename?: 'ProductFeatureCategory'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export enum ProductGermanLocationEnum {
  No = 'no',
  NoInfo = 'no_info',
  Yes = 'yes',
  YesConditional = 'yes_conditional',
}

export enum ProductGermanSupportEnum {
  No = 'no',
  NoInfo = 'no_info',
  Yes = 'yes',
  YesConditional = 'yes_conditional',
}

export type ProductListingPage = {
  __typename?: 'ProductListingPage'
  /** List of filters that are overwritten for this listing */
  appliedFilters: Array<Scalars['String']['output']>
  category: Category
  linkText?: Maybe<Scalars['String']['output']>
  path: Scalars['String']['output']
  seoData: SeoData
}

export enum ProductOnPremiseEnum {
  No = 'no',
  NoInfo = 'no_info',
  Yes = 'yes',
  YesConditional = 'yes_conditional',
}

export type ProductOrAgency = Agency | Product

export enum ProductSchemaMarkupTypeEnum {
  Product = 'product',
  SoftwareApplication = 'software_application',
}

export enum ProductTrialUnitEnum {
  Day = 'day',
  Month = 'month',
  Week = 'week',
  Year = 'year',
}

export type Query = {
  __typename?: 'Query'
  agencies: AgenciesResult
  agency: Agency
  alternativeProducts?: Maybe<AlternativeProductsResult>
  assignedBadge?: Maybe<AssignedBadge>
  categories: Array<Category>
  category: Category
  exploreAgencies: Result
  exploreProducts?: Maybe<ExploreProductsResult>
  featureCategories?: Maybe<Array<ProductFeatureCategory>>
  incentive?: Maybe<Incentive>
  /** Get information if incentive is valid for selected products */
  incentiveValidation?: Maybe<Array<IncentiveValidityInfo>>
  /** Lists all products applying for incentives in given group */
  incentivisedProducts?: Maybe<Array<IncentivisedProducts>>
  listProducts: Array<Product>
  manage?: Maybe<ManageQuery>
  ping?: Maybe<Scalars['String']['output']>
  popularProducts?: Maybe<Array<PopularProducts>>
  product?: Maybe<Product>
  /** @deprecated Use assignedBadge instead */
  productBadge?: Maybe<ProductBadge>
  productListingPage?: Maybe<ProductListingPage>
  productSlugs?: Maybe<Array<Scalars['ID']['output']>>
  /** @deprecated Use exploreProducts instead */
  products?: Maybe<ExploreProductsResult>
  /** @deprecated Use listProducts instead */
  productsByCompany: Array<Product>
  productsForComparison?: Maybe<ComparisonAlternativesResult>
  reviews?: Maybe<PaginatedReviews>
  /** Search and filter products for reviews */
  search: SearchResult
  /**
   * Search and filter products for reviews
   * @deprecated Use search instead
   */
  searchProducts: SearchResult
  survey?: Maybe<Survey>
  topProducts?: Maybe<Array<Product>>
  userProducts?: Maybe<Array<UserProduct>>
  vendorProducts?: Maybe<VendorProductsResult>
  /** AVB products */
  versusProducts?: Maybe<VersusProductsResult>
}

export type QueryAgenciesArgs = {
  categorySlug?: InputMaybe<Scalars['ID']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  productSlug?: InputMaybe<Scalars['ID']['input']>
  slug?: InputMaybe<Scalars['ID']['input']>
}

export type QueryAgencyArgs = {
  slug: Scalars['ID']['input']
}

export type QueryAlternativeProductsArgs = {
  exclude?: InputMaybe<Array<Scalars['ID']['input']>>
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  slug: Scalars['ID']['input']
}

export type QueryAssignedBadgeArgs = {
  id: Scalars['ID']['input']
}

export type QueryCategoriesArgs = {
  filterHidden?: InputMaybe<Scalars['Boolean']['input']>
  kind?: InputMaybe<CategoryKindEnum>
  limit?: InputMaybe<Scalars['Int']['input']>
  onlyTopLevel?: InputMaybe<Scalars['Boolean']['input']>
  parentCategory?: InputMaybe<Scalars['ID']['input']>
  sortByReviewCount?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryCategoryArgs = {
  slug: Scalars['ID']['input']
}

export type QueryExploreAgenciesArgs = {
  categorySlug?: InputMaybe<Scalars['ID']['input']>
  filters?: InputMaybe<AgencySearchFilters>
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  sortBy?: InputMaybe<AgencySortingEnum>
  sortDirection?: InputMaybe<SortDirectionEnum>
}

export type QueryExploreProductsArgs = {
  anytimeSupport?: InputMaybe<ProductAnytimeSupportEnum>
  categorySlug?: InputMaybe<Scalars['ID']['input']>
  cloud?: InputMaybe<ProductCloudEnum>
  euCompanyAffiliated?: InputMaybe<ProductEuCompanyAffiliatedEnum>
  euLocation?: InputMaybe<ProductEuLocationEnum>
  euStandardContract?: InputMaybe<ProductEuStandardContractEnum>
  exclude?: InputMaybe<Array<Scalars['ID']['input']>>
  freePlanAvailable?: InputMaybe<Scalars['Boolean']['input']>
  germanLocation?: InputMaybe<ProductGermanLocationEnum>
  germanSupport?: InputMaybe<ProductGermanSupportEnum>
  listingPagePath?: InputMaybe<Scalars['String']['input']>
  marketSegments?: InputMaybe<Array<MarketSegmentKeyEnum>>
  onPremise?: InputMaybe<ProductOnPremiseEnum>
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  profileClaimed?: InputMaybe<Scalars['Boolean']['input']>
  recommendationRatings?: InputMaybe<Array<RecommendationRatingKeyEnum>>
  showInArticleWidget?: InputMaybe<Scalars['Boolean']['input']>
  sortBy?: InputMaybe<ExploreProductsSortingEnum>
  term?: InputMaybe<Scalars['String']['input']>
}

export type QueryFeatureCategoriesArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>
  product?: InputMaybe<Scalars['ID']['input']>
}

export type QueryIncentiveArgs = {
  categorySlug?: InputMaybe<Scalars['String']['input']>
  hashedSlug?: InputMaybe<Scalars['String']['input']>
  productSlug?: InputMaybe<Scalars['String']['input']>
  slug?: InputMaybe<Scalars['ID']['input']>
}

export type QueryIncentiveValidationArgs = {
  incentive: Scalars['String']['input']
  products: Array<Scalars['ID']['input']>
}

export type QueryIncentivisedProductsArgs = {
  exclude?: InputMaybe<Array<Scalars['ID']['input']>>
  incentiveGroup?: InputMaybe<Scalars['String']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  source?: InputMaybe<Scalars['String']['input']>
}

export type QueryListProductsArgs = {
  agencySlugs?: InputMaybe<Array<Scalars['String']['input']>>
  companySlugs?: InputMaybe<Array<Scalars['String']['input']>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type QueryPopularProductsArgs = {
  categoryLimit?: InputMaybe<Scalars['Int']['input']>
}

export type QueryProductArgs = {
  slug: Scalars['ID']['input']
  status?: InputMaybe<FilterByPublicationStatusEnum>
}

export type QueryProductBadgeArgs = {
  id: Scalars['ID']['input']
}

export type QueryProductListingPageArgs = {
  category: Scalars['ID']['input']
  path: Scalars['String']['input']
}

export type QueryProductsArgs = {
  anytimeSupport?: InputMaybe<ProductAnytimeSupportEnum>
  categorySlug?: InputMaybe<Scalars['ID']['input']>
  cloud?: InputMaybe<ProductCloudEnum>
  euCompanyAffiliated?: InputMaybe<ProductEuCompanyAffiliatedEnum>
  euLocation?: InputMaybe<ProductEuLocationEnum>
  euStandardContract?: InputMaybe<ProductEuStandardContractEnum>
  exclude?: InputMaybe<Array<Scalars['ID']['input']>>
  freePlanAvailable?: InputMaybe<Scalars['Boolean']['input']>
  germanLocation?: InputMaybe<ProductGermanLocationEnum>
  germanSupport?: InputMaybe<ProductGermanSupportEnum>
  listingPagePath?: InputMaybe<Scalars['String']['input']>
  marketSegments?: InputMaybe<Array<MarketSegmentKeyEnum>>
  onPremise?: InputMaybe<ProductOnPremiseEnum>
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  profileClaimed?: InputMaybe<Scalars['Boolean']['input']>
  recommendationRatings?: InputMaybe<Array<RecommendationRatingKeyEnum>>
  showInArticleWidget?: InputMaybe<Scalars['Boolean']['input']>
  sortBy?: InputMaybe<ExploreProductsSortingEnum>
  term?: InputMaybe<Scalars['String']['input']>
}

export type QueryProductsByCompanyArgs = {
  agencySlugs?: InputMaybe<Array<Scalars['String']['input']>>
  companySlugs?: InputMaybe<Array<Scalars['String']['input']>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type QueryProductsForComparisonArgs = {
  comparedProduct: Scalars['ID']['input']
  perPage?: InputMaybe<Scalars['Int']['input']>
  perSideMin?: InputMaybe<Scalars['Int']['input']>
  product: Scalars['ID']['input']
}

export type QueryReviewsArgs = {
  filters?: InputMaybe<ReviewsSearchFilters>
  product: Scalars['ID']['input']
}

export type QuerySearchArgs = {
  categories?: InputMaybe<Array<Scalars['ID']['input']>>
  filterByIsSuite?: InputMaybe<FilterByIsSuiteEnum>
  filterByPublicationStatus?: InputMaybe<FilterByPublicationStatusEnum>
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  sortBy?: InputMaybe<SearchSortingEnum>
  term: Scalars['String']['input']
}

export type QuerySearchProductsArgs = {
  categories?: InputMaybe<Array<Scalars['ID']['input']>>
  filterByIsSuite?: InputMaybe<FilterByIsSuiteEnum>
  filterByPublicationStatus?: InputMaybe<FilterByPublicationStatusEnum>
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  sortBy?: InputMaybe<SearchSortingEnum>
  term: Scalars['String']['input']
}

export type QuerySurveyArgs = {
  productSlug: Scalars['ID']['input']
}

export type QueryUserProductsArgs = {
  categories?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type QueryVersusProductsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
}

export type Question = {
  __typename?: 'Question'
  constraints?: Maybe<Scalars['JSON']['output']>
  dependentOptions?: Maybe<Scalars['JSON']['output']>
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  inputType?: Maybe<Scalars['String']['output']>
  placeholder?: Maybe<Scalars['String']['output']>
  position?: Maybe<Scalars['Int']['output']>
  questionType?: Maybe<Scalars['String']['output']>
  required?: Maybe<Scalars['Boolean']['output']>
  selectOptions?: Maybe<Scalars['JSON']['output']>
  title: Scalars['String']['output']
}

export type RecommendationRatingFacet = {
  __typename?: 'RecommendationRatingFacet'
  docCount: Scalars['Int']['output']
  key: RecommendationRatingKeyEnum
}

export enum RecommendationRatingKeyEnum {
  Bad = 'bad',
  Best = 'best',
  Better = 'better',
  Good = 'good',
  Worse = 'worse',
}

export type Result = {
  __typename?: 'Result'
  industries: Array<AgencyAttributeFilterFacet>
  locations: Array<AgencyAttributeFilterFacet>
  otherCities?: Maybe<Array<AgencyCitiesFacet>>
  pagination: Pagination
  products: Array<AgencyAttributeFilterFacet>
  remote: Array<AgencyAttributeFilterFacet>
  results: Array<Agency>
}

export type Review = {
  __typename?: 'Review'
  anonymous: Scalars['Boolean']['output']
  companyField?: Maybe<Scalars['String']['output']>
  companyName?: Maybe<Scalars['String']['output']>
  companyPosition: Scalars['String']['output']
  companySize?: Maybe<Scalars['String']['output']>
  customerSupportScore?: Maybe<Scalars['Int']['output']>
  easeOfSetup?: Maybe<Scalars['Int']['output']>
  easeOfUse?: Maybe<Scalars['Int']['output']>
  /** @deprecated Use slug instead */
  id: Scalars['ID']['output']
  incentive: Scalars['Boolean']['output']
  negative?: Maybe<Scalars['String']['output']>
  positive?: Maybe<Scalars['String']['output']>
  problems?: Maybe<Scalars['String']['output']>
  product: Product
  publishedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  purpose: Array<Scalars['String']['output']>
  recommendationScore: Scalars['Int']['output']
  requirementsScore?: Maybe<Scalars['Int']['output']>
  reviewReply?: Maybe<ReviewReply>
  reviewerFirstName?: Maybe<Scalars['String']['output']>
  /** @deprecated Use reviewerFirstName instead */
  reviewerFirstname?: Maybe<Scalars['String']['output']>
  reviewerLastName?: Maybe<Scalars['String']['output']>
  /** @deprecated Use reviewerLastName instead */
  reviewerName?: Maybe<Scalars['String']['output']>
  rightDirection?: Maybe<ReviewRightDirectionEnum>
  searchScore?: Maybe<Scalars['Float']['output']>
  slug: Scalars['ID']['output']
  sticky?: Maybe<Scalars['Boolean']['output']>
  title: Scalars['String']['output']
  video?: Maybe<Asset>
}

export type ReviewReply = {
  __typename?: 'ReviewReply'
  id?: Maybe<Scalars['ID']['output']>
  outdated?: Maybe<Scalars['Boolean']['output']>
  personalized?: Maybe<Scalars['Boolean']['output']>
  reply: Scalars['String']['output']
  senderName?: Maybe<Scalars['String']['output']>
}

export enum ReviewRightDirectionEnum {
  DontKnow = 'dont_know',
  No = 'no',
  Yes = 'yes',
}

export type ReviewStatistics = {
  __typename?: 'ReviewStatistics'
  recommendationScoreBad: Scalars['Int']['output']
  recommendationScoreBest: Scalars['Int']['output']
  recommendationScoreBetter: Scalars['Int']['output']
  recommendationScoreGood: Scalars['Int']['output']
  recommendationScoreWorse: Scalars['Int']['output']
}

export type ReviewSummary = {
  __typename?: 'ReviewSummary'
  createdAt: Scalars['ISO8601DateTime']['output']
  deSummary: Scalars['JSON']['output']
  enSummary: Scalars['JSON']['output']
  errors?: Maybe<Array<FieldError>>
  id: Scalars['ID']['output']
  lastGeneratedAt: Scalars['ISO8601DateTime']['output']
  manualEdit: Scalars['Boolean']['output']
  product: Product
  reviewsCountAtGeneration: Scalars['Int']['output']
  updatedAt: Scalars['ISO8601DateTime']['output']
}

export type ReviewsPurposeType = {
  __typename?: 'ReviewsPurposeType'
  count: Scalars['Int']['output']
  slug: Scalars['String']['output']
  title: Scalars['String']['output']
  total: Scalars['Int']['output']
}

export type ReviewsSearchFilters = {
  companyField?: InputMaybe<Scalars['String']['input']>
  keywordMatch?: InputMaybe<ArrayMatchEnum>
  keywords?: InputMaybe<Array<Scalars['String']['input']>>
  marketSegments?: InputMaybe<Array<MarketSegmentKeyEnum>>
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  /** Suite products slugs */
  products?: InputMaybe<Array<Scalars['ID']['input']>>
  purposeMatch?: InputMaybe<ArrayMatchEnum>
  purposes?: InputMaybe<Array<Scalars['String']['input']>>
  recommendationRatings?: InputMaybe<Array<RecommendationRatingKeyEnum>>
  search?: InputMaybe<Scalars['String']['input']>
  sortBy?: InputMaybe<ReviewsSearchSortingEnum>
}

export enum ReviewsSearchSortingEnum {
  Date = 'date',
  DateAsc = 'date_asc',
  Rating = 'rating',
  RatingAsc = 'rating_asc',
  Relevance = 'relevance',
  /** @deprecated Use relevance instead */
  WeightedScore = 'weighted_score',
}

export type SearchResult = {
  __typename?: 'SearchResult'
  agencies: Array<Agency>
  categories: Array<Category>
  pagination: Pagination
  products: Array<Product>
}

export enum SearchSortingEnum {
  Alphabetical = 'alphabetical',
  LowestPopularity = 'lowest_popularity',
  Popularity = 'popularity',
  Relevance = 'relevance',
  WeightedScore = 'weighted_score',
}

export type SeoData = {
  __typename?: 'SeoData'
  bottomSectionBody?: Maybe<Scalars['String']['output']>
  bottomSectionHeadline?: Maybe<Scalars['String']['output']>
  canonicalTag?: Maybe<Scalars['String']['output']>
  h1?: Maybe<Scalars['String']['output']>
  metaDescription?: Maybe<Scalars['String']['output']>
  metaNoindex: Scalars['Boolean']['output']
  metaTitle?: Maybe<Scalars['String']['output']>
  topSectionBody?: Maybe<Scalars['String']['output']>
}

export enum SortDirectionEnum {
  Asc = 'asc',
  Desc = 'desc',
}

export type Survey = {
  __typename?: 'Survey'
  id: Scalars['ID']['output']
  sections: Array<SurveySection>
  surveyResponse?: Maybe<SurveyResponse>
}

export type SurveyResponse = {
  __typename?: 'SurveyResponse'
  answers?: Maybe<Array<Answer>>
  firstPublishedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  incentive?: Maybe<Incentive>
  marketingConsentVisible: Scalars['Boolean']['output']
  publishedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  survey: Survey
}

export enum SurveyResponseKinds {
  Text = 'text',
  Video = 'video',
}

export enum SurveyResponseRejectedReasons {
  AiGenerated = 'ai_generated',
  FakePerson = 'fake_person',
  FakeToolUse = 'fake_tool_use',
  IncorrectCompany = 'incorrect_company',
  InsufficientChanges = 'insufficient_changes',
  Invalid = 'invalid',
  NegativeReview = 'negative_review',
  OfferExpired = 'offer_expired',
  PrivateUse = 'private_use',
  SelfReview = 'self_review',
  TooShort = 'too_short',
}

export enum SurveyResponseReminderReminderTypes {
  Drafted = 'drafted',
  Published = 'published',
  Rejected = 'rejected',
  Stale = 'stale',
}

export enum SurveyResponseReminderStatuses {
  Queued = 'queued',
  Sent = 'sent',
}

export enum SurveyResponsesReminderStatusEnum {
  Blank = 'blank',
  SentAutomatic = 'sent_automatic',
  SentManual = 'sent_manual',
}

export type SurveyResponsesReminderStatusFacet = {
  __typename?: 'SurveyResponsesReminderStatusFacet'
  docCount: Scalars['Int']['output']
  key: SurveyResponsesReminderStatusEnum
}

export enum SurveyResponsesReplyStatusEnum {
  Blank = 'blank',
  Outdated = 'outdated',
  Sent = 'sent',
}

export type SurveyResponsesReplyStatusFacet = {
  __typename?: 'SurveyResponsesReplyStatusFacet'
  docCount: Scalars['Int']['output']
  key: SurveyResponsesReplyStatusEnum
}

export enum SurveyResponsesSortByEnum {
  Score = '_score',
  CreatedAt = 'created_at',
  RecommendationScore = 'recommendation_score',
  ReviewerCompany = 'reviewer_company',
  ReviewerName = 'reviewer_name',
  UpdatedAt = 'updated_at',
}

export enum SurveyResponsesStateEnum {
  Drafted = 'drafted',
  InRevision = 'in_revision',
  Proposed = 'proposed',
  Published = 'published',
  Rejected = 'rejected',
  Stale = 'stale',
}

export type SurveyResponsesStateFacet = {
  __typename?: 'SurveyResponsesStateFacet'
  docCount: Scalars['Int']['output']
  key: SurveyResponsesStateEnum
}

export type SurveySection = {
  __typename?: 'SurveySection'
  id: Scalars['ID']['output']
  parentSurveySection?: Maybe<SurveySection>
  position?: Maybe<Scalars['Int']['output']>
  questions?: Maybe<Array<Question>>
  sectionId: Scalars['ID']['output']
  sectionType?: Maybe<Scalars['String']['output']>
  sections?: Maybe<Array<SurveySection>>
  survey?: Maybe<Survey>
  title?: Maybe<Scalars['String']['output']>
}

export type Translation = {
  __typename?: 'Translation'
  locale: Scalars['String']['output']
  value?: Maybe<Scalars['String']['output']>
}

export type TranslationAttributes = {
  locale: Scalars['String']['input']
  value: Scalars['String']['input']
}

export type TrialPhase = {
  __typename?: 'TrialPhase'
  validUntil?: Maybe<Scalars['ISO8601DateTime']['output']>
}

/** Autogenerated input type of UpdateCompanySlug */
export type UpdateCompanySlugInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  new?: InputMaybe<Scalars['String']['input']>
  old: Scalars['String']['input']
}

/** Autogenerated return type of UpdateCompanySlug. */
export type UpdateCompanySlugPayload = {
  __typename?: 'UpdateCompanySlugPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
}

/** Autogenerated input type of Update */
export type UpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** @deprecated Use attachable instead */
  productId?: InputMaybe<Scalars['ID']['input']>
  screenshotAttributes: Array<AssetAttributes>
}

/** Autogenerated return type of Update. */
export type UpdatePayload = {
  __typename?: 'UpdatePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** All record screenshots after mutation */
  screenshots?: Maybe<Array<Asset>>
}

/** Autogenerated input type of UpdateProductDescription */
export type UpdateProductDescriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  descriptionDe: Scalars['String']['input']
  id: Scalars['ID']['input']
}

/** Autogenerated return type of UpdateProductDescription. */
export type UpdateProductDescriptionPayload = {
  __typename?: 'UpdateProductDescriptionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors?: Maybe<Array<Scalars['String']['output']>>
}

/** Autogenerated input type of UpdateProduct */
export type UpdateProductInput = {
  attributes: ProductAttributes
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
}

/** Autogenerated return type of UpdateProduct. */
export type UpdateProductPayload = {
  __typename?: 'UpdateProductPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  product?: Maybe<Product>
}

export type UserProduct = {
  __typename?: 'UserProduct'
  id: Scalars['ID']['output']
  product: Product
  surveyState?: Maybe<Scalars['String']['output']>
}

export type VendorAgencies = {
  __typename?: 'VendorAgencies'
  agenciesSlugs?: Maybe<Array<Scalars['String']['output']>>
  agencyCategoriesSlugs?: Maybe<Array<Scalars['String']['output']>>
  productsMainCategorySlugs?: Maybe<Array<Scalars['String']['output']>>
  productsSlugs?: Maybe<Array<Scalars['String']['output']>>
  vendorSlug: Scalars['String']['output']
}

export type VendorMapper = {
  __typename?: 'VendorMapper'
  id?: Maybe<Scalars['ID']['output']>
  slug?: Maybe<Scalars['String']['output']>
}

export type VendorProducts = {
  __typename?: 'VendorProducts'
  categorySlugs?: Maybe<Array<Scalars['String']['output']>>
  mainCategoryId?: Maybe<Scalars['Int']['output']>
  /**
   * plus, premium
   * @deprecated Use pageVisitReport instead, plus -> basic, premium -> extended
   */
  pageVisitMailingPlan: Scalars['String']['output']
  /**
   * basic, extended
   * @deprecated Distinguishable by basicReportProducts and extendedReportProducts instead
   */
  pageVisitReport: Scalars['String']['output']
  /** @deprecated Use pageVisitMailingPlan instead */
  plusPlan: Scalars['Boolean']['output']
  /** @deprecated Use pageVisitMailingPlan instead */
  premiumPlan: Scalars['Boolean']['output']
  productBadgeSlugs?: Maybe<Array<Scalars['String']['output']>>
  productSlugs: Array<Scalars['String']['output']>
  trialPhaseUntil?: Maybe<Scalars['ISO8601DateTime']['output']>
  vendorSlug: Scalars['String']['output']
}

export type VendorProductsResult = {
  __typename?: 'VendorProductsResult'
  agencyCategoryMapper: Array<VendorMapper>
  agencyMapper: Array<VendorMapper>
  basicReportAgencies: Array<VendorAgencies>
  basicReportProducts: Array<VendorProducts>
  categoryMapper: Array<VendorMapper>
  extendedReportAgencies: Array<VendorAgencies>
  extendedReportProducts: Array<VendorProducts>
  productBadgeMapper: Array<VendorMapper>
  productMapper: Array<VendorMapper>
  /** @deprecated Use basicReportProducts and extendedReportProducts instead */
  products: Array<VendorProducts>
}

export type VersusProduct = {
  __typename?: 'VersusProduct'
  /** @deprecated Use productSlug instead */
  productId: Scalars['String']['output']
  productSlug: Scalars['ID']['output']
  seoData?: Maybe<SeoData>
  /** @deprecated Use versusSlug instead */
  versusId: Scalars['String']['output']
  versusSlug: Scalars['ID']['output']
}

export type VersusProductsResult = {
  __typename?: 'VersusProductsResult'
  pagination: Pagination
  versusProducts: Array<VersusProduct>
}

export type AgencyBasicDataFragment = {
  __typename?: 'Agency'
  id: string
  title: string
  slug: string
  shortDescription?: string | null
  remote: boolean
  locations: Array<{ __typename?: 'Location'; city?: string | null; country?: string | null }>
  traits: Array<{ __typename?: 'AgencyTrait' } & AgencyTraitFieldsFragment>
  logo?: ({ __typename?: 'Asset' } & AssetFieldsFragment) | null
}

export type AgencyTraitFieldsFragment = {
  __typename?: 'AgencyTrait'
  kind: AgencyTraitKindEnum
  description?: string | null
  title?: string | null
  position: number
}

export type AssetFieldsFragment = { __typename?: 'Asset'; name: string; size: number; url: string; contentType: string }

export type ProductBadgeFieldsFragment = {
  __typename?: 'ProductBadge'
  id: string
  timeframe?: string | null
  badgeType: string
  badgeName: string
  url?: string | null
  category?: { __typename?: 'Category'; displayOrTitle: string } | null
}

export type ProductBasicDataFragment = {
  __typename?: 'Product'
  id: string
  slug: string
  title: string
  isSuite: boolean
  averageRecommendationRating?: number | null
  reviewCount: number
  productBadges: Array<{ __typename?: 'ProductBadge' } & ProductBadgeFieldsFragment>
  logo?: ({ __typename?: 'Asset' } & AssetFieldsFragment) | null
}

export type ProductConfigFieldsFragment = {
  __typename?: 'ProductConfig'
  ctaCustomQuoteAdminCalculated: DealPlanCtaCustomQuoteEnum
  ctaCustomQuoteCached: ProductConfigCtaCustomQuoteCachedEnum
  ctaCustomQuoteVendor: ProductConfigCtaCustomQuoteVendorEnum
  ctaCustomQuoteVendorPossibleOptions: Array<string>
  ctaPrimaryAdminCalculated: DealPlanCtaPrimaryEnum
  ctaPrimaryCached: ProductConfigCtaPrimaryCachedEnum
  ctaPrimaryVendor: ProductConfigCtaPrimaryVendorEnum
  ctaPrimaryVendorPossibleOptions: Array<string>
  ctaSecondaryAdminCalculated: DealPlanCtaSecondaryEnum
  ctaSecondaryCached: ProductConfigCtaSecondaryCachedEnum
  ctaSecondaryVendor: ProductConfigCtaSecondaryVendorEnum
  ctaSecondaryVendorPossibleOptions: Array<string>
  heroImageAdminCalculated: DealPlanHeroImageEnum
  heroImageCached: ProductConfigHeroImageCachedEnum
  heroImageVendor: ProductConfigHeroImageVendorEnum
  heroImageVendorPossibleOptions: Array<string>
  profileClaimedAdminCalculated: DealPlanProfileClaimedEnum
  profileClaimedCached: ProductConfigProfileClaimedCachedEnum
  profileClaimedVendor: ProductConfigProfileClaimedVendorEnum
  profileClaimedVendorPossibleOptions: Array<string>
  reviewRepliesAllowedAdminCalculated: DealPlanReviewRepliesAllowedEnum
  reviewRepliesAllowedCached: ProductConfigReviewRepliesAllowedCachedEnum
  reviewRepliesAllowedVendor: ProductConfigReviewRepliesAllowedVendorEnum
  reviewRepliesAllowedVendorPossibleOptions: Array<string>
}

export type ProductPricingPlanFragment = {
  __typename?: 'PricingPlan'
  billingPeriod?: string | null
  description?: string | null
  name?: string | null
  id?: string | null
  position?: number | null
  priceCents?: number | null
  priceCurrency?: string | null
  priceCurrencySymbol?: string | null
  priceTextValue?: string | null
  pricingSubscriptionModeId?: string | null
  pricingTextOptionId?: string | null
  startingPrice: boolean
  descriptionTranslations: Array<{ __typename?: 'Translation'; locale: string; value?: string | null }>
  nameTranslations: Array<{ __typename?: 'Translation'; locale: string; value?: string | null }>
  perksTranslations: Array<{ __typename?: 'Translation'; locale: string; value?: string | null }>
}

export type TranslationFieldsFragment = { __typename?: 'Translation'; locale: string; value?: string | null }

export type CreateReviewsProductDocumentMutationVariables = Exact<{
  productId: Scalars['ID']['input']
  documentAttributes: AssetAttributes
}>

export type CreateReviewsProductDocumentMutation = {
  __typename?: 'Mutation'
  manage: { __typename?: 'ManageMutation'; createDocument: Array<{ __typename?: 'Asset'; id?: string | null }> }
}

export type CreateReviewsProductScreenshotMutationVariables = Exact<{
  productId: Scalars['ID']['input']
  screenshotAttributes: AssetAttributes
}>

export type CreateReviewsProductScreenshotMutation = {
  __typename?: 'Mutation'
  manage: { __typename?: 'ManageMutation'; createScreenshot?: { __typename?: 'CreatePayload'; clientMutationId?: string | null } | null }
}

export type CreateSurveyResponseReminderMutationVariables = Exact<{
  input: CreateSurveyResponseReminderInput
}>

export type CreateSurveyResponseReminderMutation = {
  __typename?: 'Mutation'
  manage: {
    __typename?: 'ManageMutation'
    createSurveyResponseReminder?: { __typename?: 'ManageSurveyResponseReminder'; id: string } | null
  }
}

export type DeleteReviewsProductDocumentsMutationVariables = Exact<{
  productId: Scalars['ID']['input']
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input']
}>

export type DeleteReviewsProductDocumentsMutation = {
  __typename?: 'Mutation'
  manage: { __typename?: 'ManageMutation'; deleteDocuments: Array<{ __typename?: 'Asset'; id?: string | null }> }
}

export type DeleteReviewsProductReviewReplyMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteReviewsProductReviewReplyMutation = {
  __typename?: 'Mutation'
  manage: { __typename?: 'ManageMutation'; deleteReviewReply?: { __typename?: 'ReviewReply'; id?: string | null } | null }
}

export type DeleteReviewsProductScreenshotsMutationVariables = Exact<{
  productId: Scalars['ID']['input']
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input']
}>

export type DeleteReviewsProductScreenshotsMutation = {
  __typename?: 'Mutation'
  manage: { __typename?: 'ManageMutation'; deleteScreenshots?: { __typename?: 'DeletePayload'; clientMutationId?: string | null } | null }
}

export type UpdateReviewsProductMutationVariables = Exact<{
  id: Scalars['ID']['input']
  attributes: ProductAttributes
}>

export type UpdateReviewsProductMutation = {
  __typename?: 'Mutation'
  manage: {
    __typename?: 'ManageMutation'
    updateProduct?: { __typename?: 'UpdateProductPayload'; clientMutationId?: string | null } | null
  }
}

export type UpdateReviewsProductDocumentsMutationVariables = Exact<{
  productId: Scalars['ID']['input']
  documentAttributes: Array<AssetAttributes> | AssetAttributes
}>

export type UpdateReviewsProductDocumentsMutation = {
  __typename?: 'Mutation'
  manage: { __typename?: 'ManageMutation'; updateDocuments: Array<{ __typename?: 'Asset'; id?: string | null }> }
}

export type UpdateReviewsProductScreenshotsMutationVariables = Exact<{
  productId: Scalars['ID']['input']
  screenshotAttributes: Array<AssetAttributes> | AssetAttributes
}>

export type UpdateReviewsProductScreenshotsMutation = {
  __typename?: 'Mutation'
  manage: { __typename?: 'ManageMutation'; updateScreenshots?: { __typename?: 'UpdatePayload'; clientMutationId?: string | null } | null }
}

export type UpdateReviewsProductWithPricingPlansMutationVariables = Exact<{
  id: Scalars['ID']['input']
  attributes: ProductAttributes
  pricing: ChangePricingPlansInput
}>

export type UpdateReviewsProductWithPricingPlansMutation = {
  __typename?: 'Mutation'
  manage: {
    __typename?: 'ManageMutation'
    updateProduct?: { __typename?: 'UpdateProductPayload'; clientMutationId?: string | null } | null
    changePricingPlans?: Array<{ __typename?: 'PricingPlan'; name?: string | null }> | null
  }
}

export type UpsertReviewsProductReviewReplyMutationVariables = Exact<{
  notifyReviewer?: InputMaybe<Scalars['Boolean']['input']>
  personalized?: InputMaybe<Scalars['Boolean']['input']>
  reply: Scalars['String']['input']
  reviewId: Scalars['ID']['input']
}>

export type UpsertReviewsProductReviewReplyMutation = {
  __typename?: 'Mutation'
  manage: { __typename?: 'ManageMutation'; upsertReviewReply?: { __typename?: 'ReviewReply'; id?: string | null } | null }
}

export type ReviewsAgenciesQueryVariables = Exact<{ [key: string]: never }>

export type ReviewsAgenciesQuery = {
  __typename?: 'Query'
  manage?: { __typename?: 'ManageQuery'; agencies?: Array<{ __typename?: 'Agency' } & AgencyBasicDataFragment> | null } | null
}

export type ReviewsAgencyByIdQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ReviewsAgencyByIdQuery = {
  __typename?: 'Query'
  manage?: { __typename?: 'ManageQuery'; agency: { __typename?: 'Agency' } & AgencyBasicDataFragment } | null
}

export type ReviewsProductByIdQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ReviewsProductByIdQuery = {
  __typename?: 'Query'
  manage?: {
    __typename?: 'ManageQuery'
    product: {
      __typename?: 'Product'
      twitter?: string | null
      linkedin?: string | null
      showDisclaimer?: boolean | null
      disclaimerText?: string | null
      disclaimerUrl?: string | null
      freePlanAvailable?: boolean | null
      freeDemo: boolean
      freeTrial: boolean
      trialUnit?: ProductTrialUnitEnum | null
      trialPeriod?: number | null
      noPricingPlans?: boolean | null
      noPricingPlansHint?: string | null
      cloud?: ProductCloudEnum | null
      anytimeSupport?: ProductAnytimeSupportEnum | null
      onPremise?: ProductOnPremiseEnum | null
      germanSupport?: ProductGermanSupportEnum | null
      germanLocation?: ProductGermanLocationEnum | null
      euLocation?: ProductEuLocationEnum | null
      euStandardContract?: ProductEuStandardContractEnum | null
      euCompanyAffiliated?: ProductEuCompanyAffiliatedEnum | null
      contactFormRecipients?: Array<string> | null
      pageVisitReportEmails?: Array<string> | null
      vendorEmailAddresses?: Array<string> | null
      hero?: ({ __typename?: 'Asset' } & AssetFieldsFragment) | null
      documents: Array<{
        __typename?: 'Asset'
        id?: string | null
        name: string
        filename: string
        size: number
        url: string
        previewUrl?: string | null
        previewFilename?: string | null
        contentType: string
        position?: number | null
        nameTranslations: Array<{ __typename?: 'Translation'; locale: string; value?: string | null }>
      }>
      config: { __typename?: 'ProductConfig' } & ProductConfigFieldsFragment
      screenshots: Array<{ __typename?: 'Asset'; id?: string | null; name: string; size: number; url: string; contentType: string }>
      mainCategory: { __typename?: 'Category'; id: string; slug: string; title: string }
      categories: Array<{ __typename?: 'Category'; id: string; slug: string; title: string; hidden: boolean; published: boolean }>
      descriptionTranslations: Array<{ __typename?: 'Translation'; locale: string; value?: string | null }>
      shortDescriptionTranslations: Array<{ __typename?: 'Translation'; locale: string; value?: string | null }>
      urlTranslations: Array<{ __typename?: 'Translation'; locale: string; value?: string | null }>
      linkTextTranslations: Array<{ __typename?: 'Translation'; locale: string; value?: string | null }>
      disclaimerTextTranslations: Array<{ __typename?: 'Translation'; locale: string; value?: string | null }>
      disclaimerUrlTranslations: Array<{ __typename?: 'Translation'; locale: string; value?: string | null }>
      noPricingPlansHintTranslations: Array<{ __typename?: 'Translation'; locale: string; value?: string | null }>
      pricingPlans: Array<{ __typename?: 'PricingPlan' } & ProductPricingPlanFragment>
      ctaSecondaryLabelTranslations: Array<{ __typename?: 'Translation'; locale: string; value?: string | null }>
      ctaSecondaryUrlTranslations: Array<{ __typename?: 'Translation'; locale: string; value?: string | null }>
      ctaSecondary?: { __typename?: 'Link'; url: string; label?: string | null } | null
    } & ProductBasicDataFragment
  } | null
}

export type ReviewsProductOptionsQueryVariables = Exact<{ [key: string]: never }>

export type ReviewsProductOptionsQuery = {
  __typename?: 'Query'
  manage?: {
    __typename?: 'ManageQuery'
    options: {
      __typename?: 'ManageOptions'
      pricingTextOptions: Array<{
        __typename?: 'PricingTextOption'
        id: string
        name: string
        free: boolean
        nameTranslations: Array<{ __typename?: 'Translation' } & TranslationFieldsFragment>
      }>
      pricingSubscriptionModes: Array<{
        __typename?: 'PricingSubscriptionMode'
        id: string
        name: string
        inDays?: number | null
        nameTranslations: Array<{ __typename?: 'Translation' } & TranslationFieldsFragment>
      }>
      currencies: Array<{ __typename?: 'Currency'; name: string; code: string; symbol: string }>
    }
  } | null
}

export type ReviewsProductSurveyResponseDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ReviewsProductSurveyResponseDetailsQuery = {
  __typename?: 'Query'
  manage?: {
    __typename?: 'ManageQuery'
    surveyResponse: {
      __typename?: 'ManageSurveyResponse'
      aasmState: string
      anonymous: boolean
      createdAt: string
      firstPublishedAt?: string | null
      id: string
      publishedAt?: string | null
      rejectedAt?: string | null
      rejectedReason?: SurveyResponseRejectedReasons | null
      reminderDisabled: boolean
      reviewerCompany?: string | null
      reviewerCompanySize?: string | null
      reviewerIndustry?: string | null
      reviewerFirstName?: string | null
      reviewerPosition?: string | null
      updatedAt: string
      incentive?: { __typename?: 'Incentive'; slug: string } | null
      reminders: Array<{
        __typename?: 'ManageSurveyResponseReminder'
        id: string
        sentAt?: string | null
        createdAt: string
        message?: string | null
        status: SurveyResponseReminderStatuses
        userId?: number | null
        automatic: boolean
      }>
      review?: {
        __typename?: 'Review'
        id: string
        positive?: string | null
        negative?: string | null
        problems?: string | null
        anonymous: boolean
        companySize?: string | null
        companyName?: string | null
        companyField?: string | null
        companyPosition: string
        title: string
        recommendationScore: number
        reviewerFirstName?: string | null
        reviewerLastName?: string | null
        incentive: boolean
        publishedAt?: string | null
        reviewReply?: {
          __typename?: 'ReviewReply'
          id?: string | null
          personalized?: boolean | null
          reply: string
          senderName?: string | null
        } | null
        product: { __typename?: 'Product'; title: string }
        video?: { __typename?: 'Asset'; contentType: string; name: string; url: string } | null
      } | null
    }
  } | null
}

export type ReviewsProductSurveyResponseStatusQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ReviewsProductSurveyResponseStatusQuery = {
  __typename?: 'Query'
  manage?: {
    __typename?: 'ManageQuery'
    surveyResponse: {
      __typename?: 'ManageSurveyResponse'
      aasmState: string
      id: string
      reviewerFirstName?: string | null
      rejectedReason?: SurveyResponseRejectedReasons | null
    }
  } | null
}

export type ReviewsProductSurveyResponsesQueryVariables = Exact<{
  slug: Scalars['ID']['input']
  page: Scalars['Int']['input']
  perPage: Scalars['Int']['input']
  sortBy?: InputMaybe<SurveyResponsesSortByEnum>
  sortDirection?: InputMaybe<SortDirectionEnum>
  filters: ManageSurveyResponsesSearchFilters
}>

export type ReviewsProductSurveyResponsesQuery = {
  __typename?: 'Query'
  manage?: {
    __typename?: 'ManageQuery'
    surveyResponses?: {
      __typename?: 'ManageExploreSurveyResponses'
      results: Array<{
        __typename?: 'ManageSurveyResponse'
        id: string
        aasmState: string
        reviewerFirstName?: string | null
        reviewerCompany?: string | null
        reviewerPosition?: string | null
        rejectedReason?: SurveyResponseRejectedReasons | null
        reminderDisabled: boolean
        updatedAt: string
        reminders: Array<{ __typename?: 'ManageSurveyResponseReminder'; id: string }>
        review?: {
          __typename?: 'Review'
          recommendationScore: number
          companyName?: string | null
          companyPosition: string
          reviewReply?: { __typename?: 'ReviewReply'; id?: string | null } | null
        } | null
      }>
      pagination: {
        __typename?: 'Pagination'
        perPage: number
        currentPage: number
        totalCount: number
        hasNextPage: boolean
        totalPages: number
      }
    } | null
  } | null
}

export type ReviewsProductSurveyResponsesCountQueryVariables = Exact<{
  slug: Scalars['ID']['input']
}>

export type ReviewsProductSurveyResponsesCountQuery = {
  __typename?: 'Query'
  manage?: {
    __typename?: 'ManageQuery'
    surveyResponses?: { __typename?: 'ManageExploreSurveyResponses'; pagination: { __typename?: 'Pagination'; totalCount: number } } | null
  } | null
}

export type ReviewsProductsQueryVariables = Exact<{ [key: string]: never }>

export type ReviewsProductsQuery = {
  __typename?: 'Query'
  manage?: {
    __typename?: 'ManageQuery'
    products?: Array<
      {
        __typename?: 'Product'
        config: { __typename?: 'ProductConfig'; profileClaimedCached: ProductConfigProfileClaimedCachedEnum }
      } & ProductBasicDataFragment
    > | null
  } | null
}

export const AgencyTraitFieldsFragmentDoc = gql`
  fragment agencyTraitFields on AgencyTrait {
    kind
    description
    title
    position
  }
`
export const AssetFieldsFragmentDoc = gql`
  fragment assetFields on Asset {
    name
    size
    url
    contentType
  }
`
export const AgencyBasicDataFragmentDoc = gql`
  fragment agencyBasicData on Agency {
    id
    title
    slug
    shortDescription
    locations {
      city
      country
    }
    remote
    traits {
      ...agencyTraitFields
    }
    logo {
      ...assetFields
    }
  }
  ${AgencyTraitFieldsFragmentDoc}
  ${AssetFieldsFragmentDoc}
`
export const ProductBadgeFieldsFragmentDoc = gql`
  fragment productBadgeFields on ProductBadge {
    id
    timeframe
    badgeType
    badgeName
    url
    category {
      displayOrTitle
    }
  }
`
export const ProductBasicDataFragmentDoc = gql`
  fragment productBasicData on Product {
    id
    slug
    title
    isSuite
    averageRecommendationRating
    reviewCount
    productBadges {
      ...productBadgeFields
    }
    logo {
      ...assetFields
    }
  }
  ${ProductBadgeFieldsFragmentDoc}
  ${AssetFieldsFragmentDoc}
`
export const ProductConfigFieldsFragmentDoc = gql`
  fragment ProductConfigFields on ProductConfig {
    ctaCustomQuoteAdminCalculated
    ctaCustomQuoteCached
    ctaCustomQuoteVendor
    ctaCustomQuoteVendorPossibleOptions
    ctaPrimaryAdminCalculated
    ctaPrimaryCached
    ctaPrimaryVendor
    ctaPrimaryVendorPossibleOptions
    ctaSecondaryAdminCalculated
    ctaSecondaryCached
    ctaSecondaryVendor
    ctaSecondaryVendorPossibleOptions
    heroImageAdminCalculated
    heroImageCached
    heroImageVendor
    heroImageVendorPossibleOptions
    profileClaimedAdminCalculated
    profileClaimedCached
    profileClaimedVendor
    profileClaimedVendorPossibleOptions
    reviewRepliesAllowedAdminCalculated
    reviewRepliesAllowedCached
    reviewRepliesAllowedVendor
    reviewRepliesAllowedVendorPossibleOptions
  }
`
export const ProductPricingPlanFragmentDoc = gql`
  fragment ProductPricingPlan on PricingPlan {
    billingPeriod
    description
    name
    descriptionTranslations {
      locale
      value
    }
    nameTranslations {
      locale
      value
    }
    id
    position
    perksTranslations {
      locale
      value
    }
    priceCents
    priceCurrency
    priceCurrencySymbol
    priceTextValue
    pricingSubscriptionModeId
    pricingTextOptionId
    startingPrice
  }
`
export const TranslationFieldsFragmentDoc = gql`
  fragment translationFields on Translation {
    locale
    value
  }
`
export const CreateReviewsProductDocumentDocument = gql`
  mutation createReviewsProductDocument($productId: ID!, $documentAttributes: AssetAttributes!) {
    manage {
      createDocument(input: { productId: $productId, documentAttributes: $documentAttributes }) {
        id
      }
    }
  }
`

/**
 * __useCreateReviewsProductDocumentMutation__
 *
 * To run a mutation, you first call `useCreateReviewsProductDocumentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateReviewsProductDocumentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateReviewsProductDocumentMutation({
 *   variables: {
 *     productId: // value for 'productId'
 *     documentAttributes: // value for 'documentAttributes'
 *   },
 * });
 */
export function useCreateReviewsProductDocumentMutation(
  options:
    | VueApolloComposable.UseMutationOptions<CreateReviewsProductDocumentMutation, CreateReviewsProductDocumentMutationVariables>
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<CreateReviewsProductDocumentMutation, CreateReviewsProductDocumentMutationVariables>
      > = {},
) {
  return VueApolloComposable.useMutation<CreateReviewsProductDocumentMutation, CreateReviewsProductDocumentMutationVariables>(
    CreateReviewsProductDocumentDocument,
    options,
  )
}
export type CreateReviewsProductDocumentMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  CreateReviewsProductDocumentMutation,
  CreateReviewsProductDocumentMutationVariables
>
export const CreateReviewsProductScreenshotDocument = gql`
  mutation createReviewsProductScreenshot($productId: ID!, $screenshotAttributes: AssetAttributes!) {
    manage {
      createScreenshot(input: { productId: $productId, screenshotAttributes: $screenshotAttributes }) {
        clientMutationId
      }
    }
  }
`

/**
 * __useCreateReviewsProductScreenshotMutation__
 *
 * To run a mutation, you first call `useCreateReviewsProductScreenshotMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateReviewsProductScreenshotMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateReviewsProductScreenshotMutation({
 *   variables: {
 *     productId: // value for 'productId'
 *     screenshotAttributes: // value for 'screenshotAttributes'
 *   },
 * });
 */
export function useCreateReviewsProductScreenshotMutation(
  options:
    | VueApolloComposable.UseMutationOptions<CreateReviewsProductScreenshotMutation, CreateReviewsProductScreenshotMutationVariables>
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<CreateReviewsProductScreenshotMutation, CreateReviewsProductScreenshotMutationVariables>
      > = {},
) {
  return VueApolloComposable.useMutation<CreateReviewsProductScreenshotMutation, CreateReviewsProductScreenshotMutationVariables>(
    CreateReviewsProductScreenshotDocument,
    options,
  )
}
export type CreateReviewsProductScreenshotMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  CreateReviewsProductScreenshotMutation,
  CreateReviewsProductScreenshotMutationVariables
>
export const CreateSurveyResponseReminderDocument = gql`
  mutation createSurveyResponseReminder($input: CreateSurveyResponseReminderInput!) {
    manage {
      createSurveyResponseReminder(input: $input) {
        id
      }
    }
  }
`

/**
 * __useCreateSurveyResponseReminderMutation__
 *
 * To run a mutation, you first call `useCreateSurveyResponseReminderMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateSurveyResponseReminderMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateSurveyResponseReminderMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateSurveyResponseReminderMutation(
  options:
    | VueApolloComposable.UseMutationOptions<CreateSurveyResponseReminderMutation, CreateSurveyResponseReminderMutationVariables>
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<CreateSurveyResponseReminderMutation, CreateSurveyResponseReminderMutationVariables>
      > = {},
) {
  return VueApolloComposable.useMutation<CreateSurveyResponseReminderMutation, CreateSurveyResponseReminderMutationVariables>(
    CreateSurveyResponseReminderDocument,
    options,
  )
}
export type CreateSurveyResponseReminderMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  CreateSurveyResponseReminderMutation,
  CreateSurveyResponseReminderMutationVariables
>
export const DeleteReviewsProductDocumentsDocument = gql`
  mutation deleteReviewsProductDocuments($productId: ID!, $ids: [ID!]!) {
    manage {
      deleteDocuments(input: { productId: $productId, ids: $ids }) {
        id
      }
    }
  }
`

/**
 * __useDeleteReviewsProductDocumentsMutation__
 *
 * To run a mutation, you first call `useDeleteReviewsProductDocumentsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReviewsProductDocumentsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteReviewsProductDocumentsMutation({
 *   variables: {
 *     productId: // value for 'productId'
 *     ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteReviewsProductDocumentsMutation(
  options:
    | VueApolloComposable.UseMutationOptions<DeleteReviewsProductDocumentsMutation, DeleteReviewsProductDocumentsMutationVariables>
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<DeleteReviewsProductDocumentsMutation, DeleteReviewsProductDocumentsMutationVariables>
      > = {},
) {
  return VueApolloComposable.useMutation<DeleteReviewsProductDocumentsMutation, DeleteReviewsProductDocumentsMutationVariables>(
    DeleteReviewsProductDocumentsDocument,
    options,
  )
}
export type DeleteReviewsProductDocumentsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  DeleteReviewsProductDocumentsMutation,
  DeleteReviewsProductDocumentsMutationVariables
>
export const DeleteReviewsProductReviewReplyDocument = gql`
  mutation deleteReviewsProductReviewReply($id: ID!) {
    manage {
      deleteReviewReply(input: { id: $id }) {
        id
      }
    }
  }
`

/**
 * __useDeleteReviewsProductReviewReplyMutation__
 *
 * To run a mutation, you first call `useDeleteReviewsProductReviewReplyMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReviewsProductReviewReplyMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteReviewsProductReviewReplyMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteReviewsProductReviewReplyMutation(
  options:
    | VueApolloComposable.UseMutationOptions<DeleteReviewsProductReviewReplyMutation, DeleteReviewsProductReviewReplyMutationVariables>
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<DeleteReviewsProductReviewReplyMutation, DeleteReviewsProductReviewReplyMutationVariables>
      > = {},
) {
  return VueApolloComposable.useMutation<DeleteReviewsProductReviewReplyMutation, DeleteReviewsProductReviewReplyMutationVariables>(
    DeleteReviewsProductReviewReplyDocument,
    options,
  )
}
export type DeleteReviewsProductReviewReplyMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  DeleteReviewsProductReviewReplyMutation,
  DeleteReviewsProductReviewReplyMutationVariables
>
export const DeleteReviewsProductScreenshotsDocument = gql`
  mutation deleteReviewsProductScreenshots($productId: ID!, $ids: [ID!]!) {
    manage {
      deleteScreenshots(input: { productId: $productId, ids: $ids }) {
        clientMutationId
      }
    }
  }
`

/**
 * __useDeleteReviewsProductScreenshotsMutation__
 *
 * To run a mutation, you first call `useDeleteReviewsProductScreenshotsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReviewsProductScreenshotsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteReviewsProductScreenshotsMutation({
 *   variables: {
 *     productId: // value for 'productId'
 *     ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteReviewsProductScreenshotsMutation(
  options:
    | VueApolloComposable.UseMutationOptions<DeleteReviewsProductScreenshotsMutation, DeleteReviewsProductScreenshotsMutationVariables>
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<DeleteReviewsProductScreenshotsMutation, DeleteReviewsProductScreenshotsMutationVariables>
      > = {},
) {
  return VueApolloComposable.useMutation<DeleteReviewsProductScreenshotsMutation, DeleteReviewsProductScreenshotsMutationVariables>(
    DeleteReviewsProductScreenshotsDocument,
    options,
  )
}
export type DeleteReviewsProductScreenshotsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  DeleteReviewsProductScreenshotsMutation,
  DeleteReviewsProductScreenshotsMutationVariables
>
export const UpdateReviewsProductDocument = gql`
  mutation updateReviewsProduct($id: ID!, $attributes: ProductAttributes!) {
    manage {
      updateProduct(input: { id: $id, attributes: $attributes }) {
        clientMutationId
      }
    }
  }
`

/**
 * __useUpdateReviewsProductMutation__
 *
 * To run a mutation, you first call `useUpdateReviewsProductMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReviewsProductMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateReviewsProductMutation({
 *   variables: {
 *     id: // value for 'id'
 *     attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateReviewsProductMutation(
  options:
    | VueApolloComposable.UseMutationOptions<UpdateReviewsProductMutation, UpdateReviewsProductMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateReviewsProductMutation, UpdateReviewsProductMutationVariables>> = {},
) {
  return VueApolloComposable.useMutation<UpdateReviewsProductMutation, UpdateReviewsProductMutationVariables>(
    UpdateReviewsProductDocument,
    options,
  )
}
export type UpdateReviewsProductMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  UpdateReviewsProductMutation,
  UpdateReviewsProductMutationVariables
>
export const UpdateReviewsProductDocumentsDocument = gql`
  mutation updateReviewsProductDocuments($productId: ID!, $documentAttributes: [AssetAttributes!]!) {
    manage {
      updateDocuments(input: { productId: $productId, documentAttributes: $documentAttributes }) {
        id
      }
    }
  }
`

/**
 * __useUpdateReviewsProductDocumentsMutation__
 *
 * To run a mutation, you first call `useUpdateReviewsProductDocumentsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReviewsProductDocumentsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateReviewsProductDocumentsMutation({
 *   variables: {
 *     productId: // value for 'productId'
 *     documentAttributes: // value for 'documentAttributes'
 *   },
 * });
 */
export function useUpdateReviewsProductDocumentsMutation(
  options:
    | VueApolloComposable.UseMutationOptions<UpdateReviewsProductDocumentsMutation, UpdateReviewsProductDocumentsMutationVariables>
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<UpdateReviewsProductDocumentsMutation, UpdateReviewsProductDocumentsMutationVariables>
      > = {},
) {
  return VueApolloComposable.useMutation<UpdateReviewsProductDocumentsMutation, UpdateReviewsProductDocumentsMutationVariables>(
    UpdateReviewsProductDocumentsDocument,
    options,
  )
}
export type UpdateReviewsProductDocumentsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  UpdateReviewsProductDocumentsMutation,
  UpdateReviewsProductDocumentsMutationVariables
>
export const UpdateReviewsProductScreenshotsDocument = gql`
  mutation updateReviewsProductScreenshots($productId: ID!, $screenshotAttributes: [AssetAttributes!]!) {
    manage {
      updateScreenshots(input: { productId: $productId, screenshotAttributes: $screenshotAttributes }) {
        clientMutationId
      }
    }
  }
`

/**
 * __useUpdateReviewsProductScreenshotsMutation__
 *
 * To run a mutation, you first call `useUpdateReviewsProductScreenshotsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReviewsProductScreenshotsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateReviewsProductScreenshotsMutation({
 *   variables: {
 *     productId: // value for 'productId'
 *     screenshotAttributes: // value for 'screenshotAttributes'
 *   },
 * });
 */
export function useUpdateReviewsProductScreenshotsMutation(
  options:
    | VueApolloComposable.UseMutationOptions<UpdateReviewsProductScreenshotsMutation, UpdateReviewsProductScreenshotsMutationVariables>
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<UpdateReviewsProductScreenshotsMutation, UpdateReviewsProductScreenshotsMutationVariables>
      > = {},
) {
  return VueApolloComposable.useMutation<UpdateReviewsProductScreenshotsMutation, UpdateReviewsProductScreenshotsMutationVariables>(
    UpdateReviewsProductScreenshotsDocument,
    options,
  )
}
export type UpdateReviewsProductScreenshotsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  UpdateReviewsProductScreenshotsMutation,
  UpdateReviewsProductScreenshotsMutationVariables
>
export const UpdateReviewsProductWithPricingPlansDocument = gql`
  mutation updateReviewsProductWithPricingPlans($id: ID!, $attributes: ProductAttributes!, $pricing: ChangePricingPlansInput!) {
    manage {
      updateProduct(input: { id: $id, attributes: $attributes }) {
        clientMutationId
      }
      changePricingPlans(input: $pricing) {
        name
      }
    }
  }
`

/**
 * __useUpdateReviewsProductWithPricingPlansMutation__
 *
 * To run a mutation, you first call `useUpdateReviewsProductWithPricingPlansMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReviewsProductWithPricingPlansMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateReviewsProductWithPricingPlansMutation({
 *   variables: {
 *     id: // value for 'id'
 *     attributes: // value for 'attributes'
 *     pricing: // value for 'pricing'
 *   },
 * });
 */
export function useUpdateReviewsProductWithPricingPlansMutation(
  options:
    | VueApolloComposable.UseMutationOptions<
        UpdateReviewsProductWithPricingPlansMutation,
        UpdateReviewsProductWithPricingPlansMutationVariables
      >
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<
          UpdateReviewsProductWithPricingPlansMutation,
          UpdateReviewsProductWithPricingPlansMutationVariables
        >
      > = {},
) {
  return VueApolloComposable.useMutation<
    UpdateReviewsProductWithPricingPlansMutation,
    UpdateReviewsProductWithPricingPlansMutationVariables
  >(UpdateReviewsProductWithPricingPlansDocument, options)
}
export type UpdateReviewsProductWithPricingPlansMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  UpdateReviewsProductWithPricingPlansMutation,
  UpdateReviewsProductWithPricingPlansMutationVariables
>
export const UpsertReviewsProductReviewReplyDocument = gql`
  mutation upsertReviewsProductReviewReply($notifyReviewer: Boolean, $personalized: Boolean, $reply: String!, $reviewId: ID!) {
    manage {
      upsertReviewReply(input: { notifyReviewer: $notifyReviewer, personalized: $personalized, reply: $reply, reviewId: $reviewId }) {
        id
      }
    }
  }
`

/**
 * __useUpsertReviewsProductReviewReplyMutation__
 *
 * To run a mutation, you first call `useUpsertReviewsProductReviewReplyMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpsertReviewsProductReviewReplyMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpsertReviewsProductReviewReplyMutation({
 *   variables: {
 *     notifyReviewer: // value for 'notifyReviewer'
 *     personalized: // value for 'personalized'
 *     reply: // value for 'reply'
 *     reviewId: // value for 'reviewId'
 *   },
 * });
 */
export function useUpsertReviewsProductReviewReplyMutation(
  options:
    | VueApolloComposable.UseMutationOptions<UpsertReviewsProductReviewReplyMutation, UpsertReviewsProductReviewReplyMutationVariables>
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<UpsertReviewsProductReviewReplyMutation, UpsertReviewsProductReviewReplyMutationVariables>
      > = {},
) {
  return VueApolloComposable.useMutation<UpsertReviewsProductReviewReplyMutation, UpsertReviewsProductReviewReplyMutationVariables>(
    UpsertReviewsProductReviewReplyDocument,
    options,
  )
}
export type UpsertReviewsProductReviewReplyMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  UpsertReviewsProductReviewReplyMutation,
  UpsertReviewsProductReviewReplyMutationVariables
>
export const ReviewsAgenciesDocument = gql`
  query reviewsAgencies {
    manage {
      agencies {
        ...agencyBasicData
      }
    }
  }
  ${AgencyBasicDataFragmentDoc}
`

/**
 * __useReviewsAgenciesQuery__
 *
 * To run a query within a Vue component, call `useReviewsAgenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewsAgenciesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useReviewsAgenciesQuery();
 */
export function useReviewsAgenciesQuery(
  options:
    | VueApolloComposable.UseQueryOptions<ReviewsAgenciesQuery, ReviewsAgenciesQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ReviewsAgenciesQuery, ReviewsAgenciesQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<ReviewsAgenciesQuery, ReviewsAgenciesQueryVariables>> = {},
) {
  return VueApolloComposable.useQuery<ReviewsAgenciesQuery, ReviewsAgenciesQueryVariables>(ReviewsAgenciesDocument, {}, options)
}
export function useReviewsAgenciesLazyQuery(
  options:
    | VueApolloComposable.UseQueryOptions<ReviewsAgenciesQuery, ReviewsAgenciesQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ReviewsAgenciesQuery, ReviewsAgenciesQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<ReviewsAgenciesQuery, ReviewsAgenciesQueryVariables>> = {},
) {
  return VueApolloComposable.useLazyQuery<ReviewsAgenciesQuery, ReviewsAgenciesQueryVariables>(ReviewsAgenciesDocument, {}, options)
}
export type ReviewsAgenciesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  ReviewsAgenciesQuery,
  ReviewsAgenciesQueryVariables
>
export const ReviewsAgencyByIdDocument = gql`
  query reviewsAgencyById($id: ID!) {
    manage {
      agency(id: $id) {
        ...agencyBasicData
      }
    }
  }
  ${AgencyBasicDataFragmentDoc}
`

/**
 * __useReviewsAgencyByIdQuery__
 *
 * To run a query within a Vue component, call `useReviewsAgencyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewsAgencyByIdQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useReviewsAgencyByIdQuery({
 *   id: // value for 'id'
 * });
 */
export function useReviewsAgencyByIdQuery(
  variables:
    | ReviewsAgencyByIdQueryVariables
    | VueCompositionApi.Ref<ReviewsAgencyByIdQueryVariables>
    | ReactiveFunction<ReviewsAgencyByIdQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<ReviewsAgencyByIdQuery, ReviewsAgencyByIdQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ReviewsAgencyByIdQuery, ReviewsAgencyByIdQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<ReviewsAgencyByIdQuery, ReviewsAgencyByIdQueryVariables>> = {},
) {
  return VueApolloComposable.useQuery<ReviewsAgencyByIdQuery, ReviewsAgencyByIdQueryVariables>(
    ReviewsAgencyByIdDocument,
    variables,
    options,
  )
}
export function useReviewsAgencyByIdLazyQuery(
  variables?:
    | ReviewsAgencyByIdQueryVariables
    | VueCompositionApi.Ref<ReviewsAgencyByIdQueryVariables>
    | ReactiveFunction<ReviewsAgencyByIdQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<ReviewsAgencyByIdQuery, ReviewsAgencyByIdQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ReviewsAgencyByIdQuery, ReviewsAgencyByIdQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<ReviewsAgencyByIdQuery, ReviewsAgencyByIdQueryVariables>> = {},
) {
  return VueApolloComposable.useLazyQuery<ReviewsAgencyByIdQuery, ReviewsAgencyByIdQueryVariables>(
    ReviewsAgencyByIdDocument,
    variables,
    options,
  )
}
export type ReviewsAgencyByIdQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  ReviewsAgencyByIdQuery,
  ReviewsAgencyByIdQueryVariables
>
export const ReviewsProductByIdDocument = gql`
  query reviewsProductById($id: ID!) {
    manage {
      product(id: $id) {
        ...productBasicData
        hero {
          ...assetFields
        }
        documents {
          id
          name
          filename
          nameTranslations {
            locale
            value
          }
          size
          url
          previewUrl(width: 300, height: 400)
          previewFilename
          contentType
          position
        }
        config {
          ...ProductConfigFields
        }
        screenshots {
          id
          name
          size
          url
          contentType
        }
        mainCategory {
          id
          slug
          title
        }
        categories {
          id
          slug
          title
          hidden
          published
        }
        descriptionTranslations {
          locale
          value
        }
        shortDescriptionTranslations {
          locale
          value
        }
        twitter
        linkedin
        urlTranslations {
          locale
          value
        }
        linkTextTranslations {
          locale
          value
        }
        showDisclaimer
        disclaimerText
        disclaimerUrl
        disclaimerTextTranslations {
          locale
          value
        }
        disclaimerUrlTranslations {
          locale
          value
        }
        freePlanAvailable
        freeDemo
        freeTrial
        trialUnit
        trialPeriod
        noPricingPlans
        noPricingPlansHint
        noPricingPlansHintTranslations {
          locale
          value
        }
        pricingPlans {
          ...ProductPricingPlan
        }
        cloud
        anytimeSupport
        onPremise
        germanSupport
        germanLocation
        euLocation
        euStandardContract
        euCompanyAffiliated
        ctaSecondaryLabelTranslations {
          locale
          value
        }
        ctaSecondaryUrlTranslations {
          locale
          value
        }
        ctaSecondary {
          url
          label
        }
        contactFormRecipients
        pageVisitReportEmails
        vendorEmailAddresses
      }
    }
  }
  ${ProductBasicDataFragmentDoc}
  ${AssetFieldsFragmentDoc}
  ${ProductConfigFieldsFragmentDoc}
  ${ProductPricingPlanFragmentDoc}
`

/**
 * __useReviewsProductByIdQuery__
 *
 * To run a query within a Vue component, call `useReviewsProductByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewsProductByIdQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useReviewsProductByIdQuery({
 *   id: // value for 'id'
 * });
 */
export function useReviewsProductByIdQuery(
  variables:
    | ReviewsProductByIdQueryVariables
    | VueCompositionApi.Ref<ReviewsProductByIdQueryVariables>
    | ReactiveFunction<ReviewsProductByIdQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<ReviewsProductByIdQuery, ReviewsProductByIdQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ReviewsProductByIdQuery, ReviewsProductByIdQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<ReviewsProductByIdQuery, ReviewsProductByIdQueryVariables>> = {},
) {
  return VueApolloComposable.useQuery<ReviewsProductByIdQuery, ReviewsProductByIdQueryVariables>(
    ReviewsProductByIdDocument,
    variables,
    options,
  )
}
export function useReviewsProductByIdLazyQuery(
  variables?:
    | ReviewsProductByIdQueryVariables
    | VueCompositionApi.Ref<ReviewsProductByIdQueryVariables>
    | ReactiveFunction<ReviewsProductByIdQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<ReviewsProductByIdQuery, ReviewsProductByIdQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ReviewsProductByIdQuery, ReviewsProductByIdQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<ReviewsProductByIdQuery, ReviewsProductByIdQueryVariables>> = {},
) {
  return VueApolloComposable.useLazyQuery<ReviewsProductByIdQuery, ReviewsProductByIdQueryVariables>(
    ReviewsProductByIdDocument,
    variables,
    options,
  )
}
export type ReviewsProductByIdQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  ReviewsProductByIdQuery,
  ReviewsProductByIdQueryVariables
>
export const ReviewsProductOptionsDocument = gql`
  query reviewsProductOptions {
    manage {
      options {
        pricingTextOptions {
          id
          name
          nameTranslations {
            ...translationFields
          }
          free
        }
        pricingSubscriptionModes {
          id
          name
          nameTranslations {
            ...translationFields
          }
          inDays
        }
        currencies {
          name
          code
          symbol
        }
      }
    }
  }
  ${TranslationFieldsFragmentDoc}
`

/**
 * __useReviewsProductOptionsQuery__
 *
 * To run a query within a Vue component, call `useReviewsProductOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewsProductOptionsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useReviewsProductOptionsQuery();
 */
export function useReviewsProductOptionsQuery(
  options:
    | VueApolloComposable.UseQueryOptions<ReviewsProductOptionsQuery, ReviewsProductOptionsQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ReviewsProductOptionsQuery, ReviewsProductOptionsQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<ReviewsProductOptionsQuery, ReviewsProductOptionsQueryVariables>> = {},
) {
  return VueApolloComposable.useQuery<ReviewsProductOptionsQuery, ReviewsProductOptionsQueryVariables>(
    ReviewsProductOptionsDocument,
    {},
    options,
  )
}
export function useReviewsProductOptionsLazyQuery(
  options:
    | VueApolloComposable.UseQueryOptions<ReviewsProductOptionsQuery, ReviewsProductOptionsQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ReviewsProductOptionsQuery, ReviewsProductOptionsQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<ReviewsProductOptionsQuery, ReviewsProductOptionsQueryVariables>> = {},
) {
  return VueApolloComposable.useLazyQuery<ReviewsProductOptionsQuery, ReviewsProductOptionsQueryVariables>(
    ReviewsProductOptionsDocument,
    {},
    options,
  )
}
export type ReviewsProductOptionsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  ReviewsProductOptionsQuery,
  ReviewsProductOptionsQueryVariables
>
export const ReviewsProductSurveyResponseDetailsDocument = gql`
  query reviewsProductSurveyResponseDetails($id: ID!) {
    manage {
      surveyResponse(id: $id) {
        aasmState
        anonymous
        createdAt
        firstPublishedAt
        id
        incentive {
          slug
        }
        publishedAt
        rejectedAt
        rejectedReason
        reminderDisabled
        reviewerCompany
        reviewerCompanySize
        reviewerIndustry
        reviewerFirstName
        reviewerPosition
        updatedAt
        reminders {
          id
          sentAt
          createdAt
          message
          status
          userId
          automatic
        }
        review {
          id
          positive
          negative
          problems
          reviewReply {
            id
            personalized
            reply
            senderName
          }
          anonymous
          companySize
          companyName
          companyField
          companyPosition
          title
          recommendationScore
          reviewerFirstName
          reviewerLastName
          incentive
          publishedAt
          product {
            title
          }
          video {
            contentType
            name
            url
          }
        }
      }
    }
  }
`

/**
 * __useReviewsProductSurveyResponseDetailsQuery__
 *
 * To run a query within a Vue component, call `useReviewsProductSurveyResponseDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewsProductSurveyResponseDetailsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useReviewsProductSurveyResponseDetailsQuery({
 *   id: // value for 'id'
 * });
 */
export function useReviewsProductSurveyResponseDetailsQuery(
  variables:
    | ReviewsProductSurveyResponseDetailsQueryVariables
    | VueCompositionApi.Ref<ReviewsProductSurveyResponseDetailsQueryVariables>
    | ReactiveFunction<ReviewsProductSurveyResponseDetailsQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<ReviewsProductSurveyResponseDetailsQuery, ReviewsProductSurveyResponseDetailsQueryVariables>
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<ReviewsProductSurveyResponseDetailsQuery, ReviewsProductSurveyResponseDetailsQueryVariables>
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<ReviewsProductSurveyResponseDetailsQuery, ReviewsProductSurveyResponseDetailsQueryVariables>
      > = {},
) {
  return VueApolloComposable.useQuery<ReviewsProductSurveyResponseDetailsQuery, ReviewsProductSurveyResponseDetailsQueryVariables>(
    ReviewsProductSurveyResponseDetailsDocument,
    variables,
    options,
  )
}
export function useReviewsProductSurveyResponseDetailsLazyQuery(
  variables?:
    | ReviewsProductSurveyResponseDetailsQueryVariables
    | VueCompositionApi.Ref<ReviewsProductSurveyResponseDetailsQueryVariables>
    | ReactiveFunction<ReviewsProductSurveyResponseDetailsQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<ReviewsProductSurveyResponseDetailsQuery, ReviewsProductSurveyResponseDetailsQueryVariables>
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<ReviewsProductSurveyResponseDetailsQuery, ReviewsProductSurveyResponseDetailsQueryVariables>
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<ReviewsProductSurveyResponseDetailsQuery, ReviewsProductSurveyResponseDetailsQueryVariables>
      > = {},
) {
  return VueApolloComposable.useLazyQuery<ReviewsProductSurveyResponseDetailsQuery, ReviewsProductSurveyResponseDetailsQueryVariables>(
    ReviewsProductSurveyResponseDetailsDocument,
    variables,
    options,
  )
}
export type ReviewsProductSurveyResponseDetailsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  ReviewsProductSurveyResponseDetailsQuery,
  ReviewsProductSurveyResponseDetailsQueryVariables
>
export const ReviewsProductSurveyResponseStatusDocument = gql`
  query reviewsProductSurveyResponseStatus($id: ID!) {
    manage {
      surveyResponse(id: $id) {
        aasmState
        id
        reviewerFirstName
        rejectedReason
      }
    }
  }
`

/**
 * __useReviewsProductSurveyResponseStatusQuery__
 *
 * To run a query within a Vue component, call `useReviewsProductSurveyResponseStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewsProductSurveyResponseStatusQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useReviewsProductSurveyResponseStatusQuery({
 *   id: // value for 'id'
 * });
 */
export function useReviewsProductSurveyResponseStatusQuery(
  variables:
    | ReviewsProductSurveyResponseStatusQueryVariables
    | VueCompositionApi.Ref<ReviewsProductSurveyResponseStatusQueryVariables>
    | ReactiveFunction<ReviewsProductSurveyResponseStatusQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<ReviewsProductSurveyResponseStatusQuery, ReviewsProductSurveyResponseStatusQueryVariables>
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<ReviewsProductSurveyResponseStatusQuery, ReviewsProductSurveyResponseStatusQueryVariables>
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<ReviewsProductSurveyResponseStatusQuery, ReviewsProductSurveyResponseStatusQueryVariables>
      > = {},
) {
  return VueApolloComposable.useQuery<ReviewsProductSurveyResponseStatusQuery, ReviewsProductSurveyResponseStatusQueryVariables>(
    ReviewsProductSurveyResponseStatusDocument,
    variables,
    options,
  )
}
export function useReviewsProductSurveyResponseStatusLazyQuery(
  variables?:
    | ReviewsProductSurveyResponseStatusQueryVariables
    | VueCompositionApi.Ref<ReviewsProductSurveyResponseStatusQueryVariables>
    | ReactiveFunction<ReviewsProductSurveyResponseStatusQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<ReviewsProductSurveyResponseStatusQuery, ReviewsProductSurveyResponseStatusQueryVariables>
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<ReviewsProductSurveyResponseStatusQuery, ReviewsProductSurveyResponseStatusQueryVariables>
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<ReviewsProductSurveyResponseStatusQuery, ReviewsProductSurveyResponseStatusQueryVariables>
      > = {},
) {
  return VueApolloComposable.useLazyQuery<ReviewsProductSurveyResponseStatusQuery, ReviewsProductSurveyResponseStatusQueryVariables>(
    ReviewsProductSurveyResponseStatusDocument,
    variables,
    options,
  )
}
export type ReviewsProductSurveyResponseStatusQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  ReviewsProductSurveyResponseStatusQuery,
  ReviewsProductSurveyResponseStatusQueryVariables
>
export const ReviewsProductSurveyResponsesDocument = gql`
  query reviewsProductSurveyResponses(
    $slug: ID!
    $page: Int!
    $perPage: Int!
    $sortBy: SurveyResponsesSortByEnum = updated_at
    $sortDirection: SortDirectionEnum = desc
    $filters: ManageSurveyResponsesSearchFilters!
  ) {
    manage {
      surveyResponses(
        productSlug: $slug
        page: $page
        perPage: $perPage
        sortBy: $sortBy
        sortDirection: $sortDirection
        filters: $filters
      ) {
        results {
          id
          aasmState
          reviewerFirstName
          reviewerCompany
          reviewerPosition
          rejectedReason
          reminderDisabled
          reminders {
            id
          }
          review {
            recommendationScore
            companyName
            companyPosition
            reviewReply {
              id
            }
          }
          updatedAt
        }
        pagination {
          perPage
          currentPage
          totalCount
          hasNextPage
          totalPages
        }
      }
    }
  }
`

/**
 * __useReviewsProductSurveyResponsesQuery__
 *
 * To run a query within a Vue component, call `useReviewsProductSurveyResponsesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewsProductSurveyResponsesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useReviewsProductSurveyResponsesQuery({
 *   slug: // value for 'slug'
 *   page: // value for 'page'
 *   perPage: // value for 'perPage'
 *   sortBy: // value for 'sortBy'
 *   sortDirection: // value for 'sortDirection'
 *   filters: // value for 'filters'
 * });
 */
export function useReviewsProductSurveyResponsesQuery(
  variables:
    | ReviewsProductSurveyResponsesQueryVariables
    | VueCompositionApi.Ref<ReviewsProductSurveyResponsesQueryVariables>
    | ReactiveFunction<ReviewsProductSurveyResponsesQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<ReviewsProductSurveyResponsesQuery, ReviewsProductSurveyResponsesQueryVariables>
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<ReviewsProductSurveyResponsesQuery, ReviewsProductSurveyResponsesQueryVariables>
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<ReviewsProductSurveyResponsesQuery, ReviewsProductSurveyResponsesQueryVariables>
      > = {},
) {
  return VueApolloComposable.useQuery<ReviewsProductSurveyResponsesQuery, ReviewsProductSurveyResponsesQueryVariables>(
    ReviewsProductSurveyResponsesDocument,
    variables,
    options,
  )
}
export function useReviewsProductSurveyResponsesLazyQuery(
  variables?:
    | ReviewsProductSurveyResponsesQueryVariables
    | VueCompositionApi.Ref<ReviewsProductSurveyResponsesQueryVariables>
    | ReactiveFunction<ReviewsProductSurveyResponsesQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<ReviewsProductSurveyResponsesQuery, ReviewsProductSurveyResponsesQueryVariables>
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<ReviewsProductSurveyResponsesQuery, ReviewsProductSurveyResponsesQueryVariables>
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<ReviewsProductSurveyResponsesQuery, ReviewsProductSurveyResponsesQueryVariables>
      > = {},
) {
  return VueApolloComposable.useLazyQuery<ReviewsProductSurveyResponsesQuery, ReviewsProductSurveyResponsesQueryVariables>(
    ReviewsProductSurveyResponsesDocument,
    variables,
    options,
  )
}
export type ReviewsProductSurveyResponsesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  ReviewsProductSurveyResponsesQuery,
  ReviewsProductSurveyResponsesQueryVariables
>
export const ReviewsProductSurveyResponsesCountDocument = gql`
  query reviewsProductSurveyResponsesCount($slug: ID!) {
    manage {
      surveyResponses(productSlug: $slug) {
        pagination {
          totalCount
        }
      }
    }
  }
`

/**
 * __useReviewsProductSurveyResponsesCountQuery__
 *
 * To run a query within a Vue component, call `useReviewsProductSurveyResponsesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewsProductSurveyResponsesCountQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useReviewsProductSurveyResponsesCountQuery({
 *   slug: // value for 'slug'
 * });
 */
export function useReviewsProductSurveyResponsesCountQuery(
  variables:
    | ReviewsProductSurveyResponsesCountQueryVariables
    | VueCompositionApi.Ref<ReviewsProductSurveyResponsesCountQueryVariables>
    | ReactiveFunction<ReviewsProductSurveyResponsesCountQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<ReviewsProductSurveyResponsesCountQuery, ReviewsProductSurveyResponsesCountQueryVariables>
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<ReviewsProductSurveyResponsesCountQuery, ReviewsProductSurveyResponsesCountQueryVariables>
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<ReviewsProductSurveyResponsesCountQuery, ReviewsProductSurveyResponsesCountQueryVariables>
      > = {},
) {
  return VueApolloComposable.useQuery<ReviewsProductSurveyResponsesCountQuery, ReviewsProductSurveyResponsesCountQueryVariables>(
    ReviewsProductSurveyResponsesCountDocument,
    variables,
    options,
  )
}
export function useReviewsProductSurveyResponsesCountLazyQuery(
  variables?:
    | ReviewsProductSurveyResponsesCountQueryVariables
    | VueCompositionApi.Ref<ReviewsProductSurveyResponsesCountQueryVariables>
    | ReactiveFunction<ReviewsProductSurveyResponsesCountQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<ReviewsProductSurveyResponsesCountQuery, ReviewsProductSurveyResponsesCountQueryVariables>
    | VueCompositionApi.Ref<
        VueApolloComposable.UseQueryOptions<ReviewsProductSurveyResponsesCountQuery, ReviewsProductSurveyResponsesCountQueryVariables>
      >
    | ReactiveFunction<
        VueApolloComposable.UseQueryOptions<ReviewsProductSurveyResponsesCountQuery, ReviewsProductSurveyResponsesCountQueryVariables>
      > = {},
) {
  return VueApolloComposable.useLazyQuery<ReviewsProductSurveyResponsesCountQuery, ReviewsProductSurveyResponsesCountQueryVariables>(
    ReviewsProductSurveyResponsesCountDocument,
    variables,
    options,
  )
}
export type ReviewsProductSurveyResponsesCountQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  ReviewsProductSurveyResponsesCountQuery,
  ReviewsProductSurveyResponsesCountQueryVariables
>
export const ReviewsProductsDocument = gql`
  query reviewsProducts {
    manage {
      products {
        ...productBasicData
        config {
          profileClaimedCached
        }
      }
    }
  }
  ${ProductBasicDataFragmentDoc}
`

/**
 * __useReviewsProductsQuery__
 *
 * To run a query within a Vue component, call `useReviewsProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewsProductsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useReviewsProductsQuery();
 */
export function useReviewsProductsQuery(
  options:
    | VueApolloComposable.UseQueryOptions<ReviewsProductsQuery, ReviewsProductsQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ReviewsProductsQuery, ReviewsProductsQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<ReviewsProductsQuery, ReviewsProductsQueryVariables>> = {},
) {
  return VueApolloComposable.useQuery<ReviewsProductsQuery, ReviewsProductsQueryVariables>(ReviewsProductsDocument, {}, options)
}
export function useReviewsProductsLazyQuery(
  options:
    | VueApolloComposable.UseQueryOptions<ReviewsProductsQuery, ReviewsProductsQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ReviewsProductsQuery, ReviewsProductsQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<ReviewsProductsQuery, ReviewsProductsQueryVariables>> = {},
) {
  return VueApolloComposable.useLazyQuery<ReviewsProductsQuery, ReviewsProductsQueryVariables>(ReviewsProductsDocument, {}, options)
}
export type ReviewsProductsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  ReviewsProductsQuery,
  ReviewsProductsQueryVariables
>
