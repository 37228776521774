<template>
  <label class="inline-flex items-center gap-2" @click="handleCheckboxChange">
    <div class="checkbox" :class="[props.size, { checked: checked, partial: useMidState }]" />
    <span v-if="hasLabel" :disabled="disabled" class="label"><slot></slot></span>
  </label>
</template>

<script lang="ts" setup>
import { computed, ref, useSlots, watch } from 'vue'
export type CheckboxSizeEnum = 'small' | 'medium' | 'large'

export interface Props {
  checked?: boolean
  disabled?: boolean
  size?: CheckboxSizeEnum
  useMidState?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  checked: false,
  disabled: false,
  size: 'medium',
  useMidState: false,
})

const checkedValue = ref(props.checked)

watch(
  () => props.checked,
  (newVal) => {
    checkedValue.value = newVal
  },
)

const slots = useSlots()

const hasLabel = computed(() => {
  return !!slots['default']
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
}>()

function handleCheckboxChange() {
  if (props.useMidState) return emit('update:modelValue', true)
  emit('update:modelValue', !props.checked)
}
</script>

<style scoped>
.checkbox {
  /* Prevent checkbox from shrinking */
  @apply shrink-0;
  /* Cursor */
  @apply cursor-pointer;
  /* Border */
  @apply relative rounded-sm border-2 border-inherit;
}

/* Checked state */
.checkbox.checked {
  /* Checkbox mark */
  @apply before:absolute before:top-0 before:z-10 before:block before:rotate-[314deg] before:border-b-2 before:border-l-2 before:border-inherit;
  /* Corner */
  @apply after:absolute after:right-[-2px] after:top-[-5px] after:block after:h-[12px] after:w-[6px] after:bg-white;
}

/* Partial state */
.checkbox.partial {
  @apply before:absolute before:bottom-[5.5px] before:bg-grey-800;
}

/* Partial state sizes */
.checkbox.partial.small {
  /* Border */
  @apply h-[14px] w-[14px];
  /* Checkbox mark */
  @apply before:left-[1.2px] before:top-[4px] before:h-[2px] before:w-[8px];
}

.checkbox.partial.medium {
  /* Border */
  @apply h-[18px] w-[18px];
  /* Checkbox mark */
  @apply before:left-[2px] before:h-[2.5px] before:w-[11px];
}

.checkbox.partial.large {
  /* Border */
  @apply h-[20px] w-[20px];
  /* Checkbox mark */
  @apply before:left-[2px] before:top-[7px] before:h-[2.5px] before:w-[13px];
}

/* Regular sizes */
.checkbox.small {
  /* Border */
  @apply h-[14px] w-[14px];
  /* Checkbox mark */
  @apply before:left-[2px] before:h-[6px] before:w-[10px];
}

.checkbox.medium {
  /* Border */
  @apply h-[18px] w-[18px];
  /* Checkbox mark */
  @apply before:left-[2.5px] before:h-[7.5px] before:w-[12px];
}

.checkbox.large {
  /* Border */
  @apply h-[20px] w-[20px];
  /* Checkbox mark */
  @apply before:left-[3px] before:h-[8px] before:w-[14px];
}

.label {
  /* Disabled state */
  @apply cursor-pointer text-grey-800 peer-checked:text-black peer-disabled:pointer-events-none peer-disabled:cursor-default peer-disabled:opacity-50;
}
</style>
