<template>
  <PageBreadcrumbs :items="breadcrumbs" />
  <ContentCard>
    <template #header>
      <ReviewsAgencyTabs />
    </template>
    <template #subheader>
      <RouterView :agency="agency" name="subheader" />
    </template>
    <RouterView :agency="agency" />
  </ContentCard>
</template>
<script setup lang="ts">
import PageBreadcrumbs from '@/components/PageBreadcrumbs.vue'
import { useReviewsAgencyBreadcrumbs } from '@/composables/breadcrumbs/useReviewsAgencyBreadcrumbs.ts'
import type { AgencyById } from '@/gql/reviews'
import ContentCard from '@/ui/ContentCard.vue'

import ReviewsAgencyTabs from './ReviewsAgencyTabs.vue'

const props = defineProps<{
  agency: AgencyById
}>()

const breadcrumbs = useReviewsAgencyBreadcrumbs(props.agency)
</script>
