<template>
  <ContentCardSubheader :back-to="{ name: ReviewsRouteName.ReviewsProducts }">
    <div class="flex items-end">
      {{ t('Reviews.product.view.reviewsTitle', { productName: product.title }) }}
      <span class="text-body-m ml-4 pr-1">{{ t('Reviews.product.view.reviewsTitleHint', { count }) }} </span>
      <TooltipWrapper>
        <template #trigger>
          <span class="relative bottom-0.5 cursor-pointer text-grey-400"><MdiSvg :path="mdiHelpCircleOutline" size="20" /></span>
        </template>
        <template #content>
          <div class="max-w-xs" v-html="t('Reviews.product.view.reviewsCountHint', { product_title: product.title })" />
        </template>
      </TooltipWrapper>
    </div>
  </ContentCardSubheader>
</template>
<script setup lang="ts">
import { mdiHelpCircleOutline } from '@mdi/js'
import { MdiSvg, TooltipWrapper } from '@ramp106/omrjs-core-ui'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { type ProductData, useReviewsProductSurveyResponsesCountQuery } from '@/gql/reviews'
import { ReviewsRouteName } from '@/router/types.ts'
import ContentCardSubheader from '@/ui/ContentCardSubheader.vue'

const props = defineProps<{
  product: Pick<ProductData, 'id' | 'slug' | 'title'>
}>()

const { t } = useI18n()

const variables = computed(() => ({ slug: props.product.slug }))
const { result } = useReviewsProductSurveyResponsesCountQuery(variables, { clientId: 'reviews' })
const count = computed(() => result.value?.manage?.surveyResponses?.pagination.totalCount)
</script>
