<template>
  <div ref="domRoot">
    <div class="scrollbar-hide min-w-[600px] max-w-[800px] overflow-y-scroll px-6 pb-11">
      <AlertBox v-if="$externalResults.email.length" type="danger" class="error-box mt-5" :icon-path="mdiAlertOctagon">
        {{ props.errorMessage }}
      </AlertBox>
      <p class="text-body-m mb-5 mt-4 font-medium">{{ typed_t('ticketForm.ticketData') }}</p>
      <div>
        <TextInput
          id="email"
          v-model="formData.email"
          type="email"
          :error="getFormError('email', $validations.email.$error)"
          :label="typed_t('ticketForm.email') + '*'"
        />
      </div>
      <div v-if="hasJobRelatedAttributes" class="flex gap-3">
        <SelectInput
          v-model="formData.salutation"
          :error="getFormError('required', $validations.salutation.$error)"
          :options="salutations"
          :label="typed_t('ticketForm.salutation') + '*'"
        >
          <option v-for="option in salutations" :key="`select_${option.value}`" :value="option.value">{{ option.text }}</option>
        </SelectInput>
        <SelectInput
          v-if="quotaConfig.additonalTicketAttributes.includes('title')"
          v-model="formData.title"
          :label="typed_t('ticketForm.title')"
        >
          <option v-for="option in titleOptions" :key="`select_${option.value}`" :value="option.value">{{ option.text }}</option>
        </SelectInput>
      </div>
      <div class="flex items-center gap-3">
        <SelectInput
          v-if="!hasJobRelatedAttributes"
          v-model="formData.salutation"
          :error="getFormError('required', $validations.salutation.$error)"
          class="max-w-[100px]"
          :label="typed_t('ticketForm.salutation') + '*'"
        >
          <option v-for="option in salutations" :key="`select_${option.value}`" :value="option.value">{{ option.text }}</option>
        </SelectInput>
        <TextInput
          id="firstName"
          v-model="formData.firstName"
          :error="getFormError('required', $validations.firstName.$error)"
          :label="typed_t('ticketForm.firstName') + '*'"
        />
        <TextInput
          id="lastName"
          v-model="formData.lastName"
          :error="getFormError('required', $validations.lastName.$error)"
          :label="typed_t('ticketForm.lastName') + '*'"
        />
      </div>
      <div v-if="hasPartnerAttributes" class="flex items-start gap-3">
        <TextInput
          id="partnerName"
          v-model="formData.partnerName"
          :error="getFormError('required', $validations.partnerName.$error)"
          :label="typed_t('ticketForm.partnerName') + '*'"
        />
        <TextInput
          id="partnerRole"
          v-model="formData.partnerRole"
          :error="getFormError('required', $validations.partnerRole.$error)"
          :label="typed_t('ticketForm.partnerRole') + '*'"
        />
      </div>
      <div class="flex gap-3">
        <TextInput
          id="company"
          v-model="formData.companyName"
          :error="getFormError('required', $validations.companyName.$error)"
          :label="typed_t('ticketForm.companyName') + '*'"
        />
        <SelectInput
          v-model="formData.companyType"
          :error="getFormError('required', $validations.companyType.$error)"
          :label="typed_t('ticketForm.companyType') + '*'"
        >
          <option v-for="option in companyTypes" :key="`select_${option.value}`" :value="option.value">{{ option.text }}</option>
        </SelectInput>
        <TextInput
          id="position"
          v-model="formData.position"
          :error="getFormError('required', $validations.position.$error)"
          :label="typed_t('ticketForm.position') + '*'"
        />
      </div>
      <div class="flex gap-3">
        <DateInput
          v-if="quotaConfig.additonalTicketAttributes.includes('birthdate')"
          id="dob"
          v-model="formData.birthdate"
          :error="getFormError('required', $validations.birthdate.$error)"
          :label="typed_t('ticketForm.birthdate') + (isBirthdateRequired ? '*' : '')"
          always-highlighted
        />
        <TextInput
          v-if="quotaConfig.additonalTicketAttributes.includes('phone')"
          id="phone"
          v-model="formData.phone"
          type="tel"
          :error="getFormError('required', $validations.phone.$error)"
          :label="typed_t('ticketForm.phone') + (isPhoneRequired ? '*' : '')"
        />
      </div>

      <div v-if="hasJobRelatedAttributes">
        <p class="text-body-m my-5 font-medium">{{ typed_t('ticketForm.additionalInfo') }}</p>
        <div class="mt-7 flex gap-3">
          <SelectInput
            v-model="formData.jobLevel"
            :error="getFormError('required', $validations.jobLevel.$error)"
            :label="typed_t('ticketForm.jobLevel') + '*'"
          >
            <option v-for="option in jobLevels" :key="`select_${option.value}`" :value="option.value">{{ option.text }}</option>
          </SelectInput>
          <SelectInput
            v-model="formData.companyDepartment"
            :error="getFormError('required', $validations.companyDepartment.$error)"
            :disabled="!isExtraCompanyAttributesRequired"
            :label="typed_t('ticketForm.companyDepartment') + (isExtraCompanyAttributesRequired ? '*' : '')"
          >
            <option v-for="option in companyDepartments" :key="`select_${option.value}`" :value="option.value">{{ option.text }}</option>
          </SelectInput>
        </div>
        <div class="mt-7 flex gap-3">
          <SelectInput
            v-model="formData.companySize"
            :error="getFormError('required', $validations.companySize.$error)"
            :disabled="!isExtraCompanyAttributesRequired"
            :label="typed_t('ticketForm.companySize') + (isExtraCompanyAttributesRequired ? '*' : '')"
          >
            <option v-for="option in companySizes" :key="`select_${option.value}`" :value="option.value">{{ option.text }}</option>
          </SelectInput>
          <SelectInput
            v-model="formData.companyIndustryId"
            :error="getFormError('required', $validations.companyIndustryId.$error)"
            :disabled="!isExtraCompanyAttributesRequired"
            :label="typed_t('ticketForm.companyIndustry') + (isExtraCompanyAttributesRequired ? '*' : '')"
          >
            <option v-for="option in companyIndustries" :key="`select_${option.value}`" :value="option.value">{{ option.text }}</option>
          </SelectInput>
        </div>
        <div class="mt-7 flex gap-3">
          <SelectInput
            v-model="formData.companyFieldId"
            :error="getFormError('required', $validations.companyFieldId.$error)"
            :disabled="!formData.companyIndustryId"
            :label="typed_t('ticketForm.companyField') + (isExtraCompanyAttributesRequired ? '*' : '')"
          >
            <option v-for="option in companyFields" :key="`select_${option.value}`" :value="option.value">{{ option.text }}</option>
          </SelectInput>
          <SelectInput
            v-model="formData.country"
            :error="getFormError('required', $validations.country.$error)"
            :label="typed_t('ticketForm.country') + '*'"
          >
            <option v-for="option in countries" :key="`select_${option.value}`" :value="option.value">{{ option.text }}</option>
          </SelectInput>
        </div>
      </div>
    </div>
    <div class="mt-6 flex justify-end px-6 pb-6">
      <button class="btn-secondary-purple-m uppercase" @click="emit('closeDialog')">
        {{ typed_t('ticketForm.cancel') }}
      </button>
      <button :disabled="props.ticket?.swapped" class="btn-primary-purple-m ml-4" @click="submitForm">
        {{ typed_t('ticketForm.save') }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { mdiAlertOctagon } from '@mdi/js'
import { DateInput, SelectInput, TextInput } from '@ramp106/omrjs-core-ui'
import { useVuelidate } from '@vuelidate/core'
import type { Required } from 'utility-types'
import { computed, nextTick, onMounted, reactive, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import {
  useUserPropertiesPreferencesQuery,
  useCompanyFieldsByIndustryQuery,
  SalutationTypeEnum,
  CompanySizeEnum,
  CompanyTypeEnum,
  CompanyDepartmentEnum,
  type InvitationQuotaQuery,
  type DirectTicketAttributes,
  type TicketQuery,
} from '@/gql/myomr'
import { showNotification } from '@/helpers/notificationHelper'
import typed_translation from '@/helpers/typed_translation'
import { required, requiredIf, email } from '@/services/validators'

import {
  titleOptions,
  jobLevelsMapper,
  companyDepartmentsMapper,
  companySizesMapper,
  companyIndustriesMapper,
  companyFieldsMapper,
  countriesMapper,
  salutationsMapper,
  companyTypesMapper,
} from './formOptions'
import useQuotaConfig, { type InternalQuotaTypeEnum } from './quotaConfig'

type Ticket = NonNullable<TicketQuery['ticket']>

const { t } = useI18n()

const domRoot = ref<HTMLDivElement | null>(null)

const props = defineProps<{
  errorMessage: string
  invitationQuota: InvitationQuotaQuery['invitationQuota']
  ticket?: Ticket
}>()

const quotaConfig = useQuotaConfig(props.invitationQuota.kind as InternalQuotaTypeEnum)
const hasJobRelatedAttributes = computed(() => quotaConfig.value.hasJobRelatedAttributes)
const hasPartnerAttributes = computed(() => quotaConfig.value.hasPartnerAttributes)
const needsCountry = computed(() => quotaConfig.value.needsCountry)
const isPhoneRequired = computed(() => quotaConfig.value.requiredTicketAttributes.includes('phone'))
const isBirthdateRequired = computed(() => quotaConfig.value.requiredTicketAttributes.includes('birthdate'))

type FormData = Omit<
  Required<DirectTicketAttributes>,
  | 'companyDepartment'
  | 'companyFieldId'
  | 'companyIndustryId'
  | 'companySize'
  | 'companyType'
  | 'country'
  | 'fields'
  | 'partnerName'
  | 'partnerRole'
  | 'salutation'
> & {
  companyDepartment: CompanyDepartmentEnum | undefined
  companyFieldId: number | undefined
  companyIndustryId: number | undefined
  companySize: CompanySizeEnum | undefined
  companyType: CompanyTypeEnum | undefined
  country: string | undefined
  salutation: SalutationTypeEnum | undefined
  partnerName: string | undefined
  partnerRole: string | undefined
}

const formData = reactive<FormData>({
  birthdate: undefined,
  companyDepartment: undefined,
  companyFieldId: undefined,
  companyIndustryId: undefined,
  companyName: '',
  companySize: undefined,
  companyType: undefined,
  country: undefined,
  email: '',
  firstName: '',
  jobLevel: '',
  lastName: '',
  partnerName: undefined,
  partnerRole: undefined,
  phone: '',
  position: '',
  salutation: undefined,
  title: '',
})

const { result } = useUserPropertiesPreferencesQuery()
const queryVariables = computed(() => ({ id: String(formData.companyIndustryId || 1) }))
const companyFieldsQuery = useCompanyFieldsByIndustryQuery(queryVariables, { enabled: !!queryVariables.value.id })

onMounted(async () => {
  if (!props.ticket) return

  Object.keys(formData).forEach(async (key) => {
    if (!props.ticket) return
    formData[key as keyof FormData] = props.ticket[key as keyof Ticket]
    if (!props.ticket.country && !needsCountry.value) formData.country = 'NOT_PROVIDED'
  })

  if (props.ticket.aasmState == 'unlocked') $validations.value.$validate()
})

const isExtraCompanyAttributesRequired = computed(() => hasJobRelatedAttributes.value && formData.jobLevel !== 'student')

const formRules = computed(() => ({
  birthdate: { requiredIfNeedsBirthday: requiredIf(isBirthdateRequired) },
  companyName: { required },
  companyType: { required },
  country: { requiredIfExhibitorQuota: requiredIf(needsCountry) },
  email: { email, required },
  firstName: { required },
  lastName: { required },
  partnerName: { requiredIfCrewQuota: requiredIf(hasPartnerAttributes) },
  partnerRole: { requiredIfCrewQuota: requiredIf(hasPartnerAttributes) },
  phone: { requiredIfNeedsPhone: requiredIf(isPhoneRequired) },
  position: { required },
  salutation: { required },
  jobLevel: { requiredIfExhibitorQuota: requiredIf(hasJobRelatedAttributes) },
  companyDepartment: { requiredIfStudentJobLevel: requiredIf(isExtraCompanyAttributesRequired) },
  companySize: { requiredIfStudentJobLevel: requiredIf(isExtraCompanyAttributesRequired) },
  companyIndustryId: { requiredIfStudentJobLevel: requiredIf(isExtraCompanyAttributesRequired) },
  companyFieldId: { requiredIfStudentJobLevel: requiredIf(isExtraCompanyAttributesRequired) },
}))

const $externalResults = reactive<{ email: string[] }>({ email: [] })
const $validations = useVuelidate(formRules, formData, { $externalResults })
watch(
  () => props.errorMessage,
  async () => {
    $externalResults.email = props.errorMessage ? [props.errorMessage] : []
    if (props.errorMessage) {
      await nextTick()
      scrollToErrors()
    }
  },
)
// Reset server provided email error message when user changes email:
watch(
  () => formData.email,
  () => ($externalResults.email = []),
)

watch(
  () => formData.jobLevel,
  () => {
    if (formData.jobLevel === 'student') {
      formData.companyDepartment = undefined
      formData.companySize = undefined
      formData.companyIndustryId = undefined
      formData.companyFieldId = undefined
    }
  },
)

const jobLevels = jobLevelsMapper(result)
const companyDepartments = companyDepartmentsMapper(result)
const companySizes = companySizesMapper(result)
const companyIndustries = companyIndustriesMapper(result)
const companyFields = companyFieldsMapper(companyFieldsQuery.result)
const countries = countriesMapper(result)
const salutations = salutationsMapper(result)
const companyTypes = companyTypesMapper(result)

const typed_t = typed_translation('InvitationQuota', props.invitationQuota.kind)

const getFormError = function (type: string, hasError: boolean) {
  if (!hasError) return undefined
  switch (type) {
    case 'email':
      return t('validations.email')
    case 'required':
      return t('validations.required')
    default:
      return t('validations.required')
  }
}

const emit = defineEmits<{
  (e: 'closeDialog', value: void): void
  (e: 'submitForm', value: FormData): void
}>()

async function scrollToErrors() {
  const errorBox = domRoot.value?.getElementsByClassName('error-box') || []
  const errorFields = domRoot.value?.querySelectorAll('.text-amaranth-800,.text-red') || []
  if (errorBox.length) {
    errorBox[0].scrollIntoView({ behavior: 'smooth' })
  } else if (errorFields.length) {
    errorFields[0].scrollIntoView({ behavior: 'smooth' })
  }
}

const submitForm = async function () {
  const isFormValid = await $validations.value.$validate()
  if (!isFormValid) {
    scrollToErrors()
    showNotification(t('InvitationQuota.ticketForm.badFormNotification'), 'error', 3000)
    return
  }

  formData.companyFieldId = Number(formData.companyFieldId)
  formData.companyIndustryId = Number(formData.companyIndustryId)

  emit('submitForm', formData)
}
</script>
