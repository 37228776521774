<template>
  <ConfirmModal
    :title="t('attendee.manualAddModal.title')"
    :approve="t('attendee.manualAddModal.addButton')"
    :disabled="!canAddAttendees"
    wrapper-class="max-w-2xl"
    :persist="true"
    @close-dialog="handleCloseDialog"
  >
    <template #content>
      <p class="-mt-2 px-6 pb-2">{{ t('attendee.manualAddModal.description') }}</p>
      <ChipTextInput :label="t('attendee.manualAddModal.label')" :max-chips="maxAttendees" @change="updateValues" />
    </template>
  </ConfirmModal>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

defineProps<{
  maxAttendees?: number | null
}>()

const addedNumbers = ref<string[]>([])

const canAddAttendees = computed(() => {
  return !!addedNumbers.value.length
})

const emit = defineEmits<{
  (e: 'closeDialog'): void
  (e: 'confirmDialog', value: Array<string>): void
}>()

function handleCloseDialog(isConfirm: boolean) {
  if (isConfirm) {
    emit('confirmDialog', addedNumbers.value)
  } else {
    emit('closeDialog')
  }
}

function updateValues(newValues: string[]) {
  addedNumbers.value = newValues
}
</script>
