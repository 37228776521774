import type { RouteRecordRaw } from 'vue-router'

import {
  ReviewsProductAssets,
  ReviewsProductAssetsEdit,
  ReviewsProductAssetsSubheader,
  ReviewsProductDataProvider,
  ReviewsProductDetails,
  ReviewsProductDetailsEdit,
  ReviewsProductDetailsSubheader,
  ReviewsProductEdit,
  ReviewsProductGlobalFeatures,
  ReviewsProductGlobalFeaturesEdit,
  ReviewsProductGlobalFeaturesSubheader,
  ReviewsProductPreferences,
  ReviewsProductPreferencesEdit,
  ReviewsProductPreferencesSubheader,
  ReviewsProductPricing,
  ReviewsProductPricingEdit,
  ReviewsProductPricingSubheader,
  ReviewsProductResources,
  ReviewsProductResourcesSubheader,
  ReviewsProductReviewContainer,
  ReviewsProductReviews,
  ReviewsProductReviewsSubheader,
  ReviewsProductsList,
  ReviewsProductView,
} from '@/components/ReviewsManagement'
import { ReviewsProductRouteTabs, ReviewsRouteName } from '@/router/types.ts'
import { ReviewsDataProvider } from '@/views'

export const reviewsProductsRoutes: RouteRecordRaw = {
  path: '/reviews',
  component: ReviewsDataProvider,
  children: [
    {
      path: 'index',
      name: ReviewsRouteName.ReviewsProducts,
      props: true,
      component: ReviewsProductsList,
    },
    {
      path: ':productId',
      props: true,
      component: ReviewsProductDataProvider,
      children: [
        {
          path: `${ReviewsProductRouteTabs.Reviews}/:surveyResponseId`,
          name: ReviewsRouteName.ReviewsProductViewReviewDetails,
          props: true,
          component: ReviewsProductReviewContainer,
          meta: {
            tabId: ReviewsProductRouteTabs.Reviews,
          },
        },
        {
          path: '',
          name: ReviewsRouteName.ReviewsProductView,
          redirect: { name: ReviewsRouteName.ReviewsProductViewDetails },
          props: true,
          component: ReviewsProductView,
          children: [
            {
              path: ReviewsProductRouteTabs.Details,
              name: ReviewsRouteName.ReviewsProductViewDetails,
              props: true,
              components: { default: ReviewsProductDetails, subheader: ReviewsProductDetailsSubheader },
              meta: {
                tabId: ReviewsProductRouteTabs.Details,
              },
            },
            {
              path: ReviewsProductRouteTabs.GlobalFeatures,
              name: ReviewsRouteName.ReviewsProductViewGlobalFeatures,
              props: true,
              components: { default: ReviewsProductGlobalFeatures, subheader: ReviewsProductGlobalFeaturesSubheader },
              meta: {
                tabId: ReviewsProductRouteTabs.GlobalFeatures,
              },
            },
            {
              path: ReviewsProductRouteTabs.Pricing,
              name: ReviewsRouteName.ReviewsProductViewPricing,
              props: true,
              components: { default: ReviewsProductPricing, subheader: ReviewsProductPricingSubheader },
              meta: {
                tabId: ReviewsProductRouteTabs.Pricing,
              },
            },
            {
              path: ReviewsProductRouteTabs.Assets,
              name: ReviewsRouteName.ReviewsProductViewAssets,
              props: true,
              components: { default: ReviewsProductAssets, subheader: ReviewsProductAssetsSubheader },
              meta: {
                tabId: ReviewsProductRouteTabs.Assets,
              },
            },
            {
              path: ReviewsProductRouteTabs.Reviews,
              name: ReviewsRouteName.ReviewsProductViewReviews,
              props: true,
              components: { default: ReviewsProductReviews, subheader: ReviewsProductReviewsSubheader },
              meta: {
                tabId: ReviewsProductRouteTabs.Reviews,
              },
            },
            {
              path: ReviewsProductRouteTabs.Preferences,
              name: ReviewsRouteName.ReviewsProductViewPreferences,
              props: true,
              components: { default: ReviewsProductPreferences, subheader: ReviewsProductPreferencesSubheader },
              meta: {
                tabId: ReviewsProductRouteTabs.Preferences,
              },
            },
            {
              path: ReviewsProductRouteTabs.Resources,
              name: ReviewsRouteName.ReviewsProductViewResources,
              props: true,
              components: { default: ReviewsProductResources, subheader: ReviewsProductResourcesSubheader },
              meta: {
                tabId: ReviewsProductRouteTabs.Resources,
              },
            },
          ],
        },
        {
          path: 'edit',
          redirect: { name: ReviewsRouteName.ReviewsProductEditDetails },
          name: ReviewsRouteName.ReviewsProductEdit,
          props: true,
          component: ReviewsProductEdit,
          children: [
            {
              path: ReviewsProductRouteTabs.Details,
              name: ReviewsRouteName.ReviewsProductEditDetails,
              props: true,
              component: ReviewsProductDetailsEdit,
              meta: {
                tabId: ReviewsProductRouteTabs.Details,
              },
            },
            {
              path: ReviewsProductRouteTabs.GlobalFeatures,
              name: ReviewsRouteName.ReviewsProductEditGlobalFeatures,
              props: true,
              component: ReviewsProductGlobalFeaturesEdit,
              meta: {
                tabId: ReviewsProductRouteTabs.GlobalFeatures,
              },
            },
            {
              path: ReviewsProductRouteTabs.Pricing,
              name: ReviewsRouteName.ReviewsProductEditPricing,
              props: true,
              component: ReviewsProductPricingEdit,
              meta: {
                tabId: ReviewsProductRouteTabs.Pricing,
              },
            },
            {
              path: ReviewsProductRouteTabs.Assets,
              name: ReviewsRouteName.ReviewsProductEditAssets,
              props: true,
              component: ReviewsProductAssetsEdit,
              meta: {
                tabId: ReviewsProductRouteTabs.Assets,
              },
            },
            {
              path: ReviewsProductRouteTabs.Preferences,
              name: ReviewsRouteName.ReviewsProductEditPreferences,
              props: true,
              component: ReviewsProductPreferencesEdit,
              meta: {
                tabId: ReviewsProductRouteTabs.Preferences,
              },
            },
          ],
        },
      ],
    },
  ],
}
