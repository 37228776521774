<template>
  <div class="rounded-lg border border-grey-300 bg-white p-6 dark:border-grey-700 dark:bg-grey-950">
    <h2 class="text-headline-m mb-3">{{ t('PartnerCompany.webLogo') }}</h2>
    <hr class="dark:bg-gray-700 my-4 h-px border-0 bg-grey-300" />
    <div class="flex h-36 gap-3">
      <img class="aspect-square h-full rounded object-cover" :src="previewWebLogoUrl" />
      <div class="flex flex-col justify-center">
        <p class="text-label-m text-grey-500 dark:text-grey-500">{{ t('PartnerCompany.webLogoHint') }}</p>
        <label class="btn-secondary-black-s mt-2 cursor-pointer">
          <span class="whitespace-nowrap font-medium">{{ getWebLogoFilename }}</span>
          <SvgIcon :path="mdiTrayArrowUp" :size="15" class="ml-1" />
          <input type="file" class="hidden" :accept="webLogoAcceptedFormats" @change="handleWebLogoChange" />
        </label>
        <div v-if="v$.logos.webLogo.$errors.length" class="text-label-m mt-2 text-red-500">
          <div v-for="error in v$.logos.webLogo.$errors" :key="error.$message">{{ error.$message }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-4 rounded-lg border border-grey-300 bg-white p-6 dark:border-grey-700 dark:bg-grey-950">
    <h2 class="text-headline-m mb-3">{{ t('PartnerCompany.logo') }}</h2>
    <hr class="dark:bg-gray-700 my-4 h-px border-0 bg-grey-300" />
    <div class="flex h-36 gap-3">
      <img class="aspect-square w-36 rounded object-cover" :src="previewLogoUrl" />
      <div class="flex flex-col justify-center">
        <p class="text-label-m text-grey-500 dark:text-grey-500">{{ t('PartnerCompany.logoHint') }}</p>
        <label class="btn-secondary-black-s mt-2 cursor-pointer">
          <span class="whitespace-nowrap font-medium">{{ getLogoFilename }}</span>
          <SvgIcon :path="mdiTrayArrowUp" :size="15" class="ml-1" />
          <input type="file" class="hidden" :accept="logoAcceptedFormats" @change="handleLogoChange" />
        </label>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { mdiTrayArrowUp } from '@mdi/js'
import type { Validation } from '@vuelidate/core'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps<{
  v$: Validation
  logoUrl: string | undefined
  webLogoUrl: string | undefined
}>()

const model = defineModel<{
  logo: File | undefined
  webLogo: File | undefined
}>({
  default: () => ({
    logo: undefined,
    webLogo: undefined,
  }),
})

const logoAcceptedFormats = 'application/pdf,application/postscript,image/svg+xml'
const webLogoAcceptedFormats = 'image/png,image/jpg,image/jpeg'

const getLogoFilename = computed(() => {
  const filename = model.value.logo?.name || ''
  return filename.length ? shortenFileName(filename) : t('PartnerCompany.uploadNewImage')
})

const getWebLogoFilename = computed(() => {
  const filename = model.value.webLogo?.name || ''
  return filename.length ? shortenFileName(filename) : t('PartnerCompany.uploadNewImage')
})

const previewLogoUrl = computed(() => {
  return props.logoUrl?.length ? props.logoUrl : 'assets/images/profile-img.png'
})

const previewWebLogoUrl = computed(() => {
  return props.webLogoUrl?.length ? props.webLogoUrl : 'assets/images/profile-img.png'
})

function shortenFileName(fileName: string): string {
  const dotIndex = fileName.lastIndexOf('.')
  const namePart = dotIndex !== -1 ? fileName.slice(0, dotIndex) : fileName
  const extPart = dotIndex !== -1 ? fileName.slice(dotIndex) : ''
  const maxTotalLength = 16
  const maxNameLength = maxTotalLength - extPart.length

  const shortName = namePart.length > maxNameLength ? namePart.slice(0, maxNameLength - 1) + '…' : namePart

  return shortName + extPart
}

function handleLogoChange(e: Event) {
  const selected = (e.target as HTMLInputElement).files?.[0] || undefined
  model.value.logo = selected
}

function handleWebLogoChange(e: Event) {
  const selected = (e.target as HTMLInputElement).files?.[0] || undefined
  model.value.webLogo = selected
}
</script>
