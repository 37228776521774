<template>
  <div class="rounded-lg border border-grey-300 bg-white p-6 dark:border-grey-700 dark:bg-grey-950">
    <h2 class="text-headline-m">{{ t('PartnerCompany.general') }}</h2>
    <hr class="dark:bg-gray-700 my-4 h-px border-0 bg-grey-300" />

    <TextInput
      id="displayName"
      v-model="model.displayName"
      name="displayName"
      :label="t('PartnerCompany.name') + '*'"
      :helper-text="t('PartnerCompany.nameHint')"
      :error="getValidationErrors(v$.generalInfo.displayName)"
    />

    <TextInput
      id="descriptionDe"
      v-model="model.descriptionDe"
      type="textarea"
      :label="t('PartnerCompany.descriptionDe') + '*'"
      :hint="t('PartnerCompany.descriptionDeHint')"
      :max-length="750"
      :error="getValidationErrors(v$.generalInfo.descriptionDe)"
    />

    <TextInput
      id="descriptionEn"
      v-model="model.descriptionEn"
      type="textarea"
      :label="t('PartnerCompany.descriptionEn') + '*'"
      :hint="t('PartnerCompany.descriptionEnHint')"
      :max-length="750"
      :error="getValidationErrors(v$.generalInfo.descriptionEn)"
    />
  </div>
</template>
<script setup lang="ts">
import { TextInput } from '@ramp106/omrjs-core-ui'
import type { Validation } from '@vuelidate/core'
import { useI18n } from 'vue-i18n'

import type { UpdatePartnerCompanyMutationVariables } from '@/gql/myomr'
import { getValidationErrors } from '@/helpers/validationHelper'

const { t } = useI18n()

defineProps<{
  v$: Validation
}>()

const model = defineModel<NonNullable<UpdatePartnerCompanyMutationVariables>>({
  default: () => ({
    displayName: '',
    descriptionEn: '',
    descriptionDe: '',
  }),
})
</script>
