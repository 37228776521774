<template>
  <div>
    <div class="text-body-m-bold">
      {{ title }}
    </div>
    <div class="text-body-s">
      {{ description }}
    </div>
    <div v-if="items.length" class="mt-2 flex flex-wrap items-center gap-2.5">
      <MdiSvg :path="mdiEmailOutline" class="text-grey-700" size="20" />
      <ChipBadge v-for="(item, index) in items" :key="`${item}-${index}`" color="purple" size="m">
        {{ item }}
      </ChipBadge>
    </div>
    <div v-else class="flex items-center gap-x-1 text-grey-500">
      <MdiSvg :path="mdiClose" size="20" />
      <span>{{ t('Reviews.product.view.preferences.noEmailsInfo') }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { mdiClose, mdiEmailOutline } from '@mdi/js'
import { ChipBadge, MdiSvg } from '@ramp106/omrjs-core-ui'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
defineProps<{
  title: string
  description: string
  items: string[]
}>()
</script>
