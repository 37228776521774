<template>
  <div>
    <ErrorNotFound v-if="!loading && !agency" />
    <div class="h-1">
      <ProgressBar v-if="loading" />
    </div>
    <div v-if="agency">
      <RouterView :agency="agency" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, provide } from 'vue'

import { useReviewsAgencyByIdQuery } from '@/gql/reviews'
import { useRoleRedirect } from '@/services/roleRedirect'
import { reloadReviewsAgencyKey } from '@/symbols/reloadReviewsProductKey'
import ProgressBar from '@/ui/ProgressBar.vue'
import ErrorNotFound from '@/views/ErrorNotFound.vue'

const props = defineProps<{
  agencyId: string
}>()

useRoleRedirect('manageReviews')

const variables = computed(() => ({ id: props.agencyId }))

const { loading, result, refetch } = useReviewsAgencyByIdQuery(variables, { clientId: 'reviews' })

const agency = computed(() => {
  return result.value?.manage?.agency || undefined
})

provide(reloadReviewsAgencyKey, refetch)
</script>
