<template>
  <ConfirmModal persist wrapper-class="w-[100%] max-w-xl" @close-dialog="handleForm">
    <template #title>
      <h1 class="text-headline-m p-6 pb-5">{{ t('ProspectsCatalog.editList') }}</h1>
    </template>
    <template #content>
      <div class="p-6">
        <TextInput
          v-model="formData.listName"
          :label="t('ProspectsCatalog.createListModal.listNameLabel') + ' *'"
          :error="getValidationErrors($validations.listName)"
        />
        <SelectInput
          v-model="formData.contactReason"
          :disabled="isDmcEvent"
          :error="getValidationErrors($validations.contactReason)"
          :label="t('ProspectsCatalog.createListModal.contactReasonLabel') + ' *'"
        >
          <option v-for="(value, key) in contactReasons" :key="`select_${key}`" :value="key">{{ value }}</option>
        </SelectInput>
        <p class="my-5">{{ t('ProspectsCatalog.createListModal.description') }}</p>
        <div class="relative">
          <TextInput
            v-model="formData.contactName"
            :disabled="!!activeList.submittedAt"
            :error="getValidationErrors($validations.contactName)"
            :label="t('ProspectsCatalog.createListModal.contactNameLabel') + ' *'"
            :append-icon="activeList.submittedAt != null ? mdiLock : undefined"
          />
        </div>
        <div class="relative">
          <TextInput
            v-model="formData.contactEmail"
            type="email"
            :disabled="!!activeList.submittedAt"
            :error="getValidationErrors($validations.contactEmail)"
            class="mt-6"
            :label="t('ProspectsCatalog.createListModal.contactEmailLabel') + ' *'"
            :append-icon="activeList.submittedAt != null ? mdiLock : undefined"
          />
        </div>
      </div>
    </template>
    <template #actions>
      <div class="ml-6 flex items-center">
        <button v-if="!activeList.submittedAt" class="btn-secondary-error-m" @click="goToDeleteModal">
          <SvgIcon :path="mdiDelete" :size="28" class="-ml-1 mr-2" />
          {{ t('ProspectsCatalog.createListModal.delete') }}
        </button>
        <div class="mt-6 flex justify-end px-6 pb-6">
          <button class="btn-secondary-purple-m uppercase" @click="handleForm(false)">
            {{ t('cancel') }}
          </button>
          <button class="btn-primary-purple-m ml-4" data-test-id="accept-button" @click="handleForm(true)">
            {{ t('ProspectsCatalog.updateListButton') }}
          </button>
        </div>
      </div>
    </template>
  </ConfirmModal>
</template>

<script setup lang="ts">
import { mdiLock, mdiDelete } from '@mdi/js'
import { TextInput, SelectInput } from '@ramp106/omrjs-core-ui'
import { useVuelidate } from '@vuelidate/core'
import { onMounted, reactive, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

import { type MeQuery, useUpdateProspectListMutation } from '@/gql/myomr'
import { toPlainId } from '@/helpers/globalIdHelper'
import { showNotification } from '@/helpers/notificationHelper'
import { getValidationErrors } from '@/helpers/validationHelper'
import { required, email } from '@/services/validators'

import type { ProspectList } from './types'

const { t } = useI18n()

const route = useRoute()
const router = useRouter()

const { mutate: updateProspectListMutation } = useUpdateProspectListMutation()

const props = defineProps<{
  me: NonNullable<MeQuery['me']>
  activeList: ProspectList
}>()

const emit = defineEmits<{
  (e: 'refetchProspectLists'): void
}>()

function goToDeleteModal() {
  const activeListId = toPlainId(props.activeList.id)
  return router.push({ name: 'deleteProspectList', params: { listId: activeListId as string } })
}

const isDmcEvent = computed(() => props.activeList?.event?.slug?.startsWith('dmc'))

const contactReasons = computed(() => {
  if (isDmcEvent.value) {
    return { invitation: t('ProspectsCatalog.createListModal.contactReasons.Invitation') }
  } else {
    return {
      ticket_invitation: t('ProspectsCatalog.createListModal.contactReasons.TicketInvitation'),
      make_an_appointment: t('ProspectsCatalog.createListModal.contactReasons.MakeAnAppointment'),
    }
  }
})

const formData = reactive({
  listName: props?.activeList?.title || '',
  contactName: props?.activeList?.contactName || '',
  contactEmail: props?.activeList?.contactEmail || '',
  contactReason: props?.activeList?.contactReason || '',
})

const formRules = {
  listName: { required },
  contactName: { required },
  contactEmail: { required, email },
  contactReason: { required },
}

onMounted(() => {
  formData.listName = props.activeList.title
  formData.contactName = props.activeList.contactName || ''
  formData.contactEmail = props.activeList.contactEmail || ''
  formData.contactReason = props.activeList.contactReason || ''
})

const $validations = useVuelidate(formRules, formData)

function closeModal() {
  router.push({ name: 'prospectList', params: { listId: route.params.listId } })
}

async function handleForm(shouldSubmit: boolean) {
  if (!shouldSubmit) return closeModal()

  const validForm = await $validations.value.$validate()
  if (!validForm) return

  try {
    await updateProspectListMutation({
      prospectListId: props.activeList.id,
      contactName: formData.contactName,
      contactEmail: formData.contactEmail,
      contactReason: formData.contactReason,
      title: formData.listName,
    })
    showNotification(t('ProspectsCatalog.createListModal.successfulNotification'), 'success', 3000)
    emit('refetchProspectLists')
    closeModal()
  } catch (_e) {
    showNotification(t('ProspectsCatalog.createListModal.failedNotification'), 'error', 3000)
    emit('refetchProspectLists')
    closeModal()
  }
}
</script>
