<template>
  <div class="flex flex-col py-6">
    <div class="pb-8">
      <div class="text-body-m-bold">{{ t('Reviews.product.view.media.widgetTitle.top') }}</div>
      <div class="text-body-s widget-description-top" v-html="t('Reviews.product.view.media.widgetDescription.top')"></div>
    </div>

    <div class="pb-4">
      <ChipBadge rounded type="default" color="warning"><span class="text-body-s-bold">Preview</span></ChipBadge>
    </div>
    <div class="grid grid-cols-4 gap-4">
      <div v-for="widget in generatedWidget" :key="widget.id" class="rounded border border-grey-200 bg-grey-100 p-4">
        <div>
          <div class="text-body-s-bold">{{ widget.title }}</div>
          <img :src="widget.src" class="w-full justify-self-center pb-12 pt-6" />
          <div class="flex border border-grey-300 bg-white p-1">
            <div class="scrollbar-hide mr-2 w-64 overflow-scroll whitespace-nowrap text-grey-500">{{ widget.link }}</div>
            <TooltipWrapper top>
              <template #trigger>
                <SvgIcon
                  :path="mdiContentCopy"
                  :size="16"
                  class="align-middle text-purple-500 hover:text-purple-900"
                  @click="copyURLToClipboard(widget.link)"
                />
              </template>
              <template #content>
                <div>{{ t('Reviews.product.view.media.embedToolTip') }}</div>
              </template>
            </TooltipWrapper>
          </div>
        </div>
      </div>
    </div>
    <div class="py-16">
      <div class="text-body-m-bold">{{ t('Reviews.product.view.media.widgetTitle.bottom') }}</div>
      <div v-html="t('Reviews.product.view.media.widgetDescription.bottom')" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { mdiContentCopy } from '@mdi/js'
import { ChipBadge, TooltipWrapper } from '@ramp106/omrjs-core-ui'
import { reviewsPaths } from '@ramp106/omrjs-routing'
import { useI18n } from 'vue-i18n'

import { baseOmrUrl } from '@/config/env'
import type { ProductById } from '@/gql/reviews'
import { showNotification } from '@/helpers/notificationHelper'

const props = defineProps<{
  product: ProductById
}>()

const { t } = useI18n()
const reviewsPDPUrl =
  baseOmrUrl + reviewsPaths.product({ locale: 'de', slug: props.product.slug }, { utm_source: 'widget', utm_medium: 'vendor' })

interface Widget {
  title: string
  link: string
}

function buildWidgetObjects(widgetData: Widget[]) {
  const widgets = widgetData.map((widget, index) => {
    return {
      id: index,
      title: widget.title,
      src: baseOmrUrl + widget.link,
      link: `<a title='${props.product.title}' href='${reviewsPDPUrl}'><img src='${baseOmrUrl}${widget.link}' alt='OMR - Rating Widget' width='200' height='150' /></a>`,
    }
  })
  return widgets
}

const generatedWidget = buildWidgetObjects([
  {
    title: t('Reviews.product.view.media.widgetLightTitle'),
    link: reviewsPaths.ratingWidget({ locale: 'de', slug: props.product.slug, template: 'light' }),
  },
  {
    title: t('Reviews.product.view.media.widgetDarkTitle'),
    link: reviewsPaths.ratingWidget({ locale: 'de', slug: props.product.slug, template: 'dark' }),
  },
  {
    title: t('Reviews.product.view.media.widgetTransparentLightTitle'),
    link: reviewsPaths.ratingWidget({ locale: 'de', slug: props.product.slug, template: 'transparentLight' }),
  },
  {
    title: t('Reviews.product.view.media.widgetTransparentDarkTitle'),
    link: reviewsPaths.ratingWidget({ locale: 'de', slug: props.product.slug, template: 'transparentDark' }),
  },
])

function copyURLToClipboard(link: string) {
  navigator.clipboard.writeText(link)
  showNotification(t('Reviews.product.view.media.embedCopied'), 'success')
}
</script>
<style scoped>
.widget-description-top :deep(a) {
  @apply font-bold underline !important;
}
</style>
