<template>
  <RouterLink
    class="text-button-m inline-flex flex-row items-center uppercase text-black visited:text-black hover:text-purple-600"
    :to="props.to"
  >
    <SvgIcon :path="mdiChevronLeft" :size="16" class="mr-1" />

    <slot />
  </RouterLink>
</template>

<script setup lang="ts">
import { mdiChevronLeft } from '@mdi/js'
import type { RouteLocationRaw } from 'vue-router'

const props = defineProps<{
  to: RouteLocationRaw
}>()
</script>
