import type { RouteRecordRaw } from 'vue-router'

import {
  ReviewsAgenciesList,
  ReviewsAgencyDataProvider,
  ReviewsAgencyDetails,
  ReviewsAgencyDetailsSubheader,
  ReviewsAgencyView,
} from '@/components/ReviewsAgenciesManagements'
import { ReviewsAgencyRouteTabs, ReviewsRouteName } from '@/router/types.ts'
import { ReviewsAgenciesDataProvider } from '@/views'

export const reviewsAgenciesRoutes: RouteRecordRaw = {
  path: '/agencies',
  component: ReviewsAgenciesDataProvider,
  children: [
    {
      path: 'index',
      name: ReviewsRouteName.ReviewsAgencies,
      props: true,
      component: ReviewsAgenciesList,
    },
    {
      path: ':agencyId',
      props: true,
      component: ReviewsAgencyDataProvider,
      children: [
        {
          path: '',
          name: ReviewsRouteName.ReviewsAgencyView,
          redirect: { name: ReviewsRouteName.ReviewsAgencyViewDetails },
          props: true,
          component: ReviewsAgencyView,
          children: [
            {
              path: ReviewsAgencyRouteTabs.Details,
              name: ReviewsRouteName.ReviewsAgencyViewDetails,
              props: true,
              components: { default: ReviewsAgencyDetails, subheader: ReviewsAgencyDetailsSubheader },
              meta: {
                tabId: ReviewsAgencyRouteTabs.Details,
              },
            },
          ],
        },
      ],
    },
  ],
}
