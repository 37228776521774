<template>
  <div class="pb-6">
    <h3 class="text-headline-xs border-b-solid mb-4 border-b border-grey-300 pb-2">{{ title }}</h3>
    <template v-for="item in items" :key="item.key">
      <ProductFeatureValue :name="item.name" :icon="item.icon" :value="item.status">
        <div v-html="t(`Reviews.product.edit.globalFeatures.versions.${item.key}.hint`, { pricingPlansEditUrl })" />
      </ProductFeatureValue>
    </template>
  </div>

  <ConfirmModal
    v-if="showModal"
    wrapper-class="max-w-4xl"
    :title="t('Reviews.product.edit.globalFeatures.confirmModal.title')"
    :description="t('Reviews.product.edit.globalFeatures.confirmModal.description')"
    @close-dialog="onCloseModal"
  >
    <template #actions>
      <div class="mt-6 flex justify-end px-6 pb-6">
        <button class="btn-secondary-purple-m" @click="onCloseModal(true)">
          {{ t('Reviews.product.edit.globalFeatures.confirmModal.confirm') }}
        </button>
        <button class="btn-primary-purple-m ml-4" data-test-id="accept-button" @click="onCloseModal(false)">
          {{ t('Reviews.product.edit.globalFeatures.confirmModal.abort') }}
        </button>
      </div>
    </template>
  </ConfirmModal>
</template>
<script setup lang="ts">
import { type ProductGlobalFeaturesTypes } from '@ramp106/omrjs-reviews-ui'
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { ReviewsRouteName } from '@/router/types'

import ProductFeatureValue from './/ProductFeatureValue.vue'

defineProps<{
  title: string
  items: ProductGlobalFeaturesTypes.GlobalFeatureItem[]
}>()

const { t } = useI18n()
const router = useRouter()

const showModal = ref(false)

function onCloseModal(stayOnPage: boolean) {
  showModal.value = false
  if (stayOnPage) router.push({ name: ReviewsRouteName.ReviewsProductEditPricing })
}

function confirmExit(event: MouseEvent) {
  if ((event.target as HTMLElement).closest('a')) {
    const target = (event.target as HTMLElement).closest('a') as HTMLAnchorElement
    if (target.href.includes(pricingPlansEditUrl.value)) {
      event.preventDefault()
      showModal.value = true
    }
  }
}

const pricingPlansEditUrl = computed(() => router.resolve({ name: ReviewsRouteName.ReviewsProductEditPricing }).href)

onMounted(() => {
  document.addEventListener('click', confirmExit)
})

onUnmounted(() => {
  document.removeEventListener('click', confirmExit)
})
</script>
