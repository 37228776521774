<template>
  <div class="relative h-7 w-7">
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
        <circle cx="14.5" cy="14.9092" r="13.5" fill="white" stroke="#ECEDEE" />
      </svg>
    </div>
    <div class="absolute inset-0 flex items-center justify-center">
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
        <path
          d="M11.8333 12.2424H5.83334V7.9091C5.83334 6.24243 7.16667 4.9091 8.83334 4.9091C10.5 4.9091 11.8333 6.24243 11.8333 7.9091V12.2424ZM13.1667 11.5758V7.9091C13.1667 5.86243 11.74 4.1491 9.83334 3.69576V3.24243C9.83334 2.97722 9.72798 2.72286 9.54044 2.53532C9.35291 2.34779 9.09855 2.24243 8.83334 2.24243C8.56812 2.24243 8.31377 2.34779 8.12623 2.53532C7.93869 2.72286 7.83334 2.97722 7.83334 3.24243V3.69576C5.92001 4.1491 4.50001 5.86243 4.50001 7.9091V11.5758L3.16667 12.9091V13.5758H14.5V12.9091L13.1667 11.5758ZM8.83334 15.5758C9.18696 15.5758 9.5261 15.4353 9.77615 15.1852C10.0262 14.9352 10.1667 14.5961 10.1667 14.2424H7.5C7.5 14.5961 7.64048 14.9352 7.89053 15.1852C8.14058 15.4353 8.47972 15.5758 8.83334 15.5758Z"
          fill="#D4D6D9"
        />
      </svg>
    </div>
  </div>
</template>
