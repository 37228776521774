<template>
  <div>
    <RouterLink :to="props.to" custom>
      <a :href="href" @click.prevent="toggleDropDown">
        <div class="flex items-center justify-between text-white">
          <span class="text-body-m-bold block px-5 py-3 text-white">
            {{ props.label }}
          </span>
          <div class="px-4 py-2 text-right">
            <SvgIcon :path="iconPath" :size="24" class="cursor-pointer" />
          </div>
        </div>
      </a>

      <div v-if="dropDownVisible">
        <slot />
      </div>
    </RouterLink>
  </div>
</template>

<script setup lang="ts">
import { mdiChevronDown, mdiChevronUp } from '@mdi/js'
import { computed, ref } from 'vue'
import { type RouteLocationRaw, useLink } from 'vue-router'

const props = defineProps<{
  label: string
  to: RouteLocationRaw
}>()

const { isActive, href } = useLink({ to: props.to })
const dropDownVisible = ref(isActive.value)
const toggleDropDown = () => (dropDownVisible.value = !dropDownVisible.value)

const iconPath = computed(() => (dropDownVisible.value ? mdiChevronUp : mdiChevronDown))
</script>

<style scoped>
.router-link-active {
  @apply bg-purple-600;
}
</style>
