<template>
  <div>
    <template v-if="hasCompany">
      <EditPartnerCompanyForm :partner-company="me.partnerCompany" :contact-for-appointments="contactForAppointments" />
    </template>

    <AlertBox v-if="!hasCompany && !meLoading" type="warning" class="mt-5">
      {{ t('PartnerCompany.noPartnerCompany') }}
    </AlertBox>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import type { MeQuery } from '@/gql/myomr'
import { useRoleRedirect } from '@/services/roleRedirect'

useRoleRedirect('viewCompany')

const { t } = useI18n()

const props = defineProps<{
  me: NonNullable<MeQuery['me']>
  meLoading?: boolean
}>()

const hasCompany = computed(() => props.me?.partnerCompany)

const contactForAppointments = computed(() => {
  if (!props.me.partnerCompany) return null
  return props.me.partnerCompany.contacts.find((contact) => contact.type === 'SalesContact')
})
</script>
