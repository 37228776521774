<template>
  <div class="fixed bottom-0 right-10 max-w-[400px]">
    <SlideTransition>
      <MessageBanner v-if="hintVisible" class="rounded-b-none border-b-0" :headline="headline" type="info">
        <template #action>
          <button class="text-grey-500" @click="onClose"><MdiSvg :path="mdiChevronDown" /></button>
        </template>
        <div class="text-body-s">
          <p class="message mb-3" v-html="text" />
          <CheckBox size="small" @update:model-value="onCheckChange">{{ t('Reviews.workInProgressHint.hideLabel') }}</CheckBox>
        </div>
      </MessageBanner>
    </SlideTransition>
  </div>
</template>
<script setup lang="ts">
import { mdiChevronDown } from '@mdi/js'
import { CheckBox, MdiSvg, MessageBanner, SlideTransition } from '@ramp106/omrjs-core-ui'
import Cookies from 'universal-cookie'
import { ref, onMounted, computed } from 'vue'
import { useI18n } from 'vue-i18n'

const cookies = new Cookies(null, { path: '/' })

const props = defineProps<{
  context: 'details' | 'reviews'
}>()

const hintVisible = ref(false)
const { t } = useI18n()

const headline = computed(() => t(`Reviews.workInProgressHint.${props.context}.headline`))
const text = computed(() => t(`Reviews.workInProgressHint.${props.context}.text`))
const cookieKey = computed(() => `workInProgressHint-${t(`Reviews.workInProgressHint.${props.context}.cookieKey`)}-disabled`)

function onClose() {
  hintVisible.value = false
}

function onCheckChange(checked: boolean) {
  if (!checked) {
    return
  }
  cookies.set(cookieKey.value, true)
  setTimeout(() => {
    hintVisible.value = false
  }, 500)
}

onMounted(() => {
  const disabled = cookies.get(cookieKey.value)
  if (disabled) {
    return
  }

  setTimeout(() => {
    hintVisible.value = true
  }, 1000)
})
</script>
<style lang="css" scoped>
.message {
  @apply text-purple-700;

  :deep(a) {
    @apply font-bold text-purple-700;
  }
}
</style>
