import { InMemoryCache } from '@apollo/client/core'

import type { RoleNameEnum } from '@/gql/myomr'

// Uncomment for debugging in dev mode:
// import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
// loadDevMessages();
// loadErrorMessages();

const cache = new InMemoryCache({
  typePolicies: {
    Booth: {
      fields: {
        booths: {
          keyArgs: ['eventPeriod', 'type'], // separate cache key for each eventPeriod and type combination
        },
      },
    },
    User: {
      fields: {
        roles: {
          merge(_existing: RoleNameEnum[] = [], incoming: RoleNameEnum[]) {
            return incoming
          },
        },
      },
    },
    PartnerCompany: {
      fields: {
        interests: {
          merge(_existing: string[] = [], incoming: string[]) {
            return incoming
          },
        },
      },
    },
    UserPropertiesPreferences: {
      merge: true,
    },
  },
})

export default cache
