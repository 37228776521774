<template>
  <div class="booth-profile">
    <h1 v-if="props.booth" class="pb-7">
      {{ t('Booth.headline', { name: props.booth.boothNumber }) }}
      {{ t('Booth.ofPartnerCompany', { name: props.booth.partnerCompany?.name }) }}
    </h1>

    <div v-if="hasBoothOsc" id="booth-services" class="my-8 text-center">
      <h2 class="mb-5">
        {{ t('Booth.boothServiceOrdersTitle') }}
      </h2>
      <p class="mx-auto max-w-xl text-sm">
        {{ t('Booth.boothServiceOrdersDesc') }}
      </p>
      <a v-if="props.booth.oscPortalUrl" class="btn-primary-purple-m mt-5" :href="props.booth.oscPortalUrl" target="_blank">
        {{ t('Booth.boothServiceOrdersCTA') }}
      </a>
    </div>

    <div class="mt-16">
      <h1>{{ t('Booth.DocumentPanelTitle') }}</h1>

      <!-- Pass event ID here get it from BOOTH -->
      <DocumentPanel
        v-if="props.booth && props.booth.type && props.booth.id"
        :gid="props.booth.id"
        :type="props.booth.type"
        :event-id="props.booth.event.id"
        :show-notification="true"
      />
    </div>

    <BoothExtras v-if="showBoothExtras" id="booth-extras" :booth="props.booth" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { DocumentPanel } from '@/components'
import { type BoothQuery, BoothTypeEnum } from '@/gql/myomr'

import BoothExtras from './BoothExtras.vue'

const i18n = useI18n()
const t = i18n.t

const props = defineProps<{
  booth: Extract<BoothQuery['node'], { __typename: 'Booth' }>
  boothId: string
}>()

const showBoothExtras = computed(() => {
  return props.booth.products.length
})
const hasBoothOsc = computed(() => {
  return (
    props.booth.oscPortalUrl &&
    [
      BoothTypeEnum.PremiumLoungeBooth,
      BoothTypeEnum.PremiumOutdoorBooth,
      BoothTypeEnum.PremiumBooth,
      BoothTypeEnum.BrandedRoomBooth,
      BoothTypeEnum.PremiumExpoTurnkeyBooth,
    ].includes(props.booth.type as BoothTypeEnum)
  )
})
</script>
