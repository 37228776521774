<template>
  <TabBar gap="gap-0">
    <NavTabBarItem
      :disabled="disabled"
      :is-selected="route.meta?.tabId === ReviewsProductRouteTabs.Details"
      :to="{ name: ReviewsRouteName.ReviewsProductViewDetails }"
    >
      {{ t('Reviews.product.view.tab.details') }}
    </NavTabBarItem>
    <NavTabBarItem
      :disabled="disabled"
      :is-selected="route.meta?.tabId === ReviewsProductRouteTabs.GlobalFeatures"
      :to="{ name: ReviewsRouteName.ReviewsProductViewGlobalFeatures }"
    >
      {{ t('Reviews.product.view.tab.globalFeatures') }}
    </NavTabBarItem>
    <NavTabBarItem
      :disabled="disabled"
      :is-selected="route.meta?.tabId === ReviewsProductRouteTabs.Pricing"
      :to="{ name: ReviewsRouteName.ReviewsProductViewPricing }"
    >
      {{ t('Reviews.product.view.tab.pricing') }}
    </NavTabBarItem>
    <NavTabBarItem
      :disabled="disabled"
      :is-selected="route.meta?.tabId === ReviewsProductRouteTabs.Assets"
      :to="{ name: ReviewsRouteName.ReviewsProductViewAssets }"
    >
      {{ t('Reviews.product.view.tab.assets') }}
    </NavTabBarItem>
    <NavTabBarItem
      :disabled="disabled"
      :is-selected="route.meta?.tabId === ReviewsProductRouteTabs.Reviews"
      :to="{ name: ReviewsRouteName.ReviewsProductViewReviews }"
    >
      {{ t('Reviews.product.view.tab.reviews') }}
    </NavTabBarItem>
    <NavTabBarItem
      :disabled="disabled"
      :is-selected="route.meta?.tabId === ReviewsProductRouteTabs.Preferences"
      :to="{ name: ReviewsRouteName.ReviewsProductViewPreferences }"
    >
      {{ t('Reviews.product.view.tab.preferences') }}
    </NavTabBarItem>
    <NavTabBarItem
      :disabled="disabled"
      :is-selected="route.meta?.tabId === ReviewsProductRouteTabs.Resources"
      :to="{ name: ReviewsRouteName.ReviewsProductViewResources }"
    >
      {{ t('Reviews.product.view.tab.media') }}
    </NavTabBarItem>
  </TabBar>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { ReviewsRouteName, ReviewsProductRouteTabs } from '@/router/types'
import { TabBar, NavTabBarItem } from '@/ui/Tabs'

const { t } = useI18n()
const route = useRoute()

const disabled = computed(() => {
  return route.name?.toString().startsWith(ReviewsRouteName.ReviewsProductEdit)
})
</script>
