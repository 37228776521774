<template>
  <DropDown v-if="$can('downloadInvitees', { for: invitationQuota })" wrapper-class="w-full">
    <template #activator="{ open }">
      <button class="btn-seconday-mint-m" @click.prevent="open">
        <SvgIcon :path="mdiDownload" :size="16" class="mr-2" />
        {{ t('InvitationQuota.downloadInviteesCSV') }}
      </button>
    </template>
    <template #default>
      <ul class="m-0 list-none divide-y divide-solid divide-grey-300 p-0">
        <li v-for="format in exportFormats" :key="`format-${format}`" class="cursor-pointer px-2 py-2 text-left hover:bg-grey-100">
          <a :href="href(format)" class="flex text-black visited:text-black">
            {{ t(`exportFormats.${format}`) }}
          </a>
        </li>
      </ul>
    </template>
  </DropDown>
</template>

<script lang="ts">
export default { name: 'DownloadInvitees' }
</script>

<script setup lang="ts">
import { mdiDownload } from '@mdi/js'
import { DropDown } from '@ramp106/omrjs-core-ui'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import type { InvitationQuotaQuery } from '@/gql/myomr'
import { toTypeAndId } from '@/helpers/globalIdHelper'
import { invitationQuotaInviteesDownloadUrl, exportFormats, type exportFormat } from '@/services/ApiService'
import { $can } from '@/services/roles'

const props = defineProps<{
  invitationQuota: InvitationQuotaQuery['invitationQuota']
}>()

const { locale, t } = useI18n()

const invitationQuotaNumericID = computed(() => {
  return (props.invitationQuota && toTypeAndId(props.invitationQuota.id)['id']) || ''
})

const href = computed(
  () => (format: exportFormat) => invitationQuotaInviteesDownloadUrl(invitationQuotaNumericID.value, locale.value, format),
)
</script>
