<template>
  <div class="pb-6">
    <h3 class="text-headline-xs border-b-solid mb-4 border-b border-grey-300 pb-2">{{ title }}</h3>
    <template v-for="item in items" :key="item.key">
      <ProductFeatureInput
        :name="item.name"
        :icon="item.icon"
        :value="globalFeatures[item.key]"
        :allowed-values="availableStatusesByFeature[item.key]"
        @change="updateGlobalFeature(item.key, $event)"
      />
    </template>
  </div>
</template>
<script setup lang="ts">
import { type ProductGlobalFeaturesTypes } from '@ramp106/omrjs-reviews-ui'

import {
  ProductAnytimeSupportEnum,
  ProductCloudEnum,
  ProductEuCompanyAffiliatedEnum,
  ProductEuLocationEnum,
  ProductEuStandardContractEnum,
  ProductGermanLocationEnum,
  ProductGermanSupportEnum,
  ProductOnPremiseEnum,
} from '@/gql/reviews'

import ProductFeatureInput from './ProductFeatureInput.vue'
import type { ReviewsProductGlobalFeaturesEditFormData } from '../types'

const availableStatusesByFeature: Record<string, NonNullable<ProductGlobalFeaturesTypes.FeatureStatus>[]> = {
  cloud: Object.values(ProductCloudEnum),
  onPremise: Object.values(ProductOnPremiseEnum),
  anytimeSupport: Object.values(ProductAnytimeSupportEnum),
  germanSupport: Object.values(ProductGermanSupportEnum),
  germanLocation: Object.values(ProductGermanLocationEnum),
  euLocation: Object.values(ProductEuLocationEnum),
  euCompanyAffiliated: Object.values(ProductEuCompanyAffiliatedEnum),
  euStandardContract: Object.values(ProductEuStandardContractEnum),
}

defineProps<{
  title: string
  globalFeatures: ReviewsProductGlobalFeaturesEditFormData
  items: ProductGlobalFeaturesTypes.GlobalFeatureItem[]
}>()

const emit = defineEmits<{
  (e: 'change', value: { key: string; value: ProductGlobalFeaturesTypes.FeatureStatus }): void
}>()

function updateGlobalFeature(key: string, value: ProductGlobalFeaturesTypes.FeatureStatus) {
  emit('change', { key, value })
}
</script>
