<template>
  <div>
    <label
      class="relative mt-2 flex w-full cursor-pointer"
      :class="{
        'form-input-error text-amaranth-800': error,
        'text-grey-900': isHighlighted && !error,
        '-top-2 left-2 bg-white px-1 text-xs': isHighlighted,
        'left-5 top-4': !isHighlighted,
      }"
    >
      <SvgIcon v-if="props.icon" :path="props.icon" :size="25" class="opacity-60" />
      <span class="text-body-m relative ml-2 w-full border-b border-black pb-1">
        <p class="absolute" :class="{ 'text-label-s -top-3 opacity-60': props.modelValue }">{{ props.label || 'Upload file' }}</p>
        <p v-if="props.modelValue" class="text-body-m">{{ props.modelValue.name }}</p>
      </span>
      <input
        ref="fileInput"
        class="hidden"
        type="file"
        :accept="props.accept"
        :test-data-error="error || null"
        @focus="focus"
        @blur="blur"
        @change="handleInputChange"
      />
    </label>
    <p v-if="props.hint" :class="{ 'ml-8': props.icon, 'text-amaranth-800': error }" class="text-label-s mt-2 opacity-60">{{ hint }}</p>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
const props = defineProps<{
  label?: string
  icon?: string
  modelValue: File
  accept?: string
  hint?: string
  error?: boolean
}>()

const fileInput = ref()

const emit = defineEmits<{
  (e: 'update:modelValue', value: File): void
  (e: 'blur'): void
  (e: 'focus'): void
}>()

const isHighlighted = computed(() => {
  return isInputFocused.value || fileInput.value !== ''
})

const isInputFocused = ref(false)

function handleInputChange() {
  if (fileInput.value?.files && fileInput.value.files[0]) {
    emit('update:modelValue', fileInput.value.files[0])
  }
}

function blur() {
  emit('blur')
  isInputFocused.value = false
}

function focus() {
  isInputFocused.value = true
  emit('focus')
}
</script>
