import { computed, type Ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import type { BreadcrumbItem } from '@/components/PageBreadcrumbs.vue'
import type { AgencyById } from '@/gql/reviews'
import { ReviewsRouteName } from '@/router/types.ts'

import { useBreadcrumb } from './useBreadcrumb.ts'
import { useReviewsAgenciesRootBreadcrumb } from './useReviewsAgenciesRootBreadcrumb.ts'

export const useReviewsAgencyBreadcrumbs = (agency: Pick<AgencyById, 'id' | 'title'>): Ref<BreadcrumbItem[]> => {
  const route = useRoute()
  const { t } = useI18n()

  const params = { agencyId: agency.id }
  const agencyName = agency.title

  const root = useReviewsAgenciesRootBreadcrumb()

  const reviewsProductViewDetails = useBreadcrumb(
    { name: ReviewsRouteName.ReviewsAgencyViewDetails, params },
    t('breadcrumbs.reviews.agency.view.details', { agencyName }),
  )

  return computed(() => {
    const breadcrumbsMap: Partial<Record<ReviewsRouteName, BreadcrumbItem[]>> = {
      [ReviewsRouteName.ReviewsAgencyView]: [],
      [ReviewsRouteName.ReviewsAgencies]: [root.value],
      [ReviewsRouteName.ReviewsAgencyViewDetails]: [root.value, reviewsProductViewDetails.value],
    }

    return breadcrumbsMap[route.name as ReviewsRouteName] || []
  })
}
