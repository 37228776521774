<template>
  <div class="card relative p-0">
    <div v-if="hasHeader" class="border-b border-grey-300 px-6">
      <slot name="header"></slot>
    </div>
    <div v-if="hasSubheader" class="border-b border-grey-300 px-6">
      <slot name="subheader"></slot>
    </div>
    <div :class="{ 'px-6': !noPadding, '': noPadding }">
      <slot></slot>
    </div>
    <div v-if="hasFooter" class="border-t border-grey-300 p-6">
      <slot name="footer"></slot>
    </div>
    <div v-if="loading" class="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-white/70 backdrop-opacity-50">
      <CircleSpinner /> <span v-if="!!progress" class="text-body-l ml-3">{{ progress }}%</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { CircleSpinner } from '@ramp106/omrjs-core-ui'
import { computed, useSlots } from 'vue'
const slots = useSlots()

defineProps<{
  noPadding?: boolean
  loading?: boolean
  progress?: number
}>()

const hasHeader = computed(() => {
  return !!slots['header']
})

const hasSubheader = computed(() => {
  return !!slots['subheader']
})

const hasFooter = computed(() => {
  return !!slots['footer']
})
</script>
