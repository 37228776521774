<template>
  <div>
    <ContentCard class="mt-5">
      <template v-if="hasHeader" #header>
        <slot name="navHeader"></slot>
      </template>

      <section v-if="getSpeakerConfig['enableEventAssigning']" data-test-id="speaker-events" class="pt-8">
        <h2 v-if="title" class="mb-4">{{ title }}</h2>
        <div v-if="showEventsError" class="red--text mb-0 pb-2 pl-8">
          {{ t('Speakers.form.errors.eventSelectionError') }}
        </div>
        <div v-for="eventType in validEventTypes" :key="`speaker-events-${eventType}`">
          <div v-if="!eventsLoading && filterEvents(eventType).length != 0" class="pb-8">
            <h3 class="mb-4 text-base font-medium">
              {{ t(`Speakers.form.${eventType}.title`) }}
            </h3>
            <div v-for="event in filterEvents(eventType)" :key="event.id" class="checkbox-group pb-4 text-base">
              <MultiCheckBox v-model="checkedEvents" :value="event.id" :disabled="isEventPast(event)">
                {{ event.name }}
              </MultiCheckBox>
            </div>
          </div>
        </div>
      </section>

      <template v-if="getSpeakerConfig['enableEventAssigning']" #footer>
        <div class="text-right">
          <RouterLink class="btn-text-purple-m mr-4" :to="{ name: 'speakerList' }">
            {{ t('cancel') }}
          </RouterLink>

          <button class="btn-primary-purple-m" :disabled="isUploading" @click.prevent="showModalOrSubmit">
            <span v-if="isUploading">
              {{ t('EventForm.processing') }}
            </span>
            <span v-else>
              {{ t('saveButtonLabel') }}
            </span>
          </button>
        </div>
      </template>
    </ContentCard>
    <ConfirmModal
      v-if="showModal"
      :title="t('Speaker.deleteModal.title')"
      :description="t('Speaker.deleteModal.description')"
      :approve="t('Speaker.deleteModal.confirmButton')"
      @close-dialog="closeModal"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted, useSlots, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { useEventsQuery, EventTypeEnum } from '@/gql/myomr'
import type { EventOverviewFragment, Speaker } from '@/gql/myomr'

import getSpeakerConfig from './getSpeakerConfig'

const { t } = useI18n()

const slots = useSlots()

const hasHeader = computed(() => {
  return !!slots['navHeader']
})

const props = defineProps<{
  speaker: Speaker
  isUploading: boolean
  title?: string
  showConfirmModal?: boolean
}>()
const emit = defineEmits<{
  (e: 'submitForm', value: { eventsToAdd: string[]; eventSpeakersToRemove: string[] }): void
}>()

const validEventTypes = [
  EventTypeEnum.DigitalMasterclass,
  EventTypeEnum.Masterclass,
  EventTypeEnum.SideEvent,
  EventTypeEnum.StageSlot,
  EventTypeEnum.GuidedTour,
]

const { result: eventsResult, loading: eventsLoading } = useEventsQuery({ type: validEventTypes, parentEventsFirst: true })

const events = computed(() =>
  (eventsResult.value?.events?.nodes || []).filter((event) => event && (!isEventPast(event) || speakerEventIds.value.includes(event.id))),
)

function isEventPast(event: EventOverviewFragment) {
  return event.endsAt && new Date(event.endsAt) <= new Date()
}

const checkedEvents = ref<string[]>([])
const hasCheckedEvents = computed(() => checkedEvents.value.length > 0)
const showEventsError = ref(false)
const showModal = ref(false)

const filterEvents = (filter: EventTypeEnum) =>
  events.value?.filter((event): event is EventOverviewFragment => event != null && event.type == filter) || []

const speakerEventIds = computed(() => props?.speaker?.eventSpeakers?.map((eventSpeaker) => eventSpeaker.event.id) || [])
const eventsToAdd = computed(() => checkedEvents.value.filter((checkedEventId) => !speakerEventIds.value.includes(checkedEventId)))
const eventSpeakersToRemove = computed(() =>
  (props.speaker.eventSpeakers || [])
    .filter((eventSpeaker) => !checkedEvents.value.includes(eventSpeaker.event.id))
    .map((eventSpeaker) => eventSpeaker.id),
)

function showModalOrSubmit() {
  if (props.showConfirmModal && !hasCheckedEvents.value) {
    showModal.value = true
  } else {
    submit()
  }
}

function closeModal(answer: boolean) {
  showModal.value = false
  if (answer) submit()
}

async function submit() {
  emit('submitForm', {
    eventsToAdd: eventsToAdd.value,
    eventSpeakersToRemove: eventSpeakersToRemove.value,
  })
}

watch(speakerEventIds, (newValue) => {
  checkedEvents.value = newValue
})

onMounted(() => {
  checkedEvents.value = speakerEventIds.value
})
</script>
