<template>
  <div>
    <div class="text-label-m -mt-4 text-grey-600">
      {{
        t('Reviews.product.edit.documents.uploadHint', {
          maxNumberOfFiles: MAX_NUMBER_OF_DOCUMENTS,
        })
      }}
    </div>
    <div class="text-label-m my-3 flex items-center text-grey-900">
      <MdiSvg :path="mdiInformationOutline" :size="24" class="mr-2 text-purple-600" />
      {{ t('Reviews.product.edit.documents.docsHint') }}
    </div>
    <div>
      <ExpandableView
        v-for="(doc, index) in formData.documents"
        :key="index"
        ref="documentsRef"
        class="pb-2 transition-opacity duration-75 ease-in-out"
        :disabled="doc.deleted"
      >
        <template #name>
          <span :class="{ 'text-error': isDocumentInvalid(index) }">
            {{ doc.name[locale] || doc.name[ALLOWED_LOCALES[0]] || t('Reviews.product.edit.documents.doc.name', { number: index + 1 }) }}
          </span>
        </template>
        <template #actions>
          <button v-if="!doc.deleted" class="btn-text-black-m" :data-testid="`delete-document-${index}`" @click.stop="deleteDoc(index)">
            <span>{{ t('Reviews.product.edit.documents.actions.delete') }}</span>
            <MdiSvg class="ml-1" :size="18" :path="mdiTrashCanOutline" />
          </button>
          <button v-else class="btn-text-black-m" @click.stop="undoDeleteDoc(index)">
            <span>{{ t('Reviews.product.edit.documents.actions.undoDelete') }}</span>
            <MdiSvg class="ml-1" :size="18" :path="mdiUndo" />
          </button>
          <button class="btn-text-black-m ml-3" data-testid="document-sort-arrow-up" :disabled="index === 0" @click.stop="moveUp(index)">
            <MdiSvg :size="18" :path="mdiArrowUp" />
          </button>
          <button
            class="btn-text-black-m ml-3"
            data-testid="document-sort-arrow-down"
            :disabled="index === formData.documents.length - 1"
            @click.stop="moveDown(index)"
          >
            <MdiSvg :size="18" :path="mdiArrowDown" />
          </button>
        </template>
        <div class="grid grid-cols-2 gap-6">
          <ReviewsProductDocumentEditForm
            :accepted-file-types="acceptedFileTypes"
            :accepted-thumbnail-types="acceptedThumbnailTypes"
            :index="index"
            :value="formData.documents[index]"
            @input="formData.documents[index] = $event"
          />
          <DocumentCard
            class="relative mt-6 max-w-[256px]"
            :class="{ 'opacity-20': doc.deleted }"
            :data-testid="'document-thumbnail'"
            :size="doc.size"
            :name="doc.name[locale] || doc.name[ALLOWED_LOCALES[0]]"
            :content-type="doc.contentType"
            :preview-url="documentThumbnailUrl(doc)"
            @click="downloadDocument(doc)"
          />
        </div>
      </ExpandableView>

      <div v-if="formData.documents.length < MAX_NUMBER_OF_DOCUMENTS" class="mt-2">
        <input
          id="document-input"
          ref="fileInput"
          type="file"
          class="absolute -z-[1] h-[0.1px] w-[0.1px] overflow-hidden opacity-0"
          :accept="acceptedFileTypes.join(',')"
          data-testid="document-upload-input"
          @change="onFilePicked"
        />
        <label for="document-input" class="btn-primary-purple-m" data-testid="document-upload-button" @click.prevent="openFilePicker">
          <MdiSvg :path="mdiPlusCircleOutline" class="mr-2" />
          {{ t('Reviews.product.edit.documents.actions.add', formData.documents.length + 1) }}
        </label>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { mdiArrowDown, mdiArrowUp, mdiInformationOutline, mdiPlusCircleOutline, mdiTrashCanOutline, mdiUndo } from '@mdi/js'
import { MdiSvg } from '@ramp106/omrjs-core-ui'
import { DocumentCard } from '@ramp106/omrjs-reviews-ui'
import { useVuelidate } from '@vuelidate/core'
import { ref, watch, reactive, onBeforeUnmount } from 'vue'
import { useI18n } from 'vue-i18n'

import ReviewsProductDocumentEditForm from '@/components/ReviewsManagement/Assets/ReviewsProductDocumentEditForm.vue'
import type { ReviewsProductDocumentEditData } from '@/components/ReviewsManagement/types'
import type { ProductById } from '@/gql/reviews'
import { ALLOWED_LOCALES, mapTranslationsArrToObj } from '@/helpers/translationsHelper'
import ExpandableView from '@/ui/ExpandableView.vue'

interface FormData {
  documents: ReviewsProductDocumentEditData[]
}

const acceptedFileTypes = ['application/pdf']
const acceptedThumbnailTypes = ['image/jpeg', 'image/png', 'image/webp', 'image/bmp']

const props = defineProps<{
  documents: NonNullable<ProductById['documents']>
}>()

const emit = defineEmits<{
  (e: 'change', value: ReviewsProductDocumentEditData[]): void
}>()

const MAX_NUMBER_OF_DOCUMENTS = 10

const { t, locale } = useI18n()
const documentsRef = ref<(typeof ExpandableView)[] | undefined>()
const fileInput = ref<HTMLInputElement | null>(null)
const urlsToRevoke = ref<string[]>([])

const formData = reactive<FormData>({
  documents: props.documents.map((document, index) => ({
    id: document.id ?? null,
    contentType: document.contentType,
    filename: document.filename,
    name: { ...mapTranslationsArrToObj(document.nameTranslations) },
    url: document.url,
    previewUrl: document.previewUrl || undefined,
    previewFilename: document.previewFilename || undefined,
    size: document.size,
    file: null,
    previewFile: null,
    updated: false,
    deleted: false,
    previewDeleted: false,
    position: document.position ?? index,
  })),
})
const v$ = useVuelidate({}, formData)

function isDocumentInvalid(index: number) {
  const results = v$.value.$getResultsForChild(`document-${index}`)
  return results ? results.$invalid : false
}

function collapseAllDocuments() {
  if (documentsRef.value) {
    documentsRef.value.forEach((ref) => ref.close())
  }
}

function animatePositionChange(index: number) {
  collapseAllDocuments()
  documentsRef.value?.[index].$el.classList.add('opacity-10')
  setTimeout(() => {
    formData.documents.sort((a, b) => a.position - b.position)
    documentsRef.value?.[index].$el.classList.remove('opacity-10')
  }, 300)
}

function openFilePicker() {
  fileInput.value?.click()
}

function onFilePicked(evt: Event) {
  const el = evt.target as HTMLInputElement
  if (el.files?.length) {
    const file = el.files[0]

    formData.documents.push({
      file,
      id: null,
      contentType: file.type,
      filename: file.name,
      name: ALLOWED_LOCALES.reduce(
        (acc, locale) => {
          acc[locale] = file.name
          return acc
        },
        {} as Record<string, string>,
      ),
      size: file.size,
      previewFile: null,
      updated: true,
      deleted: false,
      previewDeleted: false,
      position: formData.documents.length,
    })
    collapseAllDocuments()
  }

  if (fileInput.value) {
    fileInput.value.value = ''
  }
}

function deleteDoc(index: number) {
  if (formData.documents[index].id) {
    formData.documents[index]['deleted'] = true
  } else {
    formData.documents.splice(index, 1)
  }
}

function undoDeleteDoc(index: number) {
  if (formData.documents[index].id) {
    formData.documents[index]['deleted'] = false
  }
}

function moveUp(index: number) {
  if (index === 0) {
    return
  }
  formData.documents[index - 1].position = formData.documents[index - 1].position + 1
  formData.documents[index - 1].updated = true
  formData.documents[index].position = formData.documents[index].position - 1
  formData.documents[index].updated = true
  animatePositionChange(index - 1)
  animatePositionChange(index)
}

function moveDown(index: number) {
  if (index === formData.documents.length - 1) {
    return
  }
  formData.documents[index].position = formData.documents[index].position + 1
  formData.documents[index].updated = true
  formData.documents[index + 1].position = formData.documents[index + 1].position - 1
  formData.documents[index + 1].updated = true
  animatePositionChange(index)
  animatePositionChange(index + 1)
}

function documentThumbnailUrl(doc: ReviewsProductDocumentEditData) {
  let url = doc.previewUrl || undefined
  if (doc.previewFile) {
    url = URL.createObjectURL(doc.previewFile)
    urlsToRevoke.value.push(url)
  }
  return url
}

function downloadDocument(doc: ReviewsProductDocumentEditData) {
  if (doc.file) {
    const url = URL.createObjectURL(doc.file)
    urlsToRevoke.value.push(url)
    window.open(url, '_blank')
    return
  }
  if (doc.url) {
    window.open(doc.url, '_blank')
  }
}

onBeforeUnmount(() => {
  urlsToRevoke.value.forEach((url) => URL.revokeObjectURL(url))
})

watch(
  () => formData,
  () => {
    emit('change', formData.documents)
  },
  { deep: true },
)

watch(
  () => formData.documents.length,
  (newLength, oldLength) => {
    if (newLength > oldLength) {
      setTimeout(() => {
        if (!documentsRef.value) {
          return
        }
        const lastRef = documentsRef.value[documentsRef.value.length - 1]
        if (lastRef) {
          lastRef.open()
          lastRef.$el.scrollIntoView({ behavior: 'smooth' })
        }
      }, 50)
    }
  },
)
</script>
