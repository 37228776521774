<template>
  <div class="mt-4 rounded-lg border border-grey-300 bg-white p-6 dark:border-grey-700 dark:bg-grey-950">
    <div>
      <h2 class="text-2xl font-bold text-grey-950 dark:text-grey-50">{{ t('PartnerCompany.interests') }}</h2>
      <p class="mt-2">{{ t('PartnerCompany.interestsDescription') }}</p>
      <hr class="dark:bg-gray-700 my-4 h-px border-0 bg-grey-300" />
    </div>

    <div v-if="interests.length" class="mt-10 flex flex-wrap gap-4">
      <button
        v-for="interest in interests"
        :key="interest.id"
        class="text-body-s-bold shrink-0 rounded border px-3 py-[6px]"
        :class="
          isInterestSelected(interest) ? 'border-purple-200 bg-purple-100 text-purple-700' : 'border-grey-200 bg-grey-100 text-grey-700'
        "
        @click="toggleInterest(interest)"
      >
        {{ interest.name }}
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import type { InterestsQuery } from '@/gql/myomr'

type Interest = InterestsQuery['interests']['nodes'][number]

const MAX_INTERESTS = 4
const { t } = useI18n()

const model = defineModel<string[]>({
  default: () => [],
})

defineProps<{
  v$: object
  interests: NonNullable<Array<Interest>>
}>()

function isInterestSelected(interest: Interest) {
  return model.value.includes(interest.id)
}

function toggleInterest(interest: Interest) {
  const selected = isInterestSelected(interest)

  if (!selected && model.value.length < MAX_INTERESTS) {
    model.value = [...model.value, interest.id]
  } else if (selected) {
    model.value = model.value.filter((id) => id !== interest.id)
  }
}
</script>
