<template>
  <label class="inline-flex items-center gap-2">
    <input
      class="peer pointer-events-none absolute opacity-0"
      :checked="props.modelValue"
      :disabled="props.disabled"
      type="checkbox"
      @change="handleCheckboxChange"
    />
    <div class="checkbox" :class="props.size" />
    <span v-if="hasLabel" :disabled="props.disabled" class="label"><slot></slot></span>
  </label>
</template>

<script lang="ts">
export type CheckboxSizeEnum = 'small' | 'medium' | 'large'
</script>

<script lang="ts" setup>
import { computed, useSlots } from 'vue'

export interface Props {
  modelValue?: boolean
  disabled?: boolean
  size?: CheckboxSizeEnum
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  disabled: false,
  size: 'medium',
})

const slots = useSlots()

const hasLabel = computed(() => {
  return !!slots['default']
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
}>()

function handleCheckboxChange() {
  emit('update:modelValue', !props.modelValue)
}
</script>
<style scoped>
.checkbox {
  /* Prevent checkbox from shrinking */
  @apply shrink-0;
  /* Cursor */
  @apply cursor-pointer;
  /* Border */
  @apply relative rounded-sm border-2 border-inherit;
  /* Checkbox mark */
  @apply before:absolute before:top-0 before:z-10 before:block before:rotate-[314deg] before:border-b-2 before:border-l-2 before:border-inherit;
  /* Corner */
  @apply after:absolute after:right-[-2px] after:top-[-5px] after:block after:h-[12px] after:w-[6px] after:bg-white;

  /* Visibity */
  @apply before:opacity-0 after:opacity-0 peer-checked:before:opacity-100 peer-checked:after:opacity-100;

  /* Disabled state */
  @apply text-grey-800 peer-checked:text-black peer-disabled:pointer-events-none peer-disabled:cursor-default peer-disabled:opacity-40;
}

.label {
  /* Disabled state */
  @apply cursor-pointer text-grey-800 peer-checked:text-black peer-disabled:pointer-events-none peer-disabled:cursor-default peer-disabled:opacity-50;
}

.small {
  /* Border */
  @apply h-[14px] w-[14px];
  /* Checkbox mark */
  @apply before:left-[2px] before:h-[6px] before:w-[10px];
}

.medium {
  /* Border */
  @apply h-[18px] w-[18px];
  /* Checkbox mark */
  @apply before:left-[2.5px] before:h-[7.5px] before:w-[12px];
}

.large {
  /* Border */
  @apply h-[20px] w-[20px];
  /* Checkbox mark */
  @apply before:left-[3px] before:h-[8px] before:w-[14px];
}
</style>
