<template>
  <ConfirmModal persist @close-dialog="deleteProspectList">
    <template #title>
      <h1 class="text-headline-m flex items-center p-6 pb-0">
        {{ t('ProspectsCatalog.deleteListModal.title') }}
      </h1>
    </template>
    <template #content>
      <p class="px-6 pt-4">{{ t('ProspectsCatalog.deleteListModal.description') }}</p>
    </template>
    <template #actions>
      <div class="mt-6 flex justify-end px-6 pb-6">
        <button class="btn-secondary-purple-m uppercase" @click="deleteProspectList(false)">
          {{ t('cancel') }}
        </button>
        <button class="btn-primary-error-m ml-4" data-test-id="accept-button" @click="deleteProspectList(true)">
          {{ t('ProspectsCatalog.deleteListModal.deleteButton') }}
        </button>
      </div>
    </template>
  </ConfirmModal>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter, useRoute } from 'vue-router'

import { useDeleteProspectListMutation } from '@/gql/myomr'
import { toTypeAndId, toPlainId } from '@/helpers/globalIdHelper'
import { showNotification } from '@/helpers/notificationHelper'

import type { ProspectList } from './types'

const { t } = useI18n()
const router = useRouter()
const route = useRoute()

const props = defineProps<{
  prospectLists: ProspectList[]
  activeList: ProspectList
}>()

const emit = defineEmits<{
  (e: 'refetchProspectLists'): void
}>()

const { mutate: deleteProspectListMutation } = useDeleteProspectListMutation()

const activeListIndex = computed(() => {
  return props.prospectLists.findIndex((prospectList) => prospectList.id === props.activeList.id)
})

async function deleteProspectList(shouldDelete: boolean) {
  const activeListId = toTypeAndId(props.activeList.id).id
  if (!shouldDelete) return router.push({ name: 'editProspectList', params: { listId: activeListId as string } })

  try {
    await deleteProspectListMutation({
      prospectListId: props.activeList.id,
    })
    showNotification(t('ProspectsCatalog.deleteListModal.successNotification'), 'success', 3000)
    emit('refetchProspectLists')
    redirectToPreviousList()
  } catch (_e) {
    showNotification(t('ProspectsCatalog.deleteListModal.errorNotification'), 'success', 3000)
    router.push({ name: 'prospectList', params: { listId: route.params.listId } })
  }
}

async function redirectToPreviousList() {
  if (props.prospectLists.length === 1) router.push({ name: 'prospectList' })

  const nextListIndex = activeListIndex.value == 0 ? 1 : activeListIndex.value - 1
  const nextListId = toPlainId(props.prospectLists[nextListIndex].id)

  router.push({ name: 'prospectList', params: { listId: nextListId as string } })
}
</script>
