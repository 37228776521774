<template>
  <div>
    <div v-if="loading && !invitationQuota">
      <h2>Loading ...</h2>
      <ProgressBar />
    </div>
    <AlertBox v-else-if="$cannot('viewTickets', { for: 'User' })" type="warning" class="mt-5">
      {{ t('PartnerCompany.noPartnerCompany') }}
    </AlertBox>
    <div v-else>
      <h1>{{ title }}</h1>

      <div class="flex justify-between py-3">
        <BackLink :to="backLinkTarget">
          {{ t('InvitationQuota.back') }}
        </BackLink>
        <a v-if="hasGuideUrl" target="_blank" :href="guideUrl" class="inline-flex items-center text-black">
          <SvgIcon :path="mdiHelpCircle" class="mb-[2px] mr-2" :size="17" />
          <span>{{ t('EmailInvitationQuota.tutorialTitle') }}</span>
        </a>
      </div>
      <RouterView :invitation-quota="invitationQuota" :loading="loading" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { mdiHelpCircle } from '@mdi/js'
import { computed, provide } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { useInvitationQuotaQuery, InvitationQuotaTypeEnum } from '@/gql/myomr'
import { toGlobalId, toTypeAndId } from '@/helpers/globalIdHelper'
import { getLocalizedAttribute } from '@/helpers/localizedAttribute'
import { $cannot } from '@/services/roles'
import { reloadQuotaKey } from '@/symbols/reloadQuotaKey'

const i18n = useI18n()
const t = i18n.t
const route = useRoute()

const props = defineProps<{
  invitationQuotaId: string
}>()

const { result, loading, refetch } = useInvitationQuotaQuery({ id: toGlobalId('InvitationQuota', props.invitationQuotaId) })

const guideUrls = {
  [InvitationQuotaTypeEnum.ExhibitorQuota]: '/assets/files/guides/exhibitor_quota.pdf',
  [InvitationQuotaTypeEnum.CrewQuota]: '/assets/files/guides/crew-quota.pdf',
} as const
const hasGuideUrl = computed(
  () => invitationQuota.value != null && Object.prototype.hasOwnProperty.call(guideUrls, invitationQuota.value.kind),
)
const guideUrl = computed(() => (hasGuideUrl.value ? guideUrls[invitationQuota.value?.kind as keyof typeof guideUrls] : ''))

const invitationQuota = computed(() => result.value?.invitationQuota)

const backLinkTarget = computed(() => {
  if (route.name == 'invitationQuota') {
    return {
      name: 'invitations',
      params: { eventId: plainEventId.value },
    }
  } else if (route.name == 'invitationQuotaCsvMappingForm') {
    return { name: 'invitationQuotaCsvUpload' }
  } else {
    return { name: 'invitationQuota' }
  }
})

const title = computed(() => {
  if (!invitationQuota.value || invitationQuota.value.kind !== InvitationQuotaTypeEnum.CodeQuota) {
    return t('InvitationQuota.yourInvites')
  }

  // We have a CodeQuota
  if (invitationQuota.value.title) {
    return invitationQuota.value.title
  }

  const ticketCategory = invitationQuota.value.ticketCategory
  return getLocalizedAttribute({
    de: ticketCategory?.titleDe,
    en: ticketCategory?.titleEn,
    fallback: '',
    locale: i18n.locale.value,
  })
})

const plainEventId = computed(() => {
  return (invitationQuota.value?.event && toTypeAndId(invitationQuota.value.event.id)['id']) || ''
})

provide(reloadQuotaKey, refetch)
</script>
