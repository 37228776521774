<template>
  <ConfirmModal persist wrapper-class="max-w-2xl" @close-dialog="handleCloseDialog">
    <template #title>
      <h1 class="text-headline-m flex items-center p-6 pb-0">
        {{ t('InvitationQuota.CrewQuota.resendTicketModal.title') }}
      </h1>
    </template>
    <template #content>
      <p class="px-6 pt-4">
        {{ t('InvitationQuota.CrewQuota.resendTicketModal.description') }}
      </p>
    </template>
    <template #actions>
      <div class="mt-6 flex justify-end px-6 pb-6">
        <button class="btn-text-black-s font-bold uppercase" @click="handleCloseDialog(false)">
          {{ t('InvitationQuota.CrewQuota.resendTicketModal.cancelCta') }}
        </button>
        <button class="btn-primary-mint-m ml-4" data-test-id="accept-button" @click="handleCloseDialog(true)">
          {{ t('InvitationQuota.CrewQuota.resendTicketModal.approveCta') }}
        </button>
      </div>
    </template>
  </ConfirmModal>
</template>
<script lang="ts" setup>
import { captureException } from '@sentry/vue'
import { useI18n } from 'vue-i18n'

import { useResendTicketMailMutation } from '@/gql/myomr'
import { injectStrict } from '@/helpers/injectStrict'
import { showNotification } from '@/helpers/notificationHelper'
import { reloadTicketsKey } from '@/symbols/reloadTicketsKey'

const reloadTickets = injectStrict(reloadTicketsKey)

const { t } = useI18n()
const { mutate } = useResendTicketMailMutation()

const props = defineProps<{
  ticketId: string
}>()

const emit = defineEmits<{
  (e: 'closeDialog', value: boolean): void
}>()

async function handleCloseDialog(persist: boolean) {
  if (!persist) return emit('closeDialog', false)

  try {
    const response = await mutate({ ticketId: props.ticketId })
    if (!response?.data?.resendTicketMail?.errors.length) {
      showNotification(t('InvitationQuota.CrewQuota.resendTicketModal.successMessage'), 'success', 3000)
    } else {
      showNotification(t('InvitationQuota.CrewQuota.resendTicketModal.errorMessage'), 'error', 3000)
    }
  } catch (e) {
    console.error(e)
    captureException(e)
    showNotification(t('InvitationQuota.CrewQuota.resendTicketModal.errorMessage'), 'error', 3000)
  }
  reloadTickets()
  emit('closeDialog', true)
}
</script>
