<template>
  <ContentCard :loading="loading">
    <template #header>
      <ReviewsProductTabs />
    </template>
    <template #subheader>
      <ContentCardSubheader :back-to="{ name: ReviewsRouteName.ReviewsProductViewPreferences }">
        {{ t('Reviews.product.edit.preferencesTitle', { productName: product.title }) }}
        <template #actions>
          <RouterLink
            class="btn-secondary-purple-m mr-2"
            :to="{ name: ReviewsRouteName.ReviewsProductViewPreferences, params: { productId: product.id } }"
          >
            {{ t('Reviews.product.action.cancel') }}
          </RouterLink>
          <button class="btn-primary-purple-m" @click="onSubmit">{{ t('Reviews.product.action.save') }}</button>
        </template>
      </ContentCardSubheader>
    </template>

    <div class="flex flex-col gap-y-4 pb-10 pt-6">
      <div class="flex items-start pb-6 text-grey-800">
        <MdiSvg class="mr-2 inline-block" :path="mdiInformationOutline" />
        {{ t('Reviews.product.edit.preferences.hint') }}
      </div>
      <PreferenceItemEdit
        v-model="form.vendorEmailAddresses"
        :description="t('Reviews.product.view.preferences.vendorEmails.description')"
        :title="t('Reviews.product.view.preferences.vendorEmails.title')"
      />
      <PreferenceItemEdit
        v-model="form.contactFormRecipients"
        :description="t('Reviews.product.view.preferences.contactEmails.description')"
        :title="t('Reviews.product.view.preferences.contactEmails.title')"
      />
      <PreferenceItemEdit
        v-model="form.pageVisitReportEmails"
        :description="t('Reviews.product.view.preferences.pageVisitReportEmails.description')"
        :title="t('Reviews.product.view.preferences.pageVisitReportEmails.title')"
      />
    </div>
  </ContentCard>
</template>

<script setup lang="ts">
import { mdiInformationOutline } from '@mdi/js'
import { MdiSvg } from '@ramp106/omrjs-core-ui'
import { inject, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import PreferenceItemEdit from '@/components/ReviewsManagement/Preferences/PreferenceItemEdit.vue'
import ReviewsProductTabs from '@/components/ReviewsManagement/ReviewsProductTabs.vue'
import { type ProductData, useUpdateReviewsProductMutation } from '@/gql/reviews'
import { showNotification } from '@/helpers/notificationHelper.ts'
import { ReviewsRouteName } from '@/router/types.ts'
import { reloadReviewsProductKey } from '@/symbols/reloadReviewsProductKey.ts'
import ContentCard from '@/ui/ContentCard.vue'
import ContentCardSubheader from '@/ui/ContentCardSubheader.vue'

const props = defineProps<{
  product: ProductData
}>()

const { t } = useI18n()
const router = useRouter()
const reloadReviewsProduct = inject(reloadReviewsProductKey) as () => void

const {
  mutate: mutateProduct,
  loading,
  onDone,
  onError,
} = useUpdateReviewsProductMutation({
  clientId: 'reviews',
})

const form = reactive({
  vendorEmailAddresses: props.product.vendorEmailAddresses ? [...props.product.vendorEmailAddresses] : [],
  contactFormRecipients: props.product.contactFormRecipients ? [...props.product.contactFormRecipients] : [],
  pageVisitReportEmails: props.product.pageVisitReportEmails ? [...props.product.pageVisitReportEmails] : [],
})

async function onSubmit() {
  await mutateProduct({
    id: props.product.id,
    attributes: {
      vendorEmailAddresses: form.vendorEmailAddresses,
      contactFormRecipients: form.contactFormRecipients,
      pageVisitReportEmails: form.pageVisitReportEmails,
    },
  })
}

onDone(() => {
  reloadReviewsProduct()
  showNotification(t('Reviews.product.notifications.savedSuccessfully', { name: props.product.title }), 'success')
  router.push({ name: ReviewsRouteName.ReviewsProductViewPreferences, params: { productId: props.product.id } })
})

onError((error) => showNotification(error.message, 'error'))
</script>
