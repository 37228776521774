<template>
  <tr>
    <td v-if="!isSelectionClosed" class="border-b border-grey-300 pr-2">
      <div class="flex items-center pl-2">
        <CheckBox :model-value="checked" class="text-grey-800" @update:model-value="adjustSelection" />
      </div>
    </td>
    <td class="border-b border-grey-300 py-3 pr-2">
      {{ attendee.ticket?.companyName }}
    </td>
    <td class="border-b border-grey-300 py-3 pr-2">
      {{ attendee.addedViaManage ? attendee.ticket?.identifier : '' }}
    </td>
    <td class="border-b border-grey-300 py-3 pr-2">
      {{ attendee.ticket?.position ? attendee.ticket.position : 'Student' }}
    </td>
    <td class="border-b border-grey-300 py-3 pl-2">
      <div :class="statusColor">
        {{ statusLabel }}
      </div>
    </td>
  </tr>
</template>

<script lang="ts">
export default { name: 'EventApplicationTableRow' }
</script>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import type { Attendee } from '@/gql/myomr'

import { getStatusColor, getStatusLabel } from './statusHelper.tailwind'

const { t } = useI18n()

const props = defineProps<{
  attendee: Attendee
  isSelectionClosed: boolean
  selectedAttendeeIds: string[]
}>()

const emit = defineEmits<{
  (e: 'select', value: string): void
  (e: 'unselect', value: string): void
}>()

const checked = computed(() => props.selectedAttendeeIds.includes(props.attendee.id))

function adjustSelection(newValue: boolean) {
  if (newValue) {
    emit('select', props.attendee.id)
  } else {
    emit('unselect', props.attendee.id)
  }
}

const statusLabel = computed(() => {
  return t(getStatusLabel(props.attendee.aasmState, props.attendee.forcedAllocation))
})

const statusColor = computed(() => {
  return getStatusColor(props.attendee.aasmState, props.attendee.forcedAllocation)
})
</script>
