<template>
  <div>
    <h1 class="text-headline-l mb-10">{{ t('Speakers.speakers') }}</h1>
    <div class="mb-14 grid grid-cols-2 gap-5">
      <p>
        {{ t('Speakers.speakersInstruction') }}
      </p>
      <div>
        <SpeakerSearch />
      </div>
    </div>
    <div class="my-5">
      <h1 class="text-headline-m mb-4">{{ t('Speakers.mySpeakers') }}</h1>
      <p class="mb-6">
        {{ t('Speakers.speakersHeader') }}
      </p>
    </div>
    <div class="grid grid-cols-2 gap-8">
      <SpeakerListItem v-for="speaker in props.speakers" :key="speaker.id" :speaker="speaker" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

import type { Speaker } from '@/gql/myomr'

const { t } = useI18n()

const props = defineProps<{
  speakers: Speaker[]
  loading: boolean
}>()

const emit = defineEmits(['reloadSpeakers'])

onMounted(() => emit('reloadSpeakers'))
</script>
