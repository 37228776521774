import { ProfileScopeEnum } from '@/gql/myomr'

const hostname = (window.location && window.location.hostname) || ''
let env: 'environment' | 'staging' | 'production'
switch (hostname) {
  case hostname.match(/\.omr\.com$/)?.input:
    env = 'production'
    break
  case hostname.match(/\.omr\.ninja$/)?.input:
    env = 'staging'
    break
  default:
    env = 'environment'
    break
}

export default env

const ticketShopBaseUrls = {
  environment: import.meta.env.VUE_APP_TICKET_SHOP_BASE_URL || 'https://tickets.omr.io',
  staging: 'https://tickets.omr.ninja',
  production: 'https://tickets.omr.com',
}

const myOmrGraphqlUrls = {
  environment: import.meta.env.VUE_APP_MYOMR_GRAPHQL_URL || 'https://my.omr.io/graphql',
  staging: 'https://my.omr.ninja/graphql',
  production: 'https://my.omr.com/graphql',
}

const reviewsGraphqlUrls = {
  environment: import.meta.env.VUE_APP_REVIEWS_GRAPHQL_URL || 'https://api.reviews.omr.io/graphql',
  staging: 'https://api.reviews.omr.ninja/graphql',
  production: 'https://api.reviews.omr.com/graphql',
}

const hygraphGraphqlUrls = {
  environment: import.meta.env.VUE_APP_HYGRAPH_GRAPHQL_URL || 'https://api-eu-central-1.hygraph.com/v2/ckttznct701xy01xzb2e68lp1/staging',
  staging: 'https://api-eu-central-1.hygraph.com/v2/ckttznct701xy01xzb2e68lp1/staging',
  production: 'https://eu-central-1.cdn.hygraph.com/content/ckttznct701xy01xzb2e68lp1/master',
}

const buyerIntentDataUrls = {
  environment: import.meta.env.VUE_APP_BUYER_INTENT_DATA_URL || 'https://api.reviews.omr.io/redirect_to_omrviewer',
  staging: 'https://api.reviews.omr.ninja/redirect_to_omrviewer',
  production: 'https://api.reviews.omr.com/redirect_to_omrviewer',
}

const ticketShopBaseUrl = ticketShopBaseUrls[env]
const myOmrGraphqlUrl = myOmrGraphqlUrls[env]
const reviewsGraphqlUrl = reviewsGraphqlUrls[env]
const hygraphGraphqlUrl = hygraphGraphqlUrls[env]
const buyerIntentDataUrl = buyerIntentDataUrls[env]

// Keep this here until we have the new Leads design that is supposed to not rely on the current event being set:
const currentEventIdentifier = 'omr24'

const authorities = {
  environment: import.meta.env.VUE_APP_OIDC_AUTHORITY || 'https://my.omr.io/',
  staging: 'https://my.omr.ninja/',
  production: 'https://my.omr.com/',
}

const authority = authorities[env]

const requiredProfileScopes = [ProfileScopeEnum.Basic, ProfileScopeEnum.Detailed, ProfileScopeEnum.NetworkingTerms]

function loginUrl(locale: string, redirectUrl?: string) {
  const url = new URL(`${authority}${locale}/users/sign_in`)
  url.searchParams.append('redirect_to', redirectUrl || window.location.href)
  url.searchParams.append('profile_scopes', requiredProfileScopes.join(' '))

  return url.href
}

const logoutUrl = (locale: string) => `${authority}${locale}/users/sign_out`

const baseOmrUrls = {
  environment: 'https://omr.io',
  staging: 'https://omr.ninja',
  production: 'https://omr.com',
}

const baseOmrUrl = baseOmrUrls[env]

export {
  authority,
  currentEventIdentifier,
  loginUrl,
  logoutUrl,
  myOmrGraphqlUrl,
  hygraphGraphqlUrl,
  requiredProfileScopes,
  reviewsGraphqlUrl,
  ticketShopBaseUrl,
  buyerIntentDataUrl,
  baseOmrUrl,
}
