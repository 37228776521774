<template>
  <div v-omr-click-outside="hideDropDown" class="inline-flex flex-col">
    <slot name="activator" :open="handleActivatorClick" :show-drop-down="showDropDown"></slot>

    <div class="relative">
      <div
        class="absolute z-40 rounded bg-white shadow"
        :class="[
          innerClass,
          {
            'pointer-events-none z-0 hidden': !showDropDown,
            'top-1': position === 'top',
            'bottom-0 -translate-y-1/3': position === 'bottom',
            'left-0': direction === 'left',
            'right-0': direction === 'right',
            'left-1/2 -translate-x-1/2': direction === 'middle',
          },
        ]"
      >
        <slot :close="hideDropDown"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default { name: 'DropDownSelect' }
</script>

<script setup lang="ts">
import { ref } from 'vue'

import { ClickOutside } from '@/directives/clickOutside'

withDefaults(
  defineProps<{
    direction?: 'left' | 'right' | 'middle'
    position?: 'top' | 'bottom'
    innerClass?: string
  }>(),
  {
    direction: 'left',
    position: 'top',
    innerClass: '',
  },
)

const showDropDown = ref(false)

const vOmrClickOutside = ClickOutside

const handleActivatorClick = () => {
  showDropDown.value = !showDropDown.value
}

const hideDropDown = () => {
  showDropDown.value = false
}
</script>
