<template>
  <div>
    <AlertBox v-if="!canManageReviews" type="warning" class="mt-5">
      {{ t('PartnerCompany.noPartnerCompany') }}
    </AlertBox>
    <RouterView
      v-if="canManageReviews"
      :agencies="result?.manage?.agencies || []"
      :me="props.me"
      :loading="loading"
      @reload-agencies="refetch"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { type MeQuery } from '@/gql/myomr'
import { useReviewsAgenciesQuery } from '@/gql/reviews'
import { useRoleRedirect } from '@/services/roleRedirect'
import { $can } from '@/services/roles'

useRoleRedirect('manageReviews')

const { t } = useI18n()

const canManageReviews = computed(() => {
  return $can('manageReviews', { for: 'User' })
})

const props = defineProps<{
  me: MeQuery['me']
}>()

const { loading, refetch, result } = useReviewsAgenciesQuery({ clientId: 'reviews' })
</script>
