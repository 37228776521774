<template>
  <ContentCardSubheader :back-to="{ name: ReviewsRouteName.ReviewsProducts }">
    {{ t('Reviews.product.view.mediaTitle', { productName: product.title }) }}
  </ContentCardSubheader>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import type { ProductById } from '@/gql/reviews'
import { ReviewsRouteName } from '@/router/types'
import ContentCardSubheader from '@/ui/ContentCardSubheader.vue'

defineProps<{
  product: Pick<ProductById, 'id' | 'title'>
}>()

const { t } = useI18n()
</script>
