<template>
  <div>
    <div class="text-body-s-bold grid grid-cols-12 gap-x-1 border-b border-grey-700 py-2">
      <div class="col-span-3">{{ t('Reviews.product.view.pricingPlans.table.pricingPlan') }}</div>
      <div class="col-span-4">{{ t('Reviews.product.view.pricingPlans.table.priceInformation') }}</div>
      <div class="col-span-5">{{ t('Reviews.product.view.pricingPlans.table.description') }}</div>
    </div>
    <div v-for="(pricingPlan, index) in pricingPlans" :key="index" class="text-body-m grid grid-cols-12 border-b border-grey-700 py-2">
      <div class="long-text-column col-span-3">{{ pricingPlan.name }}</div>
      <div class="long-text-column col-span-4">
        <span v-if="pricingPlan.priceTextValue">{{ pricingPlan.priceTextValue || '-' }}</span>
        <div v-else>{{ getPricingMoneyValue(pricingPlan) }}</div>
      </div>
      <div class="long-text-column col-span-5">
        {{ pricingPlan.description }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import type { ProductPricingPlanFragment } from '@/gql/reviews'

defineProps<{
  pricingPlans: ProductPricingPlanFragment[]
}>()

const { t, locale } = useI18n()

function getPricingMoneyValue(pricingPlan: ProductPricingPlanFragment) {
  if (typeof pricingPlan.priceCents === 'number') {
    const price = (pricingPlan.priceCents / 100).toLocaleString(locale.value === 'de' ? 'de-DE' : 'en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })

    return [
      pricingPlan.startingPrice ? `${t('Reviews.product.view.pricing.startingAt')} ` : '',
      price,
      pricingPlan.priceCurrencySymbol,
      pricingPlan.billingPeriod ? pricingPlan.billingPeriod : '',
    ].join(' ')
  }
  return '-'
}
</script>

<style scoped>
.long-text-column {
  @apply overflow-x-hidden overflow-ellipsis whitespace-nowrap;
}
</style>
