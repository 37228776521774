<template>
  <ConfirmModal :title="t('attendee.successfullyAddedModal.title')" persist :hide-actions="true" @close-dialog="emit('closeDialog', false)">
    <template #content>
      <div class="w-[650px] px-6">
        <AlertBox v-if="!failedTickets.length" type="success">
          <template #header>{{ t('attendee.successfullyAddedModal.successTitle') }}</template>
          <div>{{ t('attendee.successfullyAddedModal.successDescription', { number: attendeesAdded.length }) }}</div>
        </AlertBox>
        <AlertBox v-else type="warning">
          <template #header>{{ t('attendee.successfullyAddedModal.errorTitle') }}</template>
          <div v-if="notFoundTickets.length">
            {{ t('attendee.successfullyAddedModal.ticketsNotFoundDescription', { number: notFoundTickets.length }) }}
          </div>
          <div v-if="takenTickets.length">
            {{ t('attendee.successfullyAddedModal.ticketsTakenDescription', { number: notFoundTickets.length }) }}
          </div>
        </AlertBox>
        <TableLight class="mt-8 w-full table-auto">
          <tbody>
            <tr v-for="ticket in paginatedData" :key="ticket.ticketIdentifier">
              <td class="py-2">{{ ticket.ticketIdentifier }}</td>
              <td v-if="ticket.success">{{ ticket.success ? ticket.companyName || '' : '' }}</td>
              <td v-if="ticket.success">{{ ticket.position }}</td>
              <td v-else colspan="2">{{ getErrorTitle(ticket.errorMessage || '') }}</td>
              <td class="text-right">
                <SvgIcon v-if="ticket.success" class="-mb-[5px] text-tailwind-500" :size="20" :path="mdiCheckCircle" />
                <SvgIcon v-else class="-mb-[5px] text-amaranth-600" :size="20" :path="mdiAlertOctagon" />
              </td>
            </tr>
          </tbody>
        </TableLight>
        <PaginationControl
          v-if="showPagination"
          class="my-4"
          :page="currentPage"
          :show-items-per-page="false"
          :per-page="10"
          :total="attendeesAdded.length"
          :show-range="false"
          :show-total="false"
          @update:page="setPage"
        />
      </div>
      <div class="mt-6 flex justify-end px-6 pb-6">
        <button class="btn-secondary-purple-m" data-test-id="accept-button" @click="emit('closeDialog', true)">
          {{ t('attendee.successfullyAddedModal.addMoreButton') }}
        </button>
        <button class="btn-primary-purple-m ml-4 uppercase" @click="emit('closeDialog', false)">
          {{ t('attendee.successfullyAddedModal.closeButton') }}
        </button>
      </div>
    </template>
  </ConfirmModal>
</template>
<script setup lang="ts">
import { mdiAlertOctagon, mdiCheckCircle } from '@mdi/js'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import type { AddAttendeesByTicketIdentifierMutation } from '@/gql/myomr'
import { TableLight } from '@/ui/TableLight'

const TICKET_NOT_FOUND_MESSAGE = 'Ticket not found'
const TICKET_TAKEN_MESSAGE = 'Ticket has already been taken'

const { t } = useI18n()

const currentPage = ref(1)

const showPagination = computed(() => {
  return props.attendeesAdded.length > 10
})

type AddedAttendeesType = NonNullable<NonNullable<AddAttendeesByTicketIdentifierMutation['addAttendeesByTicketIdentifier']>['results']>

const emit = defineEmits<{
  (e: 'closeDialog', value: boolean): void
}>()

function setPage(page: number) {
  currentPage.value = page
}

function getErrorTitle(errorText: string) {
  if (errorText === TICKET_TAKEN_MESSAGE) return t('attendee.successfullyAddedModal.ticketTaken')
  return t('attendee.successfullyAddedModal.ticketNotFound')
}

const paginatedData = computed(() => {
  return props.attendeesAdded.slice((currentPage.value - 1) * 10, currentPage.value * 10)
})

const failedTickets = computed(() => {
  return props.attendeesAdded.filter((ticket) => !ticket.success)
})

const notFoundTickets = computed(() => {
  return props.attendeesAdded.filter((ticket) => !ticket.success && ticket.errorMessage === TICKET_NOT_FOUND_MESSAGE)
})

const takenTickets = computed(() => {
  return props.attendeesAdded.filter((ticket) => !ticket.success && ticket.errorMessage === TICKET_TAKEN_MESSAGE)
})

const props = defineProps<{
  attendeesAdded: AddedAttendeesType
}>()
</script>
