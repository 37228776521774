<template>
  <div>
    <div class="pb-5 pt-2">
      <h1>{{ t('Speakers.createSpeaker') }}</h1>

      <BackLink class="mt-4" :to="{ name: 'speakerList' }">{{ t('backToOverview') }}</BackLink>
    </div>

    <SpeakerForm :speaker="initialFormData" :is-uploading="loading" :upload-progress="uploadProgress" @submit-form="save">
      <template #navHeader>
        <TabBar>
          <NavTabBarItem :is-selected="route.name === 'createSpeaker'" :to="{ name: 'createSpeaker' }">
            {{ t('Speaker.editSpeaker') }}
          </NavTabBarItem>
          <NavTabBarItem disabled :is-selected="route.name === 'editSpeakerEvents'" :to="{ name: 'editSpeakerEvents' }">
            {{ t('Speaker.editSpeakerEvents') }}
          </NavTabBarItem>
        </TabBar>
        <ProgressBar v-if="loading" color="primary" />
      </template>
    </SpeakerForm>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

import { useCreateSpeakerMutation } from '@/gql/myomr'
import type { MeQuery, SpeakerAttributes } from '@/gql/myomr'
import { toTypeAndId } from '@/helpers/globalIdHelper'
import { showNotification } from '@/helpers/notificationHelper'
import BackLink from '@/ui/BackLink.vue'

import getSpeakerConfig from './getSpeakerConfig'
import SpeakerForm from './SpeakerForm.vue'

const { t } = useI18n()
const router = useRouter()
const route = useRoute()

const props = defineProps<{
  me: NonNullable<MeQuery['me']>
}>()

const { mutate: createSpeaker, loading } = useCreateSpeakerMutation()

const uploadProgress = ref(0)

const initialFormData = {
  id: '',
  name: '',
  firstname: '',
  lastname: '',
  descriptionEn: '',
  descriptionDe: '',
  title: '',
  email: '',
  partnerCompanyName: '',
  interests: [],
  photoUrl: '',
  twitterUrl: '',
  facebookUrl: '',
  instagramUrl: '',
  linkedinUrl: '',
  youtubeUrl: '',
  websiteUrl: '',
  eventSpeakers: [],
  createdAt: '',
}

async function save(speaker: SpeakerAttributes) {
  if (!props.me.partnerCompany) {
    window.scrollTo(0, 0)
    showNotification(t('Speakers.notifications.noCompanyError').toString(), 'error')
    return
  }

  createSpeaker(
    {
      ...speaker,
      name: `${speaker.firstname} ${speaker.lastname}`,
      partnerCompanyId: props.me.partnerCompany.id,
    },
    {
      context: {
        fetchOptions: {
          useUpload: true,
          onProgress: (progress: ProgressEvent) => {
            const loadedProgress = Math.round((progress.loaded / progress.total) * 100)
            uploadProgress.value = loadedProgress
          },
        },
      },
    },
  ).then((result) => {
    window.scrollTo(0, 0)
    if (!result?.data?.createSpeaker?.errors.length) {
      showNotification(t('Speakers.notifications.speakerCreated').toString(), 'success')
      const speakerGlobalId = result?.data?.createSpeaker?.speaker?.id
      if (speakerGlobalId) {
        const speakerId = toTypeAndId(speakerGlobalId)['id']
        if (speakerId && getSpeakerConfig['enableEventAssigning']) router.push({ name: 'editSpeakerEvents', params: { speakerId } })
        if (speakerId && !getSpeakerConfig['enableEventAssigning']) router.push({ name: 'speakerList' })
      }
    } else {
      showNotification(t('Speakers.notifications.serverIssue').toString(), 'error')
    }
  })
}
</script>
