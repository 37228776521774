<template>
  <div class="responsive-container relative pt-2">
    <div class="fixed left-0 top-0 z-10 flex w-full bg-white pb-5 pt-5 transition" :class="{ 'drop-shadow-md': hasScrolled }">
      <div class="hidden w-64 flex-none px-4 laptop:block">
        <!-- I simulate a navbar in a fixed container to keep the ratios -->
      </div>
      <div class="flex-1">
        <div class="responsive-container max-w-[1072px] px-0">
          <div class="grid grid-cols-8">
            <div class="col-span-8 text-center tablet:col-span-5 tablet:text-left">
              <h1 class="text-headline-l mb-4 tablet:mb-0">{{ t('PartnerCompany.headlineTop') }}</h1>
            </div>
            <div class="col-span-8 text-center tablet:col-span-3 tablet:text-right">
              <button class="btn-primary-purple-m min-w-28" @click="updatePartnerCompany()">
                <template v-if="!isFormSaving">{{ t('saveButtonLabel') }}</template>
                <template v-else>
                  <CircleSpinner size="small" />
                </template>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-12 grid grid-cols-8">
    <div class="col-span-8 laptop:col-span-5">
      <p class="text-body-m mt-24 laptop:mt-4">
        {{ t('PartnerCompany.companyProfileDescription') }}
      </p>
    </div>
  </div>

  <div class="mt-4 grid grid-cols-8 gap-4">
    <div class="col-span-8 laptop:col-span-5">
      <GeneralInfoForm v-if="partnerCompany" v-model="form.generalInfo" :v$="v$" />

      <CategoriesForm v-if="interests" v-model="form.interests" :v$="v$" :interests="interests" />

      <SalesContactForm v-model="salesContactForm" :v$="salesV$" />
    </div>

    <div class="order-first col-span-8 laptop:order-last laptop:col-span-3">
      <CompanyImagesForm v-model="form.logos" :logo-url="partnerCompany.logoUrl" :web-logo-url="partnerCompany.webLogoUrl" :v$="v$" />

      <div class="mt-4 rounded-lg border border-grey-300 bg-white p-6 dark:border-grey-700 dark:bg-grey-950">
        <h2 class="text-headline-m mb-4">{{ t('PartnerCompany.socialMediaLinks') }}</h2>
        <div v-for="{ name, iconPath } in socialMediaLinks" :key="name" class="flex items-center">
          <TextInput
            v-model="form.socialMediaLinks[name]"
            :name="name"
            type="url"
            :label="t(`PartnerCompany.${name}`)"
            :append-icon="iconPath"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { mdiYoutube, mdiWeb, mdiInstagram, mdiLinkedin } from '@mdi/js'
import { TextInput, CircleSpinner, iconTwitterX } from '@ramp106/omrjs-core-ui'
import { useVuelidate } from '@vuelidate/core'
import { required, maxLength } from '@vuelidate/validators'
import { ref, computed, onMounted, onUnmounted, reactive } from 'vue'
import { useI18n } from 'vue-i18n'

import type { Contact, PartnerCompany, UpdatePartnerCompanyMutationVariables } from '@/gql/myomr'
import { useUpdateContactMutation, useCreateContactMutation, useInterestsQuery, useUpdatePartnerCompanyMutation } from '@/gql/myomr'
import { showNotification } from '@/helpers/notificationHelper'
import { imageMaxDimensionRule, imageMinDimensionRule, validPhoneNumberRule } from '@/helpers/validationHelper'
import { requiredUnless, url, email } from '@/services/validators'

// Config
const MIN_WEB_LOGO_HEIGHT = 30
const MIN_WEB_LOGO_WIDTH = 200
const MAX_WEB_LOGO_HEIGHT = 1200
const MAX_WEB_LOGO_WIDTH = 1200
const MAX_DESCRIPTION_LENGTH = 750
const socialMediaLinks = [
  { name: 'website', label: 'Website', iconPath: mdiWeb },
  { name: 'linkedinUrl', label: 'LinkedIn', iconPath: mdiLinkedin },
  { name: 'instagram', label: 'Instagram', iconPath: mdiInstagram },
  { name: 'twitter', label: 'X (Twitter)', iconPath: iconTwitterX },
  { name: 'youtube', label: 'Youtube', iconPath: mdiYoutube },
] as const
const { t } = useI18n()

// Props
const props = defineProps<{
  partnerCompany: PartnerCompany
  contactForAppointments: Contact | null | undefined
}>()

// Queries
const { result: interestsResult } = useInterestsQuery()

// Refs
const hasScrolled = ref(false)
const isFormSaving = ref(false)
const uploadProgress = ref(0)

// Computed properties
const interests = computed(() => interestsResult.value?.interests.nodes)
const companyHasWebLogo = computed<boolean>(() => props.partnerCompany.webLogoUrl != null)

// Mutation definitions
const { mutate: mutatePartnerCompany } = useUpdatePartnerCompanyMutation()
const { mutate: updateContact } = useUpdateContactMutation()
const { mutate: mutateNewContact } = useCreateContactMutation()

// Forms
const form = reactive({
  generalInfo: {
    displayName: props.partnerCompany.displayName ?? '',
    descriptionEn: props.partnerCompany.descriptionEn ?? '',
    descriptionDe: props.partnerCompany.descriptionDe ?? '',
  },
  interests: props.partnerCompany.interests?.map((interest) => interest.id) || [],
  logos: {
    logo: undefined,
    webLogo: undefined,
  },
  socialMediaLinks: {
    website: props.partnerCompany.website || '',
    twitter: props.partnerCompany.twitter || '',
    facebook: props.partnerCompany.facebook || '',
    instagram: props.partnerCompany.instagram || '',
    linkedinUrl: props.partnerCompany.linkedinUrl || '',
    youtube: props.partnerCompany.youtube || '',
  },
})

const rules = {
  generalInfo: {
    displayName: { required },
    descriptionEn: { required, maxLength: maxLength(MAX_DESCRIPTION_LENGTH) },
    descriptionDe: { required, maxLength: maxLength(MAX_DESCRIPTION_LENGTH) },
  },
  socialMediaLinks: {
    website: { url },
    twitter: { url },
    facebook: { url },
    instagram: { url },
    linkedinUrl: { url },
  },
  interests: [],
  logos: {
    logo: {},
    webLogo: {
      requiredIf: requiredUnless(companyHasWebLogo),
      imageMinDimension: imageMinDimensionRule(t, { minWidth: MIN_WEB_LOGO_WIDTH, minHeight: MIN_WEB_LOGO_HEIGHT }),
      imageMaxDimension: imageMaxDimensionRule(t, { maxWidth: MAX_WEB_LOGO_WIDTH, maxHeight: MAX_WEB_LOGO_HEIGHT }),
    },
  },
}
const v$ = useVuelidate(rules, form)

const salesContactForm = reactive({
  firstname: props.contactForAppointments?.firstname ?? '',
  name: props.contactForAppointments?.name ?? '',
  title: props.contactForAppointments?.title ?? '',
  email: props.contactForAppointments?.email ?? '',
  phone: props.contactForAppointments?.phone ?? '',
})
const salesContactRules = {
  phone: { validPhoneNumber: validPhoneNumberRule(t) },
  email: { required, email },
}
const salesV$ = useVuelidate(salesContactRules, salesContactForm)

// Methods
async function updatePartnerCompany() {
  const isFormValid = await v$.value.$validate()
  const isSalesFormValid = await salesV$.value.$validate()

  if (!isFormValid || !isSalesFormValid) {
    showNotification(
      t('BoothExtras.validationError')
        .toString()
        .replace(/<\/?[^>]+>/gi, ' '),
      'error',
    )
    return
  }

  isFormSaving.value = true

  const mutationData: UpdatePartnerCompanyMutationVariables = {
    ...form.generalInfo,
    ...form.logos,
    ...form.socialMediaLinks,
    interests: form.interests,
    partnerCompanyId: props.partnerCompany.id,
  }

  try {
    if (props.contactForAppointments) {
      await updateContact({ ...salesContactForm, id: props.contactForAppointments.id })
    } else {
      await mutateNewContact({ ...salesContactForm, partnerCompanyId: props.partnerCompany.id })
    }

    await mutatePartnerCompany(mutationData, {
      context: {
        fetchOptions: {
          useUpload: true,
          onProgress: (progress: ProgressEvent) => {
            const loadedProgress = Math.round((progress.loaded / progress.total) * 100)
            uploadProgress.value = loadedProgress
          },
        },
      },
    })

    window.scrollTo(0, 0)
    showNotification(t('PartnerCompany.partnerCompanyUpdated'), 'success')
    isFormSaving.value = false
    uploadProgress.value = 0
  } catch (_e) {
    showNotification(t('EventForm.failedUpdateNotification'), 'error')
  } finally {
    Object.assign(form.logos, {
      logo: undefined,
      webLogo: undefined,
    })
  }
}

const handleScroll = () => {
  hasScrolled.value = window.scrollY > 0
}

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
  handleScroll()
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>
