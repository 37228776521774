<template>
  <div>
    <div class="text-label-m -mt-4 text-grey-600">
      {{
        t('Reviews.product.edit.screenshots.uploadHint', {
          maxNumberOfFiles: MAX_NUMBER_OF_SCREENSHOTS,
        })
      }}
    </div>
    <div class="text-label-m my-3 flex items-center text-grey-900">
      <MdiSvg :path="mdiInformationOutline" :size="24" class="mr-2" />
      {{ t('Reviews.product.edit.screenshots.sortHint') }}
    </div>
    <div class="grid grid-cols-3 gap-6">
      <div
        v-for="(screenshot, index) in editableScreenshots"
        :key="index"
        class="relative"
        :draggable="screenshot.deleted ? 'false' : 'true'"
        @dragstart="onDragStart($event, index)"
        @dragover.prevent
        @drop="onDrop(index)"
      >
        <AssetPreview
          :class="{ 'opacity-20': screenshot.deleted }"
          :content-type="screenshot.contentType || ''"
          :url="screenshot.url || ''"
        />
        <div class="flex items-center justify-between">
          <button
            class="btn-text-black-s my-4 flex items-center"
            :disabled="screenshot.deleted && nonDeletedScreenshots.length >= MAX_NUMBER_OF_SCREENSHOTS"
            :data-testid="`delete-screenshot-${index}`"
            @click="toggleDeleteScreenshot(screenshot)"
          >
            <MdiSvg :path="screenshot.deleted ? mdiArrowULeftTop : mdiDelete" :size="18" class="mr-2" />
            {{ screenshot.deleted ? t('Reviews.product.edit.screenshots.restore') : t('Reviews.product.edit.screenshots.delete') }}
          </button>
          <div v-if="screenshot.deleted || !screenshot.id" class="text-label-m-bold pr-0.5 text-grey-600">
            {{ screenshot.deleted ? t('Reviews.product.edit.screenshots.deletedFile') : t('Reviews.product.edit.screenshots.newFile') }}
          </div>
        </div>
      </div>

      <MediaUpload
        v-if="nonDeletedScreenshots.length < MAX_NUMBER_OF_SCREENSHOTS"
        ref="fileInput"
        class="w-full"
        :accept="''"
        aspect-ratio="16:9"
        :error="getValidationErrors(v$.screenshot)"
        :hide-preview="true"
        :short-hint="uploaderHints"
        data-testid="screenshot-upload"
        @upload="handleScreenshotUpload"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { mdiDelete, mdiInformationOutline, mdiArrowULeftTop } from '@mdi/js'
import { MdiSvg } from '@ramp106/omrjs-core-ui'
import { useVuelidate } from '@vuelidate/core'
import { onMounted, computed, ref, watch, nextTick } from 'vue'
import { useI18n } from 'vue-i18n'

import type { ReviewsProductScreenshotEditData } from '@/components/ReviewsManagement/types'
import type { ProductById } from '@/gql/reviews'
import { showNotification } from '@/helpers/notificationHelper'
import { fileMaxSizeRule, fileMimeTypeRule, getValidationErrors } from '@/helpers/validationHelper'
import AssetPreview from '@/ui/AssetPreview.vue'
import MediaUpload from '@/ui/MediaUpload.vue'

const props = defineProps<{
  screenshots: NonNullable<ProductById['screenshots']>
}>()

const emit = defineEmits<{
  (e: 'change', value: ReviewsProductScreenshotEditData[]): void
}>()

const MAX_NUMBER_OF_SCREENSHOTS = 10
const MAX_SCREENSHOT_MB_SIZE = 50

const { t } = useI18n()
const newScreenshot = ref<File | null>(null)
const fileInput = ref()

const screenshotMimeTypes = ['image/jpeg', 'image/png', 'image/webp', 'image/gif', 'video/mp4', 'video/webm']
const screenshotAcceptedMimeTypes = screenshotMimeTypes.map((type) => `${type.split('/')[1]}`).join(', ')

const rules = computed(() => ({
  screenshot: {
    fileSize: fileMaxSizeRule(t, { megaBytes: MAX_SCREENSHOT_MB_SIZE }),
    fileType: fileMimeTypeRule(t, { mimeTypes: screenshotMimeTypes }),
  },
}))

const uploaderHints = computed(() => {
  return [screenshotAcceptedMimeTypes, `Max. ${MAX_SCREENSHOT_MB_SIZE} MB`]
})

const v$ = useVuelidate(rules, { screenshot: newScreenshot })
const editableScreenshots = ref<ReviewsProductScreenshotEditData[]>([])
const nonDeletedScreenshots = computed(() => editableScreenshots.value.filter((s) => !s.deleted))

const initScreenshots = () => {
  editableScreenshots.value = props.screenshots.map((screenshot) => ({
    id: screenshot.id ?? null,
    contentType: screenshot.contentType,
    url: screenshot.url,
    updated: false,
    deleted: false,
  }))
}

const handleScreenshotUpload = async (file: File) => {
  if (!file) {
    return
  }
  newScreenshot.value = file

  await nextTick()
  if (v$.value.screenshot.$invalid) {
    showNotification({
      headline: t('Reviews.product.edit.screenshots.invalidScreenshot.headline'),
      message: t('Reviews.product.edit.screenshots.invalidScreenshot.message', {
        sizeMb: MAX_SCREENSHOT_MB_SIZE,
        fileFormat: screenshotAcceptedMimeTypes,
      }),
      type: 'error',
      timeout: 10000,
    })
    newScreenshot.value = null
    return
  }

  const data = {
    id: null,
    contentType: file.type,
    url: URL.createObjectURL(file),
    file,
    updated: false,
    deleted: false,
  }
  editableScreenshots.value.splice(editableScreenshots.value.length + 1, 0, data)
  fileInput.value?.reset()
  updatePositions()
}

const toggleDeleteScreenshot = (screenshot: ReviewsProductScreenshotEditData) => {
  if (!screenshot.id) {
    const index = editableScreenshots.value.findIndex((s) => s === screenshot)
    URL.revokeObjectURL(screenshot.url)
    editableScreenshots.value.splice(index, 1)
    updatePositions()
    return
  }

  screenshot.deleted = !screenshot.deleted
}

const updatePositions = () => {
  nonDeletedScreenshots.value.forEach((screenshot, index) => {
    screenshot.position = index + 1
    screenshot.updated = true
  })
}

const draggableScreenshot = ref<ReviewsProductScreenshotEditData | null>(null)

const onDragStart = (event: DragEvent, index: number) => {
  if (event.dataTransfer && event.target) {
    event.dataTransfer.effectAllowed = 'move'
  }
  draggableScreenshot.value = editableScreenshots.value[index]
}

const onDrop = (index: number) => {
  if (!draggableScreenshot.value) {
    return
  } else if (!draggableScreenshot.value.deleted) {
    moveScreenshot(draggableScreenshot.value, index)
  }
  draggableScreenshot.value = null
}

const moveScreenshot = (screenshot: ReviewsProductScreenshotEditData, index: number) => {
  const fromIndex = editableScreenshots.value.findIndex((s) => s === screenshot)
  const element = editableScreenshots.value.splice(fromIndex, 1)[0]
  editableScreenshots.value.splice(index, 0, element)
  updatePositions()
}

onMounted(() => {
  initScreenshots()
})

watch(
  () => editableScreenshots.value,
  () => {
    emit('change', editableScreenshots.value)
  },
  { deep: true },
)
</script>
