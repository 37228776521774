<template>
  <SideBarSubMenu
    data-testid="sidebar-reviews-products"
    :label="t('SideBar.reviews.softwareAndTools')"
    :to="{ name: ReviewsRouteName.ReviewsProducts }"
  >
    <SideBarItem nested :to="{ name: ReviewsRouteName.ReviewsProducts }" data-testid="sidebar-reviews-products-overview">
      {{ t('SideBar.reviews.toolOverview') }}
    </SideBarItem>
    <SideBarItem
      v-for="productRoute in productRoutes"
      :key="productRoute.label"
      nested
      :to="{ name: productRoute.routeName, params: productRoute.routeParams }"
      :data-testid="'sidebar-reviews-products-' + productRoute.routeParams.productId"
    >
      {{ productRoute.label }}
    </SideBarItem>
  </SideBarSubMenu>
  <SideBarSubMenu
    data-testid="sidebar-reviews-agencies"
    :label="t('SideBar.reviews.agenciesAndServices')"
    :to="{ name: ReviewsRouteName.ReviewsAgencies }"
  >
    <SideBarItem nested :to="{ name: ReviewsRouteName.ReviewsAgencies }" data-testid="sidebar-reviews-agencies-overview">
      {{ t('SideBar.reviews.agenciesOverview') }}
    </SideBarItem>
    <SideBarItem
      v-for="agencyRoute in agencyRoutes"
      :key="agencyRoute.label"
      nested
      :to="{ name: agencyRoute.routeName, params: agencyRoute.routeParams }"
      data-testid="sidebar-reviews-agencies-item"
      :data-id="agencyRoute.routeParams.agencyId"
    >
      {{ agencyRoute.label }}
    </SideBarItem>
  </SideBarSubMenu>
  <SideBarItem data-testid="sidebar-reviews-omr-viewer" :to="buyerIntentDataUrl">
    <span class="pr-1">{{ t('SideBar.reviews.buyerIntentData') }}</span>
    <SvgIcon :path="mdiArrowTopRight" :size="16" />
  </SideBarItem>
</template>

<script setup lang="ts">
import { mdiArrowTopRight } from '@mdi/js'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import SideBarItem from '@/components/SideBar/SideBarItem.vue'
import SideBarSubMenu from '@/components/SideBar/SideBarSubMenu.vue'
import { buyerIntentDataUrl } from '@/config/env'
import { useReviewsAgenciesQuery, useReviewsProductsQuery } from '@/gql/reviews'
import { ReviewsRouteName } from '@/router/types'

const { t } = useI18n()
const { result: productsResult } = useReviewsProductsQuery({ clientId: 'reviews' })
const { result: agenciesResult } = useReviewsAgenciesQuery({ clientId: 'reviews' })

const productRoutes = computed(() => {
  if (productsResult.value?.manage?.products) {
    return productsResult.value.manage.products.map((product) => ({
      label: product.title,
      routeName: ReviewsRouteName.ReviewsProductView,
      routeParams: { productId: product.id },
    }))
  }

  return []
})

const agencyRoutes = computed(() => {
  if (agenciesResult.value?.manage?.agencies) {
    return agenciesResult.value.manage.agencies.map((agency) => ({
      label: agency.title,
      routeName: ReviewsRouteName.ReviewsAgencyView,
      routeParams: { agencyId: agency.id },
    }))
  }

  return []
})
</script>
