<template>
  <DropDownSelect>
    <template #activator="{ open }">
      <button class="flex items-center rounded border border-solid border-grey-700 p-2" @click.prevent="open">
        <SvgIcon :path="mdiFilterVariant" :size="24" class="mr-2 text-grey-700" />
        {{ filterDropDownLabel }}
      </button>
    </template>

    <template #default="{ close }">
      <ul class="m-0 list-none p-0">
        <li v-for="option in props.states" :key="`filter_${option.value}`" class="separate-rows">
          <label class="block cursor-pointer">
            <input v-model="filterStatus" type="radio" :value="option.value" class="peer pointer-events-none absolute opacity-0" />
            <div class="px-3 py-2 hover:bg-victoria-100 peer-checked:bg-victoria-100" @click="close">
              {{ option.label }}&nbsp;{{ props.showCounts ? `(${option.count})` : '' }}
            </div>
          </label>
        </li>
      </ul>
    </template>
  </DropDownSelect>
</template>

<script setup lang="ts">
import { mdiFilterVariant } from '@mdi/js'
import { type WritableComputedRef, computed } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = withDefaults(
  defineProps<{
    title?: string
    states: Array<{ label: string; count: number; value: string }>
    filterStatus: string
    showCounts?: boolean
  }>(),
  {
    title: undefined,
    showCounts: true,
  },
)

const emit = defineEmits<{
  (e: 'change', value: string): void
}>()

const filterStatus: WritableComputedRef<string> = computed({
  get(): string {
    return props.filterStatus
  },
  set(newValue: string): void {
    emit('change', newValue)
  },
})

const selectedOption = computed(() => {
  if (!filterStatus.value) return t('EventApplications.filterByStatus')
  const option = props.states.find((state) => state.value == filterStatus.value)
  return option?.label || t('EventApplications.filterByStatus')
})

const filterDropDownLabel = computed(() => {
  if (filterStatus.value) {
    return selectedOption.value
  } else {
    return props.title || t('EventApplications.filterByStatus')
  }
})
</script>
