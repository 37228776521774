<template>
  <DropDownSelect>
    <template #activator="{ open, showDropDown }">
      <button
        class="text-body-s flex items-center gap-1 rounded border border-solid p-2"
        :class="modelValue?.length || showDropDown ? 'filter-selected' : 'filter-default'"
        @click.prevent="open"
      >
        {{ label }}
        <span v-if="props.modelValue?.length" class="text-button-m rounded-xl bg-grey-200 p-1">
          {{ props.modelValue.length }}
        </span>
        <SvgIcon :path="mdiChevronDown" :size="24" />
      </button>
    </template>

    <template #default="{ close }">
      <ul class="options-list m-0 list-none overflow-y-auto p-0">
        <slot name="header" :close="close" />
        <li v-for="option in props.options" :key="`filter_${option.value}`" class="p-2 hover:bg-grey-100">
          <CheckBox
            :model-value="props.modelValue?.includes(option.value)"
            class="whitespace-nowrap text-grey-800"
            @update:model-value="handleCheckboxChange(option.value, $event)"
          >
            <div v-if="option.renderType === 'ratingStars'" class="flex items-center">
              <span v-if="option.renderType === 'ratingStars'" class="pr-4">
                <RatingStars :value="parseInt(option.text)" />
              </span>
              {{ option.text }}
            </div>
            <div v-else-if="option.renderType === 'stateBadges'">
              <ChipBadge :color="colorByState(option.responseState)" rounded>
                {{ option.text }}
              </ChipBadge>
            </div>
          </CheckBox>
        </li>
      </ul>
    </template>
  </DropDownSelect>
</template>

<script setup lang="ts">
import { mdiChevronDown } from '@mdi/js'
import { RatingStars, ChipBadge } from '@ramp106/omrjs-core-ui'

import { SurveyResponseState } from '@/composables/useSurveyResponseState'

const props = defineProps<{
  modelValue?: string[]
  options: {
    text: string
    value: string
    renderType?: string
    responseState?: SurveyResponseState
  }[]
  label: string
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: string[]): void
}>()

const handleCheckboxChange = (optionValue: string, checked: boolean) => {
  if (checked) {
    const newOptions = props.modelValue?.slice() || []
    newOptions.push(optionValue)

    emit('update:modelValue', newOptions)
  } else {
    const newOptions = (props.modelValue?.slice() || []).filter((option) => option !== optionValue)
    emit('update:modelValue', newOptions)
  }
}

function colorByState(responseState: SurveyResponseState | undefined) {
  switch (responseState) {
    case SurveyResponseState.Published:
      return 'mint'
    case SurveyResponseState.Drafted:
    case SurveyResponseState.ReDrafted:
      return 'purple'
    case SurveyResponseState.Rejected:
      return 'error'
    case SurveyResponseState.Proposed:
      return 'yellow'
    case SurveyResponseState.Stale:
      return 'default'
    default:
      return 'default'
  }
}
</script>

<style scoped>
.options-list {
  max-height: 640px;
}
.filter-selected {
  @apply border-grey-600 text-grey-700;
}
.filter-default {
  @apply border-grey-300 text-grey-500 disabled:border-grey-200 disabled:text-grey-400;
}
</style>
