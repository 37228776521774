<template>
  <div>
    <h1 class="text-headline-l">{{ t('Reviews.agencyOverview.title') }}</h1>
    <p class="text-headline-m my-4 mb-8">{{ t('Reviews.agencyOverview.subtitle', { companyName: me.partnerCompany?.displayName }) }}</p>
    <div class="grid grid-cols-4 gap-6">
      <AgencyCard
        v-for="agency in props.agencies"
        :key="agency.id"
        :agency="mapAgency(agency)"
        data-testid="reviews-agency-card"
        :data-id="agency.id"
        @click="openAgency(agency.id)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { AgencyCard, type AgencyCardTypes } from '@ramp106/omrjs-reviews-ui'
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import type { MeQuery } from '@/gql/myomr'
import type { AgencyBasicDataFragment } from '@/gql/reviews'
import { ReviewsRouteName } from '@/router/types'

const { t } = useI18n()
const router = useRouter()

const props = defineProps<{
  me: NonNullable<MeQuery['me']>
  agencies: AgencyBasicDataFragment[]
  loading: boolean
}>()

const emit = defineEmits(['reloadAgencies'])

// We are mapping only basic props to show small agency card
function mapAgency(agency: AgencyBasicDataFragment): AgencyCardTypes.Agency {
  return {
    name: agency.title,
    link: undefined,
    logoUrl: agency.logo?.url || undefined,
    remote: agency.remote,
    locations: [],
    shortDescription: undefined,
    traits: [],
  }
}

onMounted(() => emit('reloadAgencies'))

function openAgency(agencyId: string) {
  router.push({ name: ReviewsRouteName.ReviewsAgencyView, params: { agencyId } })
}
</script>
