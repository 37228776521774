<template>
  <div class="separate-rows flex h-[52px] w-full flex-row items-center gap-3 pl-1">
    <CheckBox :id="dueDateItem.id" v-model="completed" data-test-id="due-date-checkbox" class="text-grey-800" />

    <span
      v-if="urgencyBadge"
      class="text-label-s-bold inline-block rounded-sm px-2 py-1 uppercase text-white"
      :class="urgencyBadge.color"
      data-test-id="urgency-badge"
    >
      {{ urgencyBadge.title }}
    </span>

    <div class="grow font-semibold">
      {{ title }}

      <span v-if="dueAt" class="font-light text-grey-800" :class="{ 'text-amaranth-900': !completed && isOverdue }">
        {{ t('DueDateItem.completeBy', { dueDate: d(dueAt, 'dateShort') }) }}
      </span>
    </div>

    <RouterLink v-if="ctaRoute" :to="ctaRoute" class="btn-text-black-m" data-test-id="cta-label">
      {{ t('DueDateItem.ctaLabel') }}
    </RouterLink>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import type { DueDateItem as DueDateItemType } from '@/gql/myomr'
import { toPlainId } from '@/helpers/globalIdHelper'
import { getLocalizedAttribute } from '@/helpers/localizedAttribute'

const { locale, t, d } = useI18n()

const dueSoonInterval = 1000 * 60 * 60 * 24 * 5 // 5 days

const props = defineProps<{
  dueDateItem: DueDateItemType
}>()

// type-based
const emit = defineEmits<{
  (e: 'completed', id: string, completed: boolean): void
}>()

const completed = ref(props.dueDateItem.completed)

const dueAt = computed(() => {
  return props.dueDateItem.dueAt ? Date.parse(props.dueDateItem.dueAt) : null
})

const isDueSoon = computed(() => {
  return dueDelta.value && dueDelta.value <= dueSoonInterval && dueDelta.value >= 0
})

const isOverdue = computed(() => {
  return dueDelta.value && dueDelta.value <= dueSoonInterval && dueDelta.value < 0
})

const dueDelta = computed(() => {
  return dueAt.value ? dueAt.value - Date.now() : null
})

const title = computed(() =>
  getLocalizedAttribute({
    de: props.dueDateItem.titleDe,
    en: props.dueDateItem.titleEn,
    fallback: '',
    locale: locale.value,
  }),
)

const urgencyBadge = computed(() => {
  if (props.dueDateItem.completed) return null

  if (isOverdue.value) {
    return { title: t('DueDateItem.overdue'), color: 'bg-amaranth-900' }
  } else if (isDueSoon.value) {
    return { title: t('DueDateItem.urgent'), color: 'bg-ripe-lemon-900' }
  }

  return null
})

const ctaRoute = computed(() => {
  switch (props.dueDateItem.resourceType) {
    case 'PartnerCompany':
      return { name: 'partnerCompanyEdit' }
    case 'EventList':
      return { name: 'events' }
    case 'BoothList':
      return { name: 'booths' }
    case 'Booth':
    case 'Document': {
      if (!props.dueDateItem.resourceId) return null
      const id = toPlainId(props.dueDateItem.resourceId)

      return { name: 'boothDetails', params: { boothId: id } }
    }
    case 'SpeakerList':
      return { name: 'speakerList' }
    default:
      return null
  }
})

watch(completed, (completed, _prevCompleted) => {
  emit('completed', props.dueDateItem.id, completed)
})
</script>
