<template>
  <div class="w-full">
    <div class="flex items-center justify-between align-middle">
      <h2 v-if="activeList" class="ml-2 mt-6">{{ activeList.title }}</h2>
      <RouterLink
        v-if="activeList"
        :to="{ name: 'editProspectList', params: { listId: route.params.listId } }"
        class="ml-2 mt-6 inline-flex items-center align-middle font-bold text-black"
      >
        <SvgIcon :path="mdiPencilOutline" />
        {{ t('ProspectsCatalog.editList') }}</RouterLink
      >
    </div>
    <AlertBox v-if="activeList?.submittedAt" type="success" class="mt-5">
      <template #header>
        {{ t('Prospects.prospectSelectionSubmitted.title') }}
      </template>
      <div>
        {{ t('Prospects.prospectSelectionSubmitted.body') }}
      </div>
    </AlertBox>
    <AlertBox v-if="!activeList?.submittedAt && activeList?.event?.prospectSelectionUntil" type="warning" class="mt-5">
      <template #header>
        {{ t('Prospects.submitByNotification.title', { date: formatDate(activeList.event.prospectSelectionUntil) }) }}
      </template>
      <div>
        {{ t('Prospects.submitByNotification.description', { date: formatDate(activeList.event.prospectSelectionUntil) }) }}
      </div>
    </AlertBox>

    <div v-if="prospectListItems.length">
      <TableLight class="mb-6 w-full">
        <thead>
          <tr>
            <th class="text-start">{{ t('ProspectsCatalog.companyName') }}</th>
            <th class="text-start">{{ t('ProspectsCatalog.position') }}</th>
            <th class="text-start">{{ t('ProspectsCatalog.visitedEvents') }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <ProspectRow
            v-for="prospect in prospectListItems"
            :key="prospect.id"
            :facet-items="facetItems"
            :me="props.me"
            :prospect-list-id="activeList?.id"
            :prospect-lists="props.prospectLists"
            :prospect="prospect"
            @create-prospect-item="emit('createProspectItem', $event)"
            @delete-prospect-item="emit('deleteProspectItem', $event)"
          >
            <button v-if="activeList?.id && !activeList.submittedAt" class="h-6" @click.stop="deleteProspectItem(prospect.id)">
              <SvgIcon :path="mdiMinusCircleOutline" :size="20" />
            </button>
          </ProspectRow>
        </tbody>
      </TableLight>
      <div class="mt-8 flex justify-end gap-2">
        <RouterLink class="btn-secondary-purple-m mb-8" :to="{ name: 'sendProspectListTest' }">
          {{ t('ProspectsCatalog.sendTestEmail') }}
        </RouterLink>
        <RouterLink v-if="!activeList?.submittedAt" class="btn-primary-purple-m mb-8" :to="{ name: 'sendProspectListInvitations' }">
          {{ t('ProspectsCatalog.submitProspectListCTA') }}
        </RouterLink>
      </div>
    </div>
    <p v-else class="px-48 py-16 text-center">
      {{ t('ProspectsCatalog.emptyState') }}
    </p>
    <RouterView
      :active-list="activeList"
      :facet-items="props.facetItems"
      :me="props.me"
      :prospect-lists="props.prospectLists"
      @refetch-prospect-lists="emit('refetchProspectLists')"
    />
  </div>
</template>

<script setup lang="ts">
import { mdiPencilOutline, mdiMinusCircleOutline } from '@mdi/js'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import type { ProspectFacetItems } from '@/components/Prospects/prospectFacetItems'
import type { MeQuery } from '@/gql/myomr'
import { toPlainId } from '@/helpers/globalIdHelper'
import { TableLight } from '@/ui/TableLight'

import ProspectRow from './ProspectRow.vue'
import type { ProspectList } from './types'
const { t, d } = useI18n()

function formatDate(date: string): string {
  return d(date, 'dateShort')
}
const route = useRoute()

const props = defineProps<{
  me: NonNullable<MeQuery['me']>
  facetItems: ProspectFacetItems
  prospectLists: ProspectList[]
}>()

const activeList = computed(() => props.prospectLists.find((prospectList) => toPlainId(prospectList.id) === route.params.listId))

const prospectListItems = computed(() => activeList.value?.prospects || [])

const emit = defineEmits<{
  (e: 'createProspectItem', value: { prospectId: string; prospectListId: string }): void
  (e: 'deleteProspectItem', value: { prospectId: string; prospectListId: string }): void
  (e: 'refetchProspectLists'): void
}>()

async function deleteProspectItem(prospectId: string) {
  if (!activeList.value) return
  emit('deleteProspectItem', { prospectId: prospectId, prospectListId: activeList.value.id })
}
</script>
